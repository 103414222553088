import {TypeMapper} from '../../../../models/TypeMapper';
import {InitInfoResponse} from '../../models/response/InitInfoResponse';


export class InitInfoResponseMapper extends TypeMapper<JSON, InitInfoResponse> {
  map(): InitInfoResponse {
    const initInfoResponse = new InitInfoResponse();
    initInfoResponse.companyType = this.input['companyType'];
    initInfoResponse.role = this.input['role'];
    initInfoResponse.activated = this.input['activated'];
    initInfoResponse.lang = this.input['lang'];
    initInfoResponse.mail = this.input['mail'];
    initInfoResponse.name = this.input['name'];
    initInfoResponse.surname = this.input['surname'];
    initInfoResponse.avatar = this.input['avatar'];
    initInfoResponse.id = this.input['id'];
    initInfoResponse.createdAt = this.input['createdAt'];
    initInfoResponse.tfn = this.input['tfn'];
    initInfoResponse.mixPanelId = this.input['mixPanelId'];
    initInfoResponse.enterpriseLogo = this.input['enterpriseLogo'];
    initInfoResponse.enterpriseDir = this.input['enterpriseDir'];
    initInfoResponse.enterpriseCountry = this.input['enterpriseCountry'];
    initInfoResponse.chatUser = this.input['chatUser'];
    initInfoResponse.companyName = this.input['companyName'];
    initInfoResponse.companyCifNif = this.input['companyCifNif'];
    initInfoResponse.companyBillingOptions = this.input['companyBillingOptions'];
    return initInfoResponse;
  }
}
