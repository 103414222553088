import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditChatDialogComponent} from './edit-chat-dialog/edit-chat-dialog.component';
import {CreateChatDialogComponent} from './create-chat-dialog/create-chat-dialog.component';
import {ChatMessageComponent} from './chat-messages/chat-message.component';
import {DialogsChatComponent} from './chat-dialogs/dialogs-chat.component';
import {FormsModule} from '@angular/forms';
import {ChatDashboardComponent} from './chat-dashboard.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        RouterModule
    ]
    ,
  declarations: [ChatDashboardComponent, EditChatDialogComponent, CreateChatDialogComponent, ChatMessageComponent, DialogsChatComponent],
  exports: [ChatDashboardComponent, EditChatDialogComponent, CreateChatDialogComponent, ChatMessageComponent, DialogsChatComponent]
})
export class ChatDashboardModule {
}
