import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AlertNotificationsComponent} from '../../../components/alert-notifications/alert-notifications.component';
import {Globals} from '../../../models/Globals';
import {LanguageHelper} from '../../../../../utils/LanguageHelper';
import {take} from 'rxjs/operators';

@Injectable()
export class LockGuard implements CanActivate {

  constructor(private router: Router, private globals: Globals,
              private alertNotificationComponent: AlertNotificationsComponent,
              private languangeHelper: LanguageHelper) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.globals.isNeverLoaded()) {
      return true;
    } else {
      this.languangeHelper.waitForLanguageToBeLoaded().pipe(take(1)).subscribe(() => {
      this.alertNotificationComponent.showGenericDataError();
      });
      this.router.navigate(['/login'], {replaceUrl: true});
      return false;
    }
  }
}
