import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {EmptyStateComponent} from './empty-state.component';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [EmptyStateComponent],
  exports: [
    EmptyStateComponent
  ],
})

export class EmptyStateModule {
}
