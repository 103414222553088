import {ChatUser} from '../../../../components/chat/innporting-chat-services/models/ChatUser';
import {CompanyBillingOptions} from '../../../../models/CompanyBillingOptions';

export class InitInfoResponse {
  companyType: string;
  activated: number;
  role: string;
  lang: string;
  mail: string;
  surname: string;
  name: string;
  avatar: string;
  id: number;
  createdAt: Date;
  tfn: String;
  mixPanelId: String;
  enterpriseLogo: String;
  enterpriseDir: String;
  enterpriseCountry: String;
  chatUser: ChatUser;
  companyName: string;
  companyCifNif: string;
  companyBillingOptions: CompanyBillingOptions;
}
