import {NgModule} from '@angular/core';
import {RequiredInputComponent} from './required-input.component';
import {TranslateModule} from '@ngx-translate/core';
import {FieldErrorDisplayModule} from '../../field-error-display/field-error.module';
import {MyMaterialsModule} from '../../../modules/my-materials.module';

@NgModule({
  imports: [
    MyMaterialsModule,
    TranslateModule,
    FieldErrorDisplayModule
  ],
  exports: [RequiredInputComponent],
  declarations: [RequiredInputComponent]
})

export class RequiredInputModule {
}
