import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangeLanguageService {

  private static CHANGE_LANGUAGE_ENDPOINT = environment.baseApiUrl + 'update-lang';

  constructor(private http: HttpClient) {
  }

  public updateLang(lang: string) {
    return this.http.put(ChangeLanguageService.CHANGE_LANGUAGE_ENDPOINT, lang);
  }
}
