import {Component, Injectable, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertNotification} from './models/AlertNotification';
import {LanguageHelper} from '../../../../utils/LanguageHelper';

declare const $: any;

@Component({
  selector: 'app-alert-notifications',
  templateUrl: './alert-notifications.component.html',
  styleUrls: ['./alert-notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable({
  providedIn: 'root'
})

export class AlertNotificationsComponent implements OnInit {
  public static readonly MAX_NOTIS_AMOUNT = 3;
  public static readonly NOTIS_TIME_SHOWN = 1000;

  private waitingNotis = [];
  private closing = false;
  private notis = new Map<number, any>();
  private noActivatedAccountNotiShown = false;
  private needVerifyNotiShown = false;

  constructor(public translateService: TranslateService, private languageHelper: LanguageHelper) { }

  ngOnInit() {
  }

  private showError(key: string, translate = true) {
    this.languageHelper.waitForLanguageToBeLoaded().subscribe(next => {
      const noti = new AlertNotification();
      let message;
      if (translate) {
        message = this.translateService.instant(key);
      } else {
        message = key;
      }
      noti.type = 'danger';
      noti.message = message;
      noti.template = '<div data-notify="container" class="col-xs-11 col-sm-6 col-md-4 col-lg-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        '<i class="material-icons notranslate">close</i></button>' +
        '<i class="material-icons notranslate" data-notify="icon">error</i> ' +
        '<span data-notify="message">{2}</span>' +
        '</div>';
      this.notify(noti);
    });
  }

  private showSuccess(key: string) {
    this.languageHelper.waitForLanguageToBeLoaded().subscribe( () => {
      const noti = new AlertNotification();
      noti.type = 'success';
      noti.message = this.translateService.instant(key);
      noti.template = '<div data-notify="container" class="col-xs-11 col-sm-6 col-md-4 col-lg-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        '<i class="material-icons notranslate">close</i></button>' +
        '<i class="material-icons notranslate" data-notify="icon">notifications</i> ' +
        '<span data-notify="message">{2}</span>' +
        '</div>';
      this.notify(noti);
    });
  }

  private showChatMessageRawCompany(message: string) {
    const noti = new AlertNotification();
    noti.type = 'success';
    noti.message = message;
    noti.template = '<div data-notify="container" class="col-xs-11 col-sm-6 col-md-4 col-lg-3 alert alert-{0} alert-with-icon" role="alert" ' +
      'onclick="window.location.href=\'company-chat\'">' +
      '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
      '<i class="material-icons notranslate">close</i></button>' +
      '<i class="material-icons notranslate" data-notify="icon">notifications</i> ' +
      '<span data-notify="message">{2}</span>' +
      '</div>';
    this.notify(noti);
  }

  private showChatMessageRawForwarder(message: string) {
    const noti = new AlertNotification();
    noti.type = 'success';
    noti.message = message;
    noti.template = '<div data-notify="container" class="col-xs-11 col-sm-6 col-md-4 col-lg-3 alert alert-{0} alert-with-icon" role="alert" ' +
      'onclick="window.location.href=\'forwarder-chat\'">' +
      '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
      '<i class="material-icons notranslate">close</i></button>' +
      '<i class="material-icons notranslate" data-notify="icon">notifications</i> ' +
      '<span data-notify="message">{2}</span>' +
      '</div>';
    this.notify(noti);
  }

  public showGenericDataError() {
    this.showError('NOTIFICATIONS.FETCH_DATA_ERROR.CONTENT');
  }

  public showPasswordError() {
    this.showError('NOTIFICATIONS.INCORRECT_PASSWORD.CONTENT');
  }

  public showGenericDataSuccess() {
    this.showSuccess('NOTIFICATIONS.UPDATE_DATA_OK.CONTENT');
  }

  public showUserOrPasswordError() {
    this.showError('NOTIFICATIONS.INCORRECT_USER_OR_PASSWORD.CONTENT');
  }

  public showGenericFileFormatError() {
    this.showError('NOTIFICATIONS.INVALID_FILE_FORMAT.CONTENT');
  }

  public showAccountValidationSuccess() {
    this.showSuccess('NOTIFICATIONS.ACCOUNT_VALIDATED.CONTENT');
  }

  public showAccountValidationError() {
    this.showError('NOTIFICATIONS.ACCOUNT_NOT_VALIDATED.CONTENT');
  }

  public showNotLoggedMessage() {
    this.showError('NOTIFICATIONS.NOT_LOGGED_MESSAGE.CONTENT');
  }

  public showNotCompanyMessage() {
    this.showError('NOTIFICATIONS.NOT_COMPANY_MESSAGE.CONTENT');
  }

  public showNotForwarderMessage() {
    this.showError('NOTIFICATIONS.NOT_FORWARDER_MESSAGE.CONTENT');
  }

  public showAlreadyRegisteredMail() {
    this.showError('NOTIFICATIONS.ALREADY_REGISTERED_MAIL.CONTENT');
  }

  public showAlreadyRegisteredEnterprise() {
    this.showError('NOTIFICATIONS.ALREADY_REGISTERED_ENTERPRISE.CONTENT');
  }

  public showSendInviteSuccessful() {
    this.showSuccess('NOTIFICATIONS.SEND_INVITE_CORRECT.CONTENT');
  }

  public showSendInviteKO() {
    this.showError('NOTIFICATIONS.SEND_INVITE_INCORRECT.CONTENT');
  }

  public showDeleteUserSuccessful() {
    this.showSuccess('NOTIFICATIONS.DELETE_USER_SUCCESS.CONTENT');
  }

  public showDeleteUserKO() {
    this.showError('NOTIFICATIONS.DELETE_USER_ERROR.CONTENT');
  }

  public showAddAddressSuccess() {
    this.showSuccess('NOTIFICATIONS.ADD_ADDRESS_SUCCESS.CONTENT');
  }

  public showUpdateAddressSuccess() {
    this.showSuccess('NOTIFICATIONS.UPDATE_ADDRESS_SUCCESS.CONTENT');
  }

  public showDeleteAddressSuccess() {
    this.showSuccess('NOTIFICATIONS.DELETE_ADDRESS_SUCCESS.CONTENT');
  }

  public showDeleteAddressError() {
    this.showError('NOTIFICATIONS.DELETE_ADDRESS_ERROR.CONTENT');
  }

  public showSuccessAddedPaymentMethod() {
    this.showSuccess('NOTIFICATIONS.ADD_PAYMENT_METHOD_SUCCESS.CONTENT');
  }

  public showSuccessDeletedPaymentMethod() {
    this.showSuccess('NOTIFICATIONS.DELETE_PAYMENT_METHOD_SUCCESS.CONTENT');
  }

  public showNotFoundPlace() {
    this.showError('NOTIFICATIONS.NO_PLACE_FOUND_ALERT.CONTENT');
  }

  public showRequestedQuoteSuccess() {
    this.showSuccess('EMPTY_SPACES.SHIP_NOW.NO_RESULTS.QUOTE_REQUESTED');
  }

  public showNoActivatedAccountMessage() {
    if (!this.noActivatedAccountNotiShown) {
      this.showError('NOTIFICATIONS.NO_ACTIVATED_ACCOUNT_ALERT.CONTENT');
      this.noActivatedAccountNotiShown = true;
      setTimeout(() => {
        this.noActivatedAccountNotiShown = false;
      }, AlertNotificationsComponent.NOTIS_TIME_SHOWN);
    }
  }

  public showNeedVerifyAccountMessage() {
    if (!this.needVerifyNotiShown) {
      this.showError('NOTIFICATIONS.NEED_VERIFY_ACCOUNT_ALERT.CONTENT');
      this.needVerifyNotiShown = true;
      setTimeout(() => {
        this.needVerifyNotiShown = false;
      }, AlertNotificationsComponent.NOTIS_TIME_SHOWN);
    }
  }

  public showNoLocationMessage() {
    this.showError('NOTIFICATIONS.NO_LOCATION_ALERT.CONTENT');
  }

  public showNotCompletedData() {
    this.showError('NOTIFICATIONS.NO_COMPLETED_DATA_ALERT.CONTENT');
  }

  public showAddAtLeastOnUnitMessage() {
    this.showError('NOTIFICATIONS.ADD_AT_LEAST_ONE_UNIT.CONTENT');
  }

  public showGenericShipmentDataError() {
    this.showError('NOTIFICATIONS.SEND_SHIPMENT_GENERIC_DATA_ERROR.CONTENT');
  }

  public showSendSpecialShipmentSuccess() {
    this.showSuccess('NOTIFICATIONS.SEND_SPECIAL_SHIPMENT_SUCCESS.CONTENT');
  }

  public showNeedAccessForValidateAccount() {
    this.showError('NOTIFICATIONS.NEED_ACCESS_FOR_VALIDATE_ACCOUNT.CONTENT');
  }

  public showDataTableErrors() {
    this.showError('NOTIFICATIONS.DATA_TABLE_ERROR.CONTENT');
  }

  public showPaymentSuccess() {
    this.showSuccess('NOTIFICATIONS.PAYMENT_SUCCESS.CONTENT');
  }

  public showPaymentError() {
    this.showError('NOTIFICATIONS.PAYMENT_ERROR.CONTENT');
  }

  public showShipmentSuccess() {
    this.showSuccess('NOTIFICATIONS.SHIPMENT_SUCCESS.CONTENT');
  }

  public showSavedShipmentSuccess() {
    this.showSuccess('NOTIFICATIONS.SAVED_SHIPMENT_ALERT.CONTENT');
  }

  public showPutCorrectAddressAlert() {
    this.showError('NOTIFICATIONS.PUT_CORRECT_ADDRESS_ALERT.CONTENT');
  }

  public showChangePasswordSuccess() {
    this.showSuccess('NOTIFICATIONS.SUCCESS_CHANGE_PASSWD.CONTENT');
  }

  public showChangeUserPasswordSuccess() {
    this.showSuccess('NOTIFICATIONS.SUCCESS_CHANGE_USER_PASSWD.CONTENT');
  }

  public showPutCorrectCIFNIFAlert() {
    this.showError('NOTIFICATIONS.PUT_CORRECT_CIF_NIF_ALERT.CONTENT');
  }

  public showPutBankAccountAlert() {
    this.showError('NOTIFICATIONS.PUT_BANK_ACCOUNT_ALERT.CONTENT');
  }

  public showChatMessageCompany(message: string) {
    this.showChatMessageRawCompany(message);
  }

  public showChatMessageForwarder(message: string) {
    this.showChatMessageRawForwarder(message);
  }

  public showMustPutBankAccountAlert() {
    this.showError('NOTIFICATIONS.MUST_PUT_BANK_ACCOUNT_ALERT.CONTENT');
  }

  public showLoginAgainMailAlert() {
    this.showSuccess('NOTIFICATIONS.LOGIN_MAIL_AGAIN.CONTENT');
  }

  public showLoginAgainPasswordAlert() {
    this.showSuccess('NOTIFICATIONS.LOGIN_PASSWORD_AGAIN.CONTENT');
  }

  public showErrorMessage(message: string) {
    this.showError(message, false);
  }

  public showChangeRoleAlert() {
    this.showSuccess('NOTIFICATIONS.CHANGE_ROLE_ALERT.CONTENT');
  }

  public showSuccessCopyShipment() {
    this.showSuccess('NOTIFICATIONS.SHOW_SUCCESS_COPY_SHIPMENT.CONTENT');
  }

  public showErrorCopyShipment() {
    this.showError('NOTIFICATIONS.SHOW_ERROR_COPY_SHIPMENT.CONTENT');
  }

  public showSuccessOfferAccepted() {
    this.showSuccess('NOTIFICATIONS.ACCEPT_OFFER_SUCCESS.CONTENT');
  }

  public showSuccessOfferAcceptedCompany() {
    this.showSuccess('NOTIFICATIONS.ACCEPT_OFFER_SUCCESS_TO_COMPANY.CONTENT');
  }

  public showSuccessInvoiceDownloaded(billName) {
    const noti = new AlertNotification();
    noti.type = 'success';
    // tslint:disable-next-line:max-line-length
    noti.message = this.translateService.instant('INVOICES.INVOICES_DOWNLOAD_SUCCESS_PART_1') + billName + this.translateService.instant('INVOICES.INVOICES_DOWNLOAD_SUCCESS_PART_2');
    // tslint:disable-next-line:max-line-length
    noti.template = '<div data-notify="container" class="col-xs-11 col-sm-6 col-md-4 col-lg-3 alert alert-{0} alert-with-icon" role="alert">' +
      '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
      '<i class="material-icons notranslate">close</i></button>' +
      '<i class="material-icons notranslate" data-notify="icon">notifications</i> ' +
      '<span data-notify="message">{2}</span>' +
      '</div>';
    this.notify(noti);
  }

  public showErrorInvoiceDownloaded(billName) {
    const noti = new AlertNotification();
    noti.type = 'danger';
    // tslint:disable-next-line:max-line-length
    noti.message = this.translateService.instant('INVOICES.INVOICES_DOWNLOAD_ERROR') + billName;
    // tslint:disable-next-line:max-line-length
    noti.template = '<div data-notify="container" class="col-xs-11 col-sm-6 col-md-4 col-lg-3 alert alert-{0} alert-with-icon" role="alert">' +
      '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
      '<i class="material-icons notranslate">close</i></button>' +
      '<i class="material-icons notranslate" data-notify="icon">notifications</i> ' +
      '<span data-notify="message">{2}</span>' +
      '</div>';
    this.notify(noti);
  }

  public showErrorMaxSelectedElements(max: number) {
    const text1 = this.translateService.instant('NOTIFICATIONS.SHOW_ERROR_MAX_SELECTION_REACHED.CONTENT1');
    const text2 = this.translateService.instant('NOTIFICATIONS.SHOW_ERROR_MAX_SELECTION_REACHED.CONTENT2');
    this.showError(`${text1} ${max} ${text2}`, false);
  }

  showSuccessMultipleInvoicesDownloaded() {
    this.showSuccess('NOTIFICATIONS.SHOW_SUCCESS_MULTIPLE_INVOICES_DOWNLOAD.CONTENT');
  }

  public showSuccessGroupedInvoiceDownloaded(billName) {
    const noti = new AlertNotification();
    noti.type = 'success';
    // tslint:disable-next-line:max-line-length
    noti.message = this.translateService.instant('INVOICES.INVOICES_GROUPED_DOWNLOAD_SUCCESS_PART_1') + billName + this.translateService.instant('INVOICES.INVOICES_GROUPED_DOWNLOAD_SUCCESS_PART_2');
    // tslint:disable-next-line:max-line-length
    noti.template = '<div data-notify="container" class="col-xs-11 col-sm-6 col-md-4 col-lg-3 alert alert-{0} alert-with-icon" role="alert">' +
      '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
      '<i class="material-icons notranslate">close</i></button>' +
      '<i class="material-icons notranslate" data-notify="icon">notifications</i> ' +
      '<span data-notify="message">{2}</span>' +
      '</div>';
    this.notify(noti);
  }

  private notify(noti: AlertNotification) {
    if (this.notis.size < AlertNotificationsComponent.MAX_NOTIS_AMOUNT && this.waitingNotis.length < 1) {
      this.sendNoti(noti);
    } else {
      this.waitingNotis.push(noti);
    }
  }

  private async sendNoti(notification: AlertNotification) {
    if (!notification.template) {
      notification.template = '<div data-notify="container" class="col-xs-11 col-sm-6 col-md-4 col-lg-3 alert alert-{0} alert-with-icon" role="alert">' +
        '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">' +
        '<i class="material-icons notranslate">close</i></button>' +
        '<i class="material-icons notranslate" data-notify="icon">error</i> ' +
        '<span data-notify="message">{2}</span>' +
        '</div>';
    }
    const this_ = this;

    const notiId = this.getRandomNotiId();

    const noti = $.notify({
      icon: 'notifications',
      message: notification.message
    }, {
      type: notification.type,
      timer: AlertNotificationsComponent.NOTIS_TIME_SHOWN,
      placement: {
        from: 'top',
        align: 'center'
      },
      onClose: () => {
        this_.closing = true;
      },
      onClosed: () => {
        this_.notiClosed(notiId);
      },
      template: notification.template
    });
    this.notis.set(notiId, noti);
    this.correctNotisPositions();
  }

  private notiClosed(id) {
    this.notis.delete(id);
    this.closing = false;
    setTimeout(() => {
      this.correctNotisPositions();
      this.waitIfNotiClosing();
      this.sendNextWaitingNoti();
    }, 500);
  }

  private correctNotisPositions() {
    this.waitIfNotiClosing();
    let top = 30;
    this.notis.forEach( noti => {
      const elem = noti.$ele[0];
      elem.style.top = top + 'px';
      top += elem.offsetHeight + 20;
    });
  }

  private async waitIfNotiClosing() {
    while (this.closing) {
      await new Promise(f => setTimeout(f, 300));
    }
  }

  private sendNextWaitingNoti() {
    if (this.waitingNotis.length > 0) {
      this.sendNoti(this.waitingNotis[0]);
      this.waitingNotis.splice(0, 1);
    }
  }

  private removePrevious() {
    const elemsToRemove = this.notis.size - AlertNotificationsComponent.MAX_NOTIS_AMOUNT;
    let accumulatedHeight = 0;
    let i = 0;
    this.notis.forEach(value => {
      if (i < elemsToRemove) {
        value.close();
      } else {
        const elem = value.$ele[0];
        if (i === elemsToRemove) {
          elem.style.top = '20px';
        } else {
          elem.style.top = accumulatedHeight;
        }
        accumulatedHeight += elem.offsetHeight + 10;
      }
      i++;
    });
  }

  private getRandomNotiId() {
    let notiId = Math.random() * 1000;
    while (this.notis.get(notiId)) {
      notiId = Math.random() * 1000;
    }
    return notiId;
  }

  showSuccessGroupedInvoiceDownloading() {
    this.showSuccess('NOTIFICATIONS.SHOW_SUCCESS_GROUPED_INVOICES_DOWNLOADING.CONTENT');
  }

  showSuccessMultipleInvoicesDownloading() {
    this.showSuccess('NOTIFICATIONS.SHOW_SUCCESS_MULTIPLE_INVOICES_DOWNLOADING.CONTENT');
  }

  showInstantOfferSuccess() {
    this.showSuccess('OFFERS.SUCCESSFUL_INSTANT_OFFER');
  }

  showOfferSuccess() {
    this.showSuccess('OFFERS.SUCCESSFUL_OFFER');
  }

  showEditOfferSuccessToCompany() {
    this.showSuccess('OFFERS.SUCCESS_ALERT_OFFER_DESC_TO_COMPANY');
  }

  showEditOfferSuccessToFW() {
    this.showSuccess('OFFERS.SUCCESS_ALERT_OFFER_DESC_TO_FW');
  }
}
