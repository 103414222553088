import {Routes} from '@angular/router';

import {CompanyAdminComponent} from './components/layouts/company-admin/company-admin.component';
import {ForwarderAdminComponent} from './components/layouts/forwarder-admin/forwarder-admin.component';
import {CanActivateCompanyGuard} from './components/shared/guards/CanActivateCompanyGuard';
import {CanActivateForwarderGuard} from './components/shared/guards/CanActivateForwarderGuard';
import {LoginComponent} from './components/pages/shared-pages/login/login.component';
import {RegisterComponent} from './components/pages/shared-pages/register/register.component';
import {LockComponent} from './components/pages/shared-pages/lock/lock.component';
import {NotFoundPageComponent} from './components/pages/shared-pages/not-found-page/not-found-page.component';
import {AuthGuard} from './components/shared/features/auth/guards/auth-guard.service';
import {LockGuard} from './components/shared/features/auth/guards/lock-guard.service';
import {CanActivateAccountActivatedGuard} from './components/shared/guards/CanActivateAccountActivatedGuard';
import {RecoverPassComponent} from './components/pages/shared-pages/recover-pass/recover-pass.component';
import {RecoverPassUserComponent} from './components/pages/shared-pages/recover-pass-user/recover-pass-user.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: CompanyAdminComponent,
    canActivate: [AuthGuard, CanActivateCompanyGuard],
    children: [
      {
        path: '',
        data: {
          title: 'Overview'
        },
        loadChildren: './components/pages/company/company-overview/company-overview.module#CompanyOverviewModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'my-company',
        data: {
          title: 'My company'
        },
        loadChildren: './components/pages/company/my-company/my-company.module#MyCompanyModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'ship-now/saved/:savedShipmentId',
        loadChildren: './components/pages/company/ship-now/ship-now.module#ShipNowModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'ship-now/repeat/:repeatShipmentId',
        loadChildren: './components/pages/company/ship-now/ship-now.module#ShipNowModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'ship-now/offer/:offerShipmentId',
        loadChildren: './components/pages/company/ship-now/ship-now.module#ShipNowModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'ship-now',
        data: {
          title: 'Ship now'
        },
        loadChildren: './components/pages/company/ship-now/ship-now.module#ShipNowModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'company-shipments',
        data: {
          title: 'My shipments'
        },
        loadChildren: './components/pages/company/company-shipments/company-shipments.module#CompanyShipmentsModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'company-ship-analytics',
        data: {
          title: 'Shipment analytics'
        },
        loadChildren: './components/pages/company/company-ship-analytics/company-ship-analytics.module#CompanyShipAnalyticsModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'company-invoices',
        data: {
          title: 'Billing'
        },
        loadChildren: './components/pages/company/company-invoices/company-invoices-routing/company-invoices-routing.module#CompanyInvoicesRoutingModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'company-help',
        data: {
          title: 'Help'
        },
        loadChildren: './components/pages/company/company-help/company-help.module#CompanyHelpModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'my-company-account',
        data: {
          title: 'My Account'
        },
        loadChildren: './components/pages/company/my-company-account/my-company-account.module#MyCompanyAccountModule'
      },
      {
        path: 'company-notifications',
        data: {
          title: 'Notifications'
        },
        loadChildren: './components/pages/shared-pages/notifications/notifications.module#NotificationsModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'company-shipment-detail/:trackNumber',
        data: {
          title: 'Shipment detail'
        },
        loadChildren: './components/pages/shared-pages/my-shipments/shipment-detail/company-shipment-detail/company-shipment-detail.module#CompanyShipmentDetailModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'company-offers',
        data: {
          title: 'Company Offers'
        },
        loadChildren: './components/shared/components/offers/offers.module#OffersModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      // TODO: Reactivar chat en un futuro
      /*{
        path: 'company-chat',
        data: {
          title: 'Chat'
        },
        loadChildren: './components/pages/company/company-chat/company-chat.module#CompanyChatModule',
        canActivate: [CanActivateAccountActivatedGuard]
      }*/
    ]
  },
  {
    path: '',
    component: ForwarderAdminComponent,
    canActivate: [AuthGuard, CanActivateForwarderGuard],
    children: [
      {
        path: '',
        data: {
          title: 'Overview'
        },
        loadChildren: './components/pages/forwarder/forwarder-overview/forwarder-overview.module#ForwarderOverviewModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'my-transport-company',
        data: {
          title: 'My company'
        },
        loadChildren: './components/pages/forwarder/my-transport-company/my-transport-company.module#MyTransportCompanyModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'forwarder-shipments',
        data: {
          title: 'My Shipments'
        },
        loadChildren: './components/pages/forwarder/forwarder-shipments/forwarder-shipments.module#ForwarderShipmentsModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'forwarder-ship-analytics',
        data: {
          title: 'Shipment analytics'
        },
        loadChildren: './components/pages/forwarder/forwarder-ship-analytics/forwarder-ship-analytics.module#ForwarderShipAnalyticsModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'my-quotes',
        data: {
          title: 'My quotes'
        },
        loadChildren: './components/pages/forwarder/my-quotes/my-quotes.module#MyQuotesModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'forwarder-invoices',
        data: {
          title: 'Invoices'
        },
        loadChildren: './components/pages/forwarder/forwarder-invoices/forwarder-invoices-routing/forwarder-invoices-routing.module#ForwarderInvoicesRoutingModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'forwarder-help',
        data: {
          title: 'Help'
        },
        loadChildren: './components/pages/forwarder/forwarder-help/forwarder-help.module#ForwarderHelpModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'my-forwarder-account',
        data: {
          title: 'My account'
        },
        loadChildren: './components/pages/forwarder/my-forwarder-account/my-forwarder-account.module#MyForwarderAccountModule'
      },
      {
        path: 'forwarder-notifications',
        data: {
          title: 'Notifications'
        },
        loadChildren: './components/pages/shared-pages/notifications/notifications.module#NotificationsModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'forwarder-shipment-detail/:trackNumber',
        data: {
          title: 'Shipment detail'
        },
        loadChildren: './components/pages/shared-pages/my-shipments/shipment-detail/forwarder-shipment-detail/forwarder-shipment-detail.module#ForwarderShipmentDetailModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      /*{
        path: 'forwarder-chat',
        data: {
          title: 'Chat'
        },
        loadChildren: './components/pages/forwarder/forwarder-chat/forwarder-chat.module#ForwarderChatModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },*/
      {
        path: 'simulate-quote',
        data: {
          title: 'Simulate quote'
        },
        loadChildren: './components/pages/forwarder/simulate-quote/simulate-quote.module#SimulateQuoteModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
      {
        path: 'forwarder-offers',
        data: {
          title: 'Forwarder Offers'
        },
        loadChildren: './components/shared/components/offers/offers.module#OffersModule',
        canActivate: [CanActivateAccountActivatedGuard]
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register'
    }
  },
  {
    path: 'lock',
    component: LockComponent,
    canActivate: [LockGuard],
    data: {
      title: 'Lock'
    },
  },
  {
    path: '404',
    component: NotFoundPageComponent,
    data: {
      title: 'Not found'
    }
  },
  {
    path: 'track-my-shipment/:trackNumber',
    loadChildren: './components/shared/components/track-my-shipment/track-my-shipment.module#TrackMyShipmentModule',
    data: {
      title: 'Track my shipment'
    },
  },
  {
    path: 'recover-pass',
    component: RecoverPassComponent,
    data: {
      title: 'Recover password'
    },
  },
  {
    path: 'recover-pass-user',
    component: RecoverPassUserComponent,
    data: {
      title: 'Recover password user'
    },
  },
  {path: '**', redirectTo: '404'}
];

export class AppRoutingModule {
}
