import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DataValidator} from '../utils/DataValidator';
import {InnRadioItem} from './models/InnRadioItem';

@Component({
  selector: 'app-inn-radio-group',
  templateUrl: './inn-radio-group.component.html',
  styleUrls: ['./inn-radio-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/**
 * TV: Type Value
 */

export class InnRadioGroupComponent<TV> implements OnInit {

  @Output()
  outputChooseOption: EventEmitter<TV> = new EventEmitter<TV>();

  public choosenOption: TV;

  @Input()
  public label?: string;

  @Input()
  public items?: InnRadioItem<TV>[];

  @Input()
  public direction?: 'column' | 'row' = 'column';

  constructor() { }

  ngOnInit() {
    this.initChoosenOption();
  }

  initChoosenOption() {
    if (!DataValidator.isNullOrUndefined(this.items)) {
      let defaultOptionFounded = false;
      let index = 0;
      while (!defaultOptionFounded && index < this.items.length) {
        defaultOptionFounded = this.items[index].isDefault;
        if (defaultOptionFounded) {
          this.choosenOption = this.items[index].value;
        }
        index++;
      }
    }
  }

  onChooseOption() {
    if (!DataValidator.isNullOrUndefined(this.outputChooseOption)) {
      this.outputChooseOption.emit(this.choosenOption);
    }
  }

  public getChoosenOption(): TV {
    return this.choosenOption;
  }

  setChosenOption(value: TV) {
    this.choosenOption = value;
    this.onChooseOption();
  }

}
