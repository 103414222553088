import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Globals} from '../models/Globals';
import {AlertNotificationsComponent} from '../components/alert-notifications/alert-notifications.component';
import {RouteHelper} from '../../../utils/RouteHelper';
import {UserService} from '../features/auth/services/user.service';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {InitInfoService} from '../features/get-init-info/services/init-info.service';
import {catchError, map, take} from 'rxjs/operators';
import {DataValidator} from '../components/inputs/utils/DataValidator';
import {LanguageHelper} from '../../../utils/LanguageHelper';

@Injectable({
  providedIn: 'root'
})

export class CanActivateCompanyGuard implements CanActivate {

  constructor(private globals: Globals, private router: Router,
              private alertNotificationComponent: AlertNotificationsComponent,
              private routeHelper: RouteHelper, private userService: UserService,
              private httpauth: HttpClient, private languageHelper: LanguageHelper) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.globals.isNeverLoaded()) {
      if (this.globals.isForwarder()) {
        this.languageHelper.waitForLanguageToBeLoaded().pipe(take(1)).subscribe(() => {
          this.alertNotificationComponent.showNotCompanyMessage();
        });
        this.router.navigate(['/forwarder-overview'], {replaceUrl: true});
        return false;
      } else {
        return true;
      }
    } else {
      return this.httpauth.get(InitInfoService.INIT_INFO_ENDPOINT).pipe(
        map(data => {
          if (data && !DataValidator.isNullOrUndefined(data['mail'])) {
            this.userService.saveGlobals(data);
            if (this.globals.isForwarder()) {
              this.languageHelper.waitForLanguageToBeLoaded().pipe(take(1)).subscribe(() => {
                this.alertNotificationComponent.showNotCompanyMessage();
              });
              this.router.navigate(['/forwarder-overview'], {replaceUrl: true});
              return false;
            }
            return true;
          } else {
            this.languageHelper.waitForLanguageToBeLoaded().pipe(take(1)).subscribe(() => {
              this.alertNotificationComponent.showGenericDataError();
            });
            this.userService.logout();
            return false;
          }
        }),
        catchError(err => {
          this.alertNotificationComponent.showGenericDataError();
          this.userService.logout();
          return of(false);
        })
      );
    }
  }
}
