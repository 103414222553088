import {Injectable} from '@angular/core';
import {ShipmentPaymentRequest} from '../models/ShipmentPaymentRequest';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  private static SET_PAYMENT_METHOD_DEFAULT = environment.baseApiUrl + 'pay-shipment';
  private static GET_PAYMENT_METHODS_ENDPOINT = environment.baseApiUrl + 'get-payment-methods';
  private static AUTO_PAY_STRIPE_BILL = environment.baseApiUrl + 'auto-pay-stripe-bill';

  constructor(private httpauth: HttpClient) {
  }

  getPaymentMethods() {
    return this.httpauth.get(PaymentsService.GET_PAYMENT_METHODS_ENDPOINT).map(value => value as JSON);
  }

  pay(shipmentPaymentRequest: ShipmentPaymentRequest) {
    return this.httpauth.post(PaymentsService.SET_PAYMENT_METHOD_DEFAULT, shipmentPaymentRequest).map(value => value as JSON);
  }

  autoPayStripeBill(shipmentPaymentRequest: ShipmentPaymentRequest) {
    return this.httpauth.post(PaymentsService.AUTO_PAY_STRIPE_BILL, shipmentPaymentRequest);
  }
}
