import {Component, NgZone, OnInit} from '@angular/core';
import {GlobalService} from '../../../service/global.service';
import {Globals} from '../../../models/Globals';
import {AlertNotificationsComponent} from '../../alert-notifications/alert-notifications.component';
import {Router} from '@angular/router';

declare function initQuickbloxWorker(userId, userLoginName): any;
declare function terminateWorker(): any;

@Component({
  selector: 'app-chat-worker-background',
  templateUrl: './chat-worker-background.component.html',
  styleUrls: ['./chat-worker-background.component.scss']
})
export class ChatWorkerBackgroundComponent implements OnInit {

  constructor(private ngZone: NgZone, private globalService: GlobalService,
              private globals: Globals, private alertNotificationsComponent: AlertNotificationsComponent,
              private router: Router) {
  }

  ngOnInit() {
    try {
      // tslint:disable-next-line:max-line-length
      window['angularComponentReference'] = {component: this, zone: this.ngZone, loadAngularFunction: (data) => this.callAngularAlert(data)};

      this.globalService.globalLoaded.subscribe(value => {
        if (value && !this.globals.isChatBackgroundWorking()) {
          this.globals.setIsChatBackgroundWorking(true);
          const chatUser = this.globals.getChatUser();
          initQuickbloxWorker(chatUser.userChatId, chatUser.userLoginName);
        }
      }, error => {

      }, () => {
      });
    } catch (e) {
      // Do nothing
    }
  }

  callAngularAlert(data) {
    try {
      if (this.isShipmentDetailScreen()) {
        if (!this.shipmentDetailChatActive()) {
          this.showChatMessage(data.message, data.userName);
        }
      } else {
        if (!this.isChatUrl()) {
          this.showChatMessage(data.message, data.userName);
        }
      }
    } catch (e) {
      // Do nothing
    }
  }

  showChatMessage(message, userName) {
    const body = message;
    const messageFinal = 'New message from: ' + userName + '<br/>' + body;

    this.showNotificationsWrapper();
    if (this.globals.isCompany()) {
      this.alertNotificationsComponent.showChatMessageCompany(messageFinal);
    } else {
      this.alertNotificationsComponent.showChatMessageForwarder(messageFinal);
    }
  }

  showNotificationsWrapper() {
    document.getElementById('notificationsWrapper1').style.display = 'block';
    document.getElementById('notificationsWrapper2').style.display = 'block';
  }

  isChatUrl() {
    return window.location.href.includes('chat');
  }

  private isShipmentDetailScreen() {
    return this.router.url.includes('shipment-detail');
  }

  shipmentDetailChatActive() {
    let isInChatCompany = false;
    let isInChatForwarder = false;

    try {
      // tslint:disable-next-line:max-line-length
      isInChatCompany = document.querySelector('body > app-root > app-company-admin > div > div.main-panel > app-company-shipment-detail > app-shipment-detail > div > div > div > div.col-lg-12.col-md-12 > div > div.card-header.card-header-tabs.card-header-primary > div > div > ul > li:nth-child(7) > a').className.includes('active');
    } catch (e) {
      isInChatCompany = false;
    }

    try {
      // tslint:disable-next-line:max-line-length
      isInChatForwarder = document.querySelector('body > app-root > app-forwarder-admin > div > div.main-panel > app-forwarder-shipment-detail > app-shipment-detail > div > div > div > div.col-lg-12.col-md-12 > div > div.card-header.card-header-tabs.card-header-primary > div > div > ul > li:nth-child(7) > a').className.includes('active');
    } catch (e) {
      isInChatForwarder = false;
    }

    return isInChatCompany || isInChatForwarder;
  }
}
