import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MailWrapper} from '../../recover-pass/services/models/MailWrapper';
import {PassWrapper} from './models/PassWrapper';

@Injectable({
  providedIn: 'root'
})
export class RecoverPassUserService {

  private static RECOVER_PASSWD_USER_METHOD = environment.baseApiUrl + 'recover-user-password';

  constructor(private httpauth: HttpClient) { }

  recoverPasswdUser(pass: PassWrapper) {
    return this.httpauth.post(RecoverPassUserService.RECOVER_PASSWD_USER_METHOD, pass).map(value => value as MailWrapper);
  }
}
