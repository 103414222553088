import {Component, Input, OnInit} from '@angular/core';
import {DataValidator} from '../../../shared/components/inputs/utils/DataValidator';

@Component({
  selector: 'app-complete-pages-footer',
  templateUrl: './complete-pages-footer.component.html',
  styleUrls: ['./complete-pages-footer.component.scss']
})
export class CompletePagesFooterComponent implements OnInit {
  @Input()
  breakAbsolutePosition?: boolean;

  public date: Date = new Date();

  constructor() {
    if (DataValidator.isNullOrUndefined(this.breakAbsolutePosition)) {
      this.breakAbsolutePosition = true;
    }
  }

  ngOnInit() {
  }

}
