import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {InnRoute} from './models/InnRoute';
import {DataValidator} from '../../components/shared/components/inputs/utils/DataValidator';

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  private prevRoutes: InnRoute[];

  constructor(private router: Router) {
    this.prevRoutes = [];
  }

  init() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.pushRoute(event);
    });
  }

  goBack(defaultRoute: string = '', preserveScrollPosition: boolean = false) {
    if (this.hasNavigated()) {
      const route = this.getPreviousRoute();
      if (route) {
        this.router.navigate([route.url]);
        if (preserveScrollPosition) {
          setTimeout(() => {
            scrollTo({top: route.scrollY});
          }, 300);
        }
      }
    } else {
      this.router.navigate([defaultRoute]);
    }
  }

  hasNavigated() {
    return this.prevRoutes.length > 1;
  }

  pushInnRoute(route: InnRoute) {
    if (!DataValidator.isNullOrUndefined(route)) {
      this.prevRoutes.push(route);
    }
  }

  private pushRoute(event: NavigationEnd) {
    const actualRoute = this.getActualRoute();
    if (DataValidator.isNullOrUndefined(actualRoute) || actualRoute.url !== event.urlAfterRedirects) {
      if (actualRoute) {
        actualRoute.scrollY = window.scrollY;
      }
      this.prevRoutes.push({
        url: event.urlAfterRedirects,
        scrollY: 0
      });
    }
  }

  private getActualRoute(): InnRoute {
    if (this.prevRoutes.length > 0) {
      return this.prevRoutes[this.prevRoutes.length - 1];
    }
    return null;
  }

  getPreviousRoute(): InnRoute {
    if (this.prevRoutes.length > 1) {
      return this.prevRoutes[this.prevRoutes.length - 2];
    }
    return null;
  }

  goBackToSpecificRoute(route: InnRoute, defaultRoute: string = '', preserveScrollPosition: boolean = false) {
    if (!DataValidator.isNullOrUndefined(route)) {
      this.router.navigate([route.url]);
      if (preserveScrollPosition) {
        setTimeout(() => {
          scrollTo({top: route.scrollY});
        }, 300);
      }
    } else {
      this.router.navigate([defaultRoute]);
    }
  }

  reset() {
    this.prevRoutes = [];
  }
}
