import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateUtils {

  public static DEFAULT_FORMAT = 'dd/MM/yyyy';

  constructor(public datepipe: DatePipe) {

  }

  public static isOutOfPeriod(offerValidTo: string, offsetDays?: number) {
    function convertStringToDateWithDefaultFormat(dateToConvert: string): Date {
      const dateComponents = dateToConvert.split('/');
      const year = Number(dateComponents[2]);
      const month = Number(dateComponents[1]) - 1;
      const day = Number(dateComponents[0]);
      return new Date(year, month, day);
    }

    const date = convertStringToDateWithDefaultFormat(offerValidTo);
    if (offsetDays) {
      date.setDate(date.getDate() + offsetDays);
    }
    const today = new Date();
    return date.getTime() < today.getTime();
  }

  convertDateToString(date: Date, format: string) {
    return this.datepipe.transform(date, format);
  }

  convertStringToDateWithDefaultFormat(date: string): Date {
    var dateComponents = date.split('/');
    const year = Number(dateComponents[2]);
    const month = Number(dateComponents[1]) -1;
    const day = Number(dateComponents[0]);
    return new Date(year, month, day);
  }

  getMonthAndYearStringFromCompleteString(date: string): string {
    const dateComponents = date.split('/');
    const month = dateComponents[1];
    const year = dateComponents[2].slice(0, 4);
    return `${month}/${year}`;
  }

  isOutOfPeriod(offerValidTo: string) {
    const date = this.convertStringToDateWithDefaultFormat(offerValidTo);
    const today = new Date();
    return date.getTime() < today.getTime();
  }

  convertStringToDateWithHyphenFormat(date: string): Date{
    var dateComponents = date.split('-');
    const year = Number(dateComponents[0]);
    const month = Number(dateComponents[1]) -1;
    const day = Number(dateComponents[2][0] + dateComponents[2][1]);
    return new Date(year, month, day);
  }

  sumDaysToDate(date: string, days: number): string {
    try {
      const dateParsed = this.convertStringToDateWithDefaultFormat(date);
      dateParsed.setDate(dateParsed.getDate() + days);
      return this.convertDateToString(dateParsed, DateUtils.DEFAULT_FORMAT);
    } catch (e) {

    }
    return undefined;
  }

  sumDaysToToday(days: number): string {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return this.convertDateToString(date, DateUtils.DEFAULT_FORMAT);
  }
}
