import {TranslateService} from '@ngx-translate/core';
import {LocalStorageHelper} from './LocalStorageHelper';
import {Language} from '../components/shared/models/Language';
import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';

@Injectable(
  {providedIn: 'root'}
)

export class LanguageHelper {

  public static readonly LANG_ES = 'es';
  public static readonly LANG_EN = 'en';
  public static readonly LANG_ES_COMPLETE_NAME = 'Español';
  public static readonly LANG_EN_COMPLETE_NAME = 'English';
  public static readonly FLAG_ES = 'flag-es';
  public static readonly FLAG_EN = 'flag-en';


  private languageLoaded: boolean;
  private _languagesMap: Map<String, Language> = new Map<String, Language>();
  private observers: Subscriber<any>[];
  get languagesMap(): Map<String, Language> {
    return this._languagesMap;
  }

  constructor(public translateService: TranslateService) {
    this.languageLoaded = false;
    this.observers = [];
  }

  initLanguage() {
      this.fillMapForLanguages();
      this.translateService.addLangs([LanguageHelper.LANG_ES, LanguageHelper.LANG_EN]);
      this.translateService.setDefaultLang(LanguageHelper.LANG_EN);
      this.setLanguage().subscribe(() => {
        this.triggerObservers();
      });
  }

  initDirectLanguage(lang: string) {
    this.fillMapForLanguages();
    this.translateService.addLangs([LanguageHelper.LANG_ES, LanguageHelper.LANG_EN]);
    this.translateService.setDefaultLang(LanguageHelper.LANG_EN);
    this.setDirectLanguage(lang).subscribe(() => {
      this.triggerObservers();
    });
  }

  private fillMapForLanguages() {
    this._languagesMap.set(LanguageHelper.LANG_ES, new Language(LanguageHelper.LANG_ES_COMPLETE_NAME, LanguageHelper.FLAG_ES));
    this._languagesMap.set(LanguageHelper.LANG_EN, new Language(LanguageHelper.LANG_EN_COMPLETE_NAME, LanguageHelper.FLAG_EN));
  }

  private setLanguage() {
    let lang: string;
    if (LocalStorageHelper.existsItem(LocalStorageHelper.LANG_KEY)) {
      lang = LocalStorageHelper.getItem(LocalStorageHelper.LANG_KEY);
    } else {
      if (this.existLangInLangsArray(this.getBrowserLang())) {
        lang = this.getBrowserLang();
      } else {
        lang = LanguageHelper.LANG_EN;
      }
    }
    return this.translateService.use(lang);
  }

  private setDirectLanguage(lang: string) {
    return this.translateService.use(lang);
  }

  private existLangInLangsArray(lang: string) {
    return this.getLangs().indexOf(lang) !== -1;
  }

  private getLangs() {
    return this.translateService.getLangs();
  }

  public getFilteredMenuLangs() {
    return this.translateService.getLangs().filter(lang => lang !== this.getCurrentLang());
  }

  private getBrowserLang(): string {
    return this.translateService.getBrowserLang();
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
    LocalStorageHelper.setItem(LocalStorageHelper.LANG_KEY, lang);
  }

  getCurrentLang(): string {
    return this.translateService.currentLang;
  }

  waitForLanguageToBeLoaded() {
    return new Observable((observer) => {
      if (this.languageLoaded) {
        observer.next();
      } else {
        this.observers.push(observer);
      }
      return {
        unsubscribe() {
        }
      };
    });
  }

  triggerObservers() {
    this.observers.forEach(observer => {
      observer.next();
    });
    this.languageLoaded = true;
  }
}
