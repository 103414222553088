import {Injectable} from '@angular/core';
import {InitInfoResponseMapper} from '../mappers/response/InitInfoResponseMapper';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class InitInfoService {

  public static INIT_INFO_ENDPOINT = environment.baseApiUrl + 'init-info';

  constructor(private httpauth: HttpClient) {
  }

  getInitInfo() {
    return this.httpauth.get(InitInfoService.INIT_INFO_ENDPOINT)
      .map(value => new InitInfoResponseMapper(value as JSON).map());
  }

  getInitInfoSynchronous() {
    return this.httpauth.get(InitInfoService.INIT_INFO_ENDPOINT)
      .map(value => new InitInfoResponseMapper(value as JSON).map()).toPromise();
  }
}
