import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertNotificationsComponent} from '../../alert-notifications/alert-notifications.component';
import {BillingInfoService} from '../../../../pages/shared-pages/billing-info/services/billing-info.service';
import {Globals} from '../../../models/Globals';
import {DataValidator} from '../../inputs/utils/DataValidator';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss']
})
export class AddPaymentMethodComponent implements OnInit {
  public stripe;
  public card;
  public ibanElement;
  public clientSecret;
  public typeView = 'card';

  @Input()
  singleType = false;

  @Input()
  typeAllowed: string;

  @Output() paymentMethodAdded: EventEmitter<string> = new EventEmitter<string>();
  @Output() loader: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private alertNotificationsComponent: AlertNotificationsComponent, private billingInfoService: BillingInfoService,
              private globals: Globals) {
  }

  ngOnInit() {
    this.getConfigStripe();
    this.createCustomer();
    this.checkAllowedTypes();
  }

  getConfigStripe() {
    let result: JSON;
    this.billingInfoService.getConfigStripe().subscribe((value => {
        result = value;
      }),
      error => {
        this.alertNotificationsComponent.showGenericDataError();
      },
      () => {
        this.stripeElements(result['publishableKey']);
      });
  }

  stripeElements(publishableKey) {
    try {
      this.stripe = Stripe(publishableKey);
      var elements = this.stripe.elements();

      var style = this.getStripeStyle();

      this.card = elements.create('card', {style: style});
      this.card.mount('#card-element');
      this.ibanElement = elements.create('iban', {style: style, supportedCountries: ['SEPA']});
      this.ibanElement.mount('#iban-element');

      this.setEventsToElements();
    } catch (e) {
      // Do nothing
    }
  }

  public createCustomer() {
    let result: JSON;
    this.billingInfoService.createCustomer().subscribe((value => {
        result = value;
      }),
      error => {
        try {
          let errorMessage = error.error.message;
          if (errorMessage.startsWith('visible: ')) {
            errorMessage = errorMessage.replace('visible: ', '');
            this.alertNotificationsComponent.showErrorMessage(errorMessage);
          } else {
            this.alertNotificationsComponent.showGenericDataError();
          }
        } catch (e) {
          this.alertNotificationsComponent.showGenericDataError();
        }
      },
      () => {
        this.clientSecret = result['clientSecret'];
      });
  }

  public getStripeStyle() {
    return {
      base: {
        fontSize: '16px',
        color: '#32325d',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: 'rgba(0,0,0,0.4)'
        }
      }
    };
  }

  public setEventsToElements() {
    for (let element of [this.card, this.ibanElement]) {
      element.on('focus', function () {
        var el = document.getElementById(`${element._componentName}-element`);
        el.classList.add('focused');
      });
      element.on('blur', function () {
        var el = document.getElementById(`${element._componentName}-element`);
        el.classList.remove('focused');
      });
      element.on('change', function (event) {
        var displayError = document.getElementById('error-message');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
    }
  }

  public submitPaymentMethod() {
    var payment = document.querySelector('input[name=payment]:checked').getAttribute('value');
    this.setupPaymentMethod(this.clientSecret, payment, {
      card: this.card,
      type_iban_element: this.ibanElement
    });
  }

  setupPaymentMethod(setupIntentSecret, paymentMethod, element) {

    var billingName = this.getName();
    var billingEmail = this.getMail();
    var billingPhone = this.getPhone();
    var enterpriseDir = this.getEnterpriseDir();
    var enterpriseCountry = this.getEnterpriseCountry();

    switch (paymentMethod) {
      case 'card':
        this.stripe
          .confirmCardSetup(setupIntentSecret, {
            payment_method: {
              card: element[paymentMethod],
              billing_details: {
                name: billingName,
                email: billingEmail,
                phone: billingPhone,
                address: {
                  line1: enterpriseDir,
                  country: enterpriseCountry
                }
              }
            }
          }).then((response) => this.handleAddPaymentResult(response, 'card'));
        break;
      case 'type_iban_element':
        this.stripe
          .confirmSepaDebitSetup(setupIntentSecret, {
            payment_method: {
              sepa_debit: element[paymentMethod],
              billing_details: {
                name: billingName,
                email: billingEmail,
                phone: billingPhone,
                address: {
                  line1: enterpriseDir,
                  country: enterpriseCountry
                }
              }
            }
          })
          .then((response) => this.handleAddPaymentResult(response, 'sepa_debit'));
        break;
      default:
        break;
    }


  }

  handleAddPaymentResult(result, type) {
    if (result.error) {
      this.loader.emit(false);
      this.showCardErrorTS(result.error);
    } else {
      this.addPaymentMethod(result.setupIntent['payment_method'], type);
    }
  }

  showCardErrorTS(error) {
    var errorMsg = document.querySelector('.sr-field-error');
    errorMsg.textContent = error.message;
    setTimeout(function () {
      errorMsg.textContent = '';
    }, 8000);
  }

  public addPaymentMethod(paymentMethodId, type) {
    this.billingInfoService.addPaymentMethod(paymentMethodId, type).subscribe((value => {
      }),
      error => {
        try {
          let errorMessage = error.error.message;
          if (errorMessage.startsWith('visible: ')) {
            errorMessage = errorMessage.replace('visible: ', '');
            this.alertNotificationsComponent.showErrorMessage(errorMessage);
          } else {
            this.alertNotificationsComponent.showGenericDataError();
          }
        } catch (e) {
          this.alertNotificationsComponent.showGenericDataError();
        }
      },
      () => {
        this.paymentMethodAdded.emit(paymentMethodId);
      });
  }

  public getName() {
    return this.globals.name;
  }

  public getMail() {
    return this.globals.mail;
  }

  public getPhone() {
    return this.globals.tfn;
  }

  public getEnterpriseDir() {
    return this.globals.enterpriseDir;
  }

  public getEnterpriseCountry() {
    return this.globals.enterpriseCountry;
  }

  private checkAllowedTypes() {
    if (this.singleType && !DataValidator.isNullOrUndefined(this.typeAllowed)) {
        this.typeView = this.typeAllowed;
    }
  }
}
