import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FieldErrorDisplayModule} from '../../field-error-display/field-error.module';

import {MyMaterialsModule} from '../../../modules/my-materials.module';
import {EmailInputComponent} from './email-input.component';

@NgModule({
  imports: [
    MyMaterialsModule,
    TranslateModule,
    FieldErrorDisplayModule,
    TranslateModule
  ],
  exports: [EmailInputComponent],
  declarations: [EmailInputComponent]
})

export class EmailInputModule {
}
