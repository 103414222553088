import {Injectable} from '@angular/core';

declare let gtag: (property: string, value: any, configs: any) => {};


@Injectable(
  {providedIn: 'root'}
)
export class GoogleAnalyticsHelper {

  public static readonly GOOGLE_ANALYTICS_TOKEN = 'UA-176152078-2';

  constructor() {
  }

  public pageLoaded(url) {
    gtag('config', GoogleAnalyticsHelper.GOOGLE_ANALYTICS_TOKEN, {
      page_path: url
    });
  }
}
