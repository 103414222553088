import {Injectable} from '@angular/core';
import {HubSpotService} from './HubSpot.service';
import {Globals} from '../../components/shared/models/Globals';
import {DataValidator} from '../../components/shared/components/inputs/utils/DataValidator';

declare var window: any;

@Injectable(
  {providedIn: 'root'}
)

export class HubSpotHelper {

  constructor(private hubSpotService: HubSpotService,
              private globals: Globals) {
  }

  public identifyHubSpotUser() {
    try {
      this.hubSpotService.getIdentifyToken().subscribe(res => {
        if (this.globals.mail) {
          window.hsConversationsSettings = {
            identificationEmail: this.globals.mail,
            identificationToken: res['token']
          };
          if (!DataValidator.isNullOrUndefined(window.HubSpotConversations)) {
            window.HubSpotConversations.clear({resetWidget: true});
          }
        }
      }, error => {
        // Do nothing
      });
    } catch (e) {
      // Do nothing
    }
  }
}
