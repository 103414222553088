import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Observable, Subscriber} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private titleComplement: string;
  private closeSidebarObserver: Subscriber<any>;
  public closeSidebarObservable: Observable<any>;

  constructor(private router: Router) {
    this.titleComplement = '';
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.titleComplement = '';
    });
    this.setObserver();
  }

  getTitleComplement() {
    return this.titleComplement;
  }

  setTitleComplement(titleComplement: string) {
    this.titleComplement = ': ' + titleComplement;
  }

  closeSidebar() {
    this.closeSidebarObserver.next();
  }

  private setObserver() {
    this.closeSidebarObservable = new Observable((observer) => {
      this.closeSidebarObserver = observer;
      return {
        unsubscribe() {
        }
      };
    });
  }
}
