export class DataValidator {

  static isEmpty(data: string): boolean {
    return this.isNullOrUndefined(data) || data === '' || data === "";
  }

  static isEmptyString(data: String): boolean {
    return this.isNullOrUndefined(data) || data === '' || data === "";
  }

  static isEmptyNumber(data: number): boolean {
    return this.isNullOrUndefined(data) || data == 0;
  }

  static isNullOrUndefined(data: any): boolean {
    return data == null || data == undefined;
  }
}
