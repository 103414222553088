import {RouteInfo} from '../sidebar.component';
import {Injectable} from '@angular/core';
import {CustomerType} from '../../../models/CustomerType';
import {Globals} from '../../../models/Globals';

@Injectable({
  providedIn: 'root'
})
export class SidebarHelper {

  static readonly ROUTES_COMPANY: RouteInfo[] = [{
    id: 'company-overview-tab',
    path: '/company-overview',
    title: 'MENU_COMPANY.OVERVIEW',
    type: 'link',
    icontype: 'dashboard'
  }, {
    id: 'my-company-tab',
    path: '/my-company',
    title: 'MENU_COMPANY.MY_COMPANY',
    type: 'link',
    icontype: 'apps'
  }, {
    id: 'ship-now-tab',
    path: '/ship-now',
    title: 'MENU_COMPANY.SHIP_NOW',
    type: 'link',
    icontype: 'date_range'
  }, {
    id: 'my-shipments-tab',
    path: '/company-shipments',
    title: 'MENU_COMPANY.MY_SHIPMENTS',
    type: 'link',
    icontype: 'dashboard'
  }, {
    id: 'ship-analytics-tab',
    path: '/company-ship-analytics',
    title: 'MENU_COMPANY.SHIPMENT_ANALYTICS',
    type: 'link',
    icontype: 'timeline'
  }, {
    id: 'invoices-tab',
    path: '/company-invoices',
    title: 'MENU_COMPANY.INVOICES',
    type: 'link',
    icontype: 'content_paste'
  }, {
    id: 'help-tab',
    path: '/company-help',
    title: 'MENU_COMPANY.HELP',
    type: 'link',
    icontype: 'school'
  }];

  static readonly ROUTES_FORWARDER: RouteInfo[] = [{
    id: 'company-overview-tab',
    path: '/forwarder-overview',
    title: 'MENU_FORWARDER.OVERVIEW',
    type: 'link',
    icontype: 'dashboard'
  }, {
    id: 'my-company-tab',
    path: '/my-transport-company',
    title: 'MENU_FORWARDER.MY_COMPANY',
    type: 'link',
    icontype: 'apps'
  }, {
    id: 'my-shipments-tab',
    path: '/forwarder-shipments',
    title: 'MENU_FORWARDER.MY_SHIPMENTS',
    type: 'link',
    icontype: 'date_range'
  }, {
    id: 'ship-analytics-tab',
    path: '/forwarder-ship-analytics',
    title: 'MENU_FORWARDER.SHIPMENT_ANALYTICS',
    type: 'link',
    icontype: 'timeline'
  }, {
    id: 'quotes-tab',
    path: '/my-quotes',
    title: 'MENU_FORWARDER.MY_QUOTES',
    type: 'link',
    icontype: 'grid_on'
  },
  {
      id: 'simulate-quotes-tab',
      path: '/simulate-quote',
      title: 'MENU_FORWARDER.SIMULATE_MY_QUOTES',
      type: 'link',
      icontype: 'quiz'
  },
  {
    id: 'invoices-tab',
    path: '/forwarder-invoices',
    title: 'MENU_FORWARDER.INVOICES',
    type: 'link',
    icontype: 'content_paste'
  }, {
    id: 'help-tab',
    path: '/forwarder-help',
    title: 'MENU_FORWARDER.HELP',
    type: 'link',
    icontype: 'school'
  }];

  alreadyAddedOffersTab = false;
  constructor(private globals: Globals) {
  }

  public getRoutes(customerType: CustomerType): RouteInfo[] {
    let routes;
    if (customerType === CustomerType.COMPANY) {
      routes = SidebarHelper.ROUTES_COMPANY;
      if (!this.alreadyAddedOffersTab) {
        routes = this.addOffersTabIfRequired(true, routes);
      }
    } else {
      routes = SidebarHelper.ROUTES_FORWARDER;
      if (!this.alreadyAddedOffersTab) {
        routes = this.addOffersTabIfRequired(false, routes);
      }
    }
    return routes;
  }

  private addOffersTabIfRequired(isCompany, routes) {
    if (this.globals.showIO) {
      if (isCompany) {
        routes.splice(5, 0, {
          id: 'offers-tab',
          path: '/company-offers',
          title: 'OFFERS.OFFERS_TITLE',
          type: 'link',
          icontype: 'receipt_long'
        });
        this.alreadyAddedOffersTab = true;
      } else {
        routes.splice(6, 0, {
          id: 'offers-tab',
          path: '/forwarder-offers',
          title: 'OFFERS.OFFERS_TITLE_FW',
          type: 'link',
          icontype: 'receipt_long'
        });
        this.alreadyAddedOffersTab = true;
      }
    }
    return routes;
  }

  public getMyAccountRoute(customerType: CustomerType): string {
    return customerType === CustomerType.COMPANY ? '/my-company-account' : '/my-forwarder-account';
  }

  public getMyCompanyTitle(customerType: CustomerType): string {
    return customerType === CustomerType.COMPANY ? 'My company account' : 'My forwarder account';
  }
}
