import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RegisterRequestMapper} from './mappers/request/RegisterRequestMapper';
import {RegisterResponseMapper} from './mappers/response/RegisterResponseMapper';
import {environment} from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private static REGISTER_ENDPOINT = environment.baseApiUrl + 'register-user';

  constructor(private http: HttpClient) {
  }

  register(registerValues: JSON) {
    const registerRequest = new RegisterRequestMapper(registerValues).map();
    return this.http.post(RegisterService.REGISTER_ENDPOINT, registerRequest)
      .map(value => new RegisterResponseMapper(value as JSON).map());
  }
}
