import {Injectable} from '@angular/core';
import {ClientIpAddressService} from './service/client-ip-address.service';
import {ClientIpAddress} from './models/ClientIpAddress';

@Injectable(
  {providedIn: 'root'}
)
export class ClientHelper {
  private readonly _hasTouchScreen: boolean;
  private clientIpAddress: string;

  constructor(private clientIpAddressService: ClientIpAddressService) {
    this.setIp();
    this._hasTouchScreen = 'ontouchstart' in window || navigator.msMaxTouchPoints > 1;
  }

  getIp() {
    return this.clientIpAddress;
  }

  private setIp() {
    this.clientIpAddressService.getClientIpAddress().subscribe((ipDTO: ClientIpAddress) => {
      this.clientIpAddress = ipDTO.ip;
    });
  }

  get hasTouchScreen(): boolean {
    return this._hasTouchScreen;
  }
}
