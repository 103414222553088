import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  globalLoaded: BehaviorSubject<boolean>;

  constructor() {
    this.globalLoaded = new BehaviorSubject<boolean>(false);
  }

  onGlobalLoaded(value): void {
    this.globalLoaded.next(value);
  }

}
