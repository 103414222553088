import {TypeMapper} from '../../../../../../../shared/models/TypeMapper';
import {RegisterRequest} from '../../models/request/RegisterRequest';
import {PhoneMapper} from './PhoneMapper';

export class RegisterRequestMapper extends TypeMapper<JSON, RegisterRequest> {
  map(): RegisterRequest {
    const registerRequest = new RegisterRequest();
    registerRequest.name = this.input['name'];
    registerRequest.surname = this.input['surname'];
    registerRequest.mail = this.input['mail'];
    registerRequest.enterprise = this.input['enterprise'];
    registerRequest.phone = new PhoneMapper(this.input['phone']).map();
    registerRequest.nif = this.input['nif'];
    registerRequest.password = this.input['password'];
    registerRequest.enterpriseType = this.input['enterpriseType'];
    registerRequest.lang = this.input['lang'];
    registerRequest.invitation = this.input['invitation'];
    return registerRequest;
  }
}
