import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillingInfoService {

  private static GET_CONFIG_STRIPE_ENDPOINT = environment.baseApiUrl + 'config-stripe';
  private static CREATE_CUSTOMER_ENDPOINT = environment.baseApiUrl + 'create-customer';
  private static ADD_PAYMENT_METHOD_ENDPOINT = environment.baseApiUrl + 'add-payment-method';
  private static GET_PAYMENT_METHODS_ENDPOINT = environment.baseApiUrl + 'get-payment-methods';
  private static DELETE_PAYMENT_METHOD_ENDPOINT = environment.baseApiUrl + 'delete-payment-method';
  private static SET_PAYMENT_METHOD_DEFAULT = environment.baseApiUrl + 'update-default-payment-method';

  constructor(private httpauth: HttpClient) {
  }

  getConfigStripe() {
    return this.httpauth.get(BillingInfoService.GET_CONFIG_STRIPE_ENDPOINT).map(value => value as JSON);
  }

  createCustomer() {
    return this.httpauth.post(BillingInfoService.CREATE_CUSTOMER_ENDPOINT, null).map(value => value as JSON);
  }

  addPaymentMethod(paymentMethodId, type) {
    return this.httpauth.post(BillingInfoService.ADD_PAYMENT_METHOD_ENDPOINT, {paymentMethodId: paymentMethodId, type: type});
  }

  getPaymentMethods() {
    return this.httpauth.get(BillingInfoService.GET_PAYMENT_METHODS_ENDPOINT).map(value => value as JSON);
  }

  deletePaymentMethod(paymentMethodId) {
    return this.httpauth.request('delete', BillingInfoService.DELETE_PAYMENT_METHOD_ENDPOINT, { body: paymentMethodId });
  }

  updatePaymentMethodDefault(paymentMethodId) {
    return this.httpauth.put(BillingInfoService.SET_PAYMENT_METHOD_DEFAULT, paymentMethodId);
  }
}
