import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-on-boarding-video',
  templateUrl: './company-on-boarding-video.component.html',
  styleUrls: ['./company-on-boarding-video.component.scss']
})
export class CompanyOnBoardingVideoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public pauseVideo() {
    (document.getElementById('on-boarding-embedded-video') as HTMLIFrameElement)
      .contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
  }

  public playVideo() {
    (document.getElementById('on-boarding-embedded-video') as HTMLIFrameElement)
      .contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
  }

}
