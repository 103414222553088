import {Component, ElementRef, HostListener, OnInit, Renderer, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {Location} from '@angular/common';
import 'rxjs/add/operator/filter';
import {SidebarHelper} from '../sidebar/helpers/SidebarHelper';
import {Globals} from '../../models/Globals';
import {CustomerType} from '../../models/CustomerType';
import {UserService} from '../../features/auth/services/user.service';
import {NavbarService} from './services/navbar.service';

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  private static readonly MOBILE_LIMIT = 992;

  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private _router: Subscription;
  public isMobileMenu = false;

  @ViewChild('app-navbar') button: any;

  @ViewChild('notificationsItem') notificationsItem: ElementRef;

  // TODO: Reactivar chat en un futuro
  // @ViewChild('minifiedNotificationsItem') minifiedNotificationsItem: ElementRef;
  private readonly NAVBAR_CLOSED_WIDTH_ISSUE = 992;
  private readonly NAVBAR_CLOSE_INDICATOR_WIDTH = 991;

  constructor(location: Location, private renderer: Renderer,
              private element: ElementRef, private router: Router,
              private sidebarHelper: SidebarHelper,
              private globals: Globals, private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private navbarService: NavbarService) {
    this.sidebarHelper = sidebarHelper;
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.observeSidebarClosing();
  }

  ngOnInit() {
    this.listTitles = this.sidebarHelper.getRoutes(this.globals.customerType)
      .filter(listTitle => listTitle);

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains('hide-sidebar')) {
      misc.hide_sidebar_active = true;
    }
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.sidebarClose();

      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });
    this.isMobileMenu = window.innerWidth < NavbarComponent.MOBILE_LIMIT;
    // TODO: Reactivar chat en un futuro
    // this.setMinifiedNotificationsVisibility();
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;

    } else {
      setTimeout(function () {
        body.classList.add('sidebar-mini');

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  hideSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const sidebar = document.getElementsByClassName('sidebar')[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(function () {
        body.classList.remove('hide-sidebar');
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        sidebar.classList.remove('animation');
      }, 600);
      sidebar.classList.add('animation');

    } else {
      setTimeout(function () {
        body.classList.add('hide-sidebar');
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  sidebarOpen() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('overflow-hidden');
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');
    setTimeout(function () {
      $toggle.classList.add('toggled');
    }, 430);

    const $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');


    if (body.querySelectorAll('.main-panel')) {
      document.getElementsByClassName('main-panel')[0].appendChild($layer);
    } else if (body.classList.contains('off-canvas-sidebar')) {
      document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add('visible');
    }, 100);

    $layer.onclick = () => this.sidebarClose();
    body.classList.add('nav-open');
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('overflow-hidden');
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');

    const $layer = document.getElementsByClassName('close-layer visible')[0];
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
    body.classList.remove('nav-open');
    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
      $toggle.classList.remove('toggled');
    }, 400);

    this.mobile_menu_visible = 0;
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    if (this.isMyCompanyAccountLink()) {
      return 'MENU_COMPANY.MY_ACCOUNT';
    } else if (this.isMyForwarderAccountLink()) {
      return 'MENU_FORWARDER.MY_ACCOUNT';
    } else if (this.isNotificationsLink()) {
      return 'MENU_SHARED.NOTIFICATIONS';
    } else if (this.isChatLink()) {
      return 'MENU_SHARED.CHAT';
    } else if (this.isShipmentDetailLink()) {
      return 'MENU_SHARED.SHIPMENT_DETAIL';
    } else {
      const titlee: any = this.location.prepareExternalUrl(this.location.path());
      for (let i = 0; i < this.listTitles.length; i++) {
        if (this.listTitles[i].type === 'link' && titlee.includes(this.listTitles[i].path)) {
          return this.listTitles[i].title;
        } else if (this.listTitles[i].type === 'sub') {
          for (let j = 0; j < this.listTitles[i].children.length; j++) {
            const subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
            if (titlee.includes(subtitle)) {
              return this.listTitles[i].children[j].title;
            }
          }
        }
      }
      return '';
     }
  }



  getTitleComplement() {
    return this.navbarService.getTitleComplement();
  }

  isShipmentDetailLink() {
    return this.router.url.includes('/company-shipment-detail') || this.router.url.includes('/forwarder-shipment-detail');
  }

  isMyCompanyAccountLink(): boolean {
    return this.router.url === '/my-company-account';
  }

  isMyForwarderAccountLink(): boolean {
    return this.router.url === '/my-forwarder-account';
  }

  isNotificationsLink(): boolean {
    return this.router.url === '/company-notifications' || this.router.url === '/forwarder-notifications';
  }

  isChatLink(): boolean {
    return this.router.url === '/company-chat' || this.router.url === '/forwarder-chat';
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  switchProfile() {
    if (this.globals.isCompany()) {
      this.globals.customerType = CustomerType.FORWARDER;
      this.router.navigate(['/forwarder-overview'], {replaceUrl: true});
    } else {
      this.globals.customerType = CustomerType.COMPANY;
      this.router.navigate(['/company-overview'], {replaceUrl: true});
    }
  }

  getSeeMoreRouterLink(): string {
    return this.globals.isCompany() ? '/company-notifications'
      : '/forwarder-notifications';
  }

  getChatLink(): string {
    return this.globals.isCompany() ? '/company-chat'
      : '/forwarder-chat';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const navBarIssueWidth = this.NAVBAR_CLOSED_WIDTH_ISSUE;
    const navBar: Element = document.getElementsByClassName('sidebar navbar')[0];
    navBar.setAttribute('style',
      event.target.innerWidth < navBarIssueWidth ? 'display:block;' : 'display:none;');
    if (event.target.innerWidth > this.NAVBAR_CLOSE_INDICATOR_WIDTH
      && this.sidebarVisible) {
      this.sidebarClose();
    }
    this.isMobileMenu = event.target.innerWidth < NavbarComponent.MOBILE_LIMIT;
    // TODO: Reactivar chat en un futuro
    // this.setMinifiedNotificationsVisibility();
  }

  /* TODO: Reactivar chat en un futuro
  private setMinifiedNotificationsVisibility() {
    if (this.isMobileMenu) {
      this.minifiedNotificationsItem.nativeElement.style.display = 'initial';
    } else {
      this.minifiedNotificationsItem.nativeElement.style.display = 'none';
    }
  }*/

  lock() {
    this.userService.lock();
  }

  private observeSidebarClosing() {
    this.navbarService.closeSidebarObservable.subscribe(() => {
      this.sidebarClose();
    });
  }
}
