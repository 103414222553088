import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {Subscription} from 'rxjs/Subscription';
import {Location} from '@angular/common';
import 'rxjs/add/operator/filter';
import {NavbarComponent} from '../../shared/components/navbar/navbar.component';
import PerfectScrollbar from 'perfect-scrollbar';
import {NavItem} from '../../shared/modules/md/md.module';
import {HotkeysService} from 'angular2-hotkeys';
import {ShortcutPage} from '../../shared/features/shortcuts/ShortcutPage';
import {UserService} from '../../shared/features/auth/services/user.service';

declare const $: any;


@Component({
  selector: 'app-company-admin',
  templateUrl: './company-admin.component.html',
  styleUrls: ['./company-admin.component.scss']
})

export class CompanyAdminComponent extends ShortcutPage implements OnInit, AfterViewInit {
  public navItems: NavItem[];
  private _router: Subscription;
  url: string;
  location: Location;

  @ViewChild(NavbarComponent) navbar: NavbarComponent;

  constructor(private router: Router, location: Location,
              private _hotkeys: HotkeysService, private userService: UserService) {
    super(_hotkeys);
    this.location = location;
  }

  ngOnInit() {
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      elemSidebar.scrollTop = 0;
    });
    const html = document.getElementsByTagName('html')[0];
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemSidebar);
      html.classList.add('perfect-scrollbar-on');
    } else {
      html.classList.add('perfect-scrollbar-off');
    }
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.navbar.sidebarClose();
    });

    this.navItems = [];
  }

  ngAfterViewInit() {
    this.runOnRouteChange();
  }

  public isMap() {
    if (this.location.prepareExternalUrl(this.location.path()) === '/maps/fullscreen') {
      return true;
    } else {
      return false;
    }
  }

  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      let ps = new PerfectScrollbar(elemSidebar);
      ps.update();
    }
  }

  isMac(): boolean {
    let isMac = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      isMac = true;
    }
    return isMac;
  }

  onLockShortcut() {
    this.userService.lock();
  }
}
