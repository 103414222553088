import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {ChatWorkerBackgroundComponent} from './chat-worker-background.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        RouterModule
    ]
    ,
  declarations: [ChatWorkerBackgroundComponent],
  exports: [ChatWorkerBackgroundComponent]
})
export class ChatWorkerBackgroundModule {
}
