import {FormGroup} from '@angular/forms';

export class FormUtils {

  static markIncorrectFields(formGroup: FormGroup) {
    // noinspection TsLint
    for (const field in formGroup.controls) {
      const control = formGroup.get(field);
      control.markAsTouched({onlySelf: true});
    }
  }

  static resetFields(formGroup: FormGroup, fieldsExcluded = []) {
    // noinspection TsLint
    for (const field in formGroup.controls) {
      if (!fieldsExcluded.includes(field)) {
        const control = formGroup.get(field);
        control.enable();
        control.reset();
      }
    }
  }

  static getJSONString(formGroup: FormGroup): string {
    return JSON.stringify(formGroup.value);
  }
}
