import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class Chathelpers {

  static parseTime(timeString: string) {
    const time = new Date(timeString).getTime();
    return moment(time).fromNow();
  }

  static scrollTo(elem, position) {
    try {
      const
        elemHeight = elem.offsetHeight,
        elemScrollHeight = elem.scrollHeight;

      if (position === 'bottom') {
        if ((elemScrollHeight - elemHeight) > 0) {
          elem.scrollTop = elemScrollHeight;
        }
      } else if (position === 'top') {
        elem.scrollTop = 10;
      } else if (+position) {
        elem.scrollTop = +position;
      }
    } catch (e) {
      // Do nothing
    }
  }


}
