import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FieldErrorDisplayModule} from '../../field-error-display/field-error.module';

import {MyMaterialsModule} from '../../../modules/my-materials.module';
import {PasswordInputComponent} from './password-input.component';
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';

@NgModule({
  imports: [
    MyMaterialsModule,
    TranslateModule,
    FieldErrorDisplayModule,
    MatPasswordStrengthModule,
    TranslateModule
  ],
  exports: [PasswordInputComponent],
  declarations: [PasswordInputComponent]
})

export class PasswdInputModule {
}
