import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FieldErrorDisplayModule} from '../../field-error-display/field-error.module';
import {MyMaterialsModule} from '../../../modules/my-materials.module';
import {InnRadioGroupComponent} from './inn-radio-group.component';

@NgModule({
  imports: [
    MyMaterialsModule,
    TranslateModule,
    FieldErrorDisplayModule,
    TranslateModule
  ],
  exports: [InnRadioGroupComponent],
  declarations: [InnRadioGroupComponent]
})

export class InnRadioGroupModule {
}
