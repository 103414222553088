import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SidebarComponent} from './sidebar.component';
import {LanguageSwitchItemModule} from '../language-switch-item/language-switch-item.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [ RouterModule, CommonModule, LanguageSwitchItemModule, TranslateModule ],
    declarations: [ SidebarComponent ],
    exports: [ SidebarComponent ]
})

export class SidebarModule {

  constructor() {

  }

}
