import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ChatUser} from './models/ChatUser';
import {ChatDialog} from './models/ChatDialog';
import {ShipmentChatDialogId} from './models/ShipmentChatDialogId';
import {ShipmentChatTrackNumber} from './models/ShipmentChatTrackNumber';

@Injectable({
  providedIn: 'root'
})
export class InnportingChatServicesService {

  private static GET_CHAT_USER_ENDPOINT = environment.baseApiUrl + 'get-chat-user';
  private static CREATE_CHAT_USER_ENDPOINT = environment.baseApiUrl + 'create-chat-user';
  private static CREATE_DIALOG_ENDPOINT = environment.baseApiUrl + 'create-dialog';
  private static GET_DIALOG_ID_BY_TRACK_NUMBER = environment.baseApiUrl + 'get-dialog-by-shipment';

  constructor(private httpauth: HttpClient) { }

  getChatUser(id: number) {
    return this.httpauth.get(`${InnportingChatServicesService.GET_CHAT_USER_ENDPOINT}?id=${id}`)
      .map(value => value as ChatUser);
  }

  createChatUser(chatUser: ChatUser) {
    return this.httpauth.post(InnportingChatServicesService.CREATE_CHAT_USER_ENDPOINT, chatUser);
  }

  createChatDialog(chatDialog: ChatDialog) {
    return this.httpauth.post(InnportingChatServicesService.CREATE_DIALOG_ENDPOINT, chatDialog);
  }

  getChatDialogByTrackNumber(shipmentTrackNumber: ShipmentChatTrackNumber) {
    return this.httpauth.post(InnportingChatServicesService.GET_DIALOG_ID_BY_TRACK_NUMBER, shipmentTrackNumber)
      .map(value => value as ShipmentChatDialogId);
  }
}
