import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ValidableInputComponent} from '../validable-input/validable-input.component';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['../validable-input/validable-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordInputComponent extends ValidableInputComponent implements OnInit {

  @Input() confirmPasswordComponent?: PasswordInputComponent;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getErrorText(): string {
    if (this.isFirstInput()) {
      return 'INPUTS.ERRORS.PASSWD_FIELD_1';
    } else {
      if (this.getValue().length < 8) {
        return 'INPUTS.ERRORS.PASSWD_FIELD_1';
      } else {
        if (this.getValue() !== this.formGroup.get('password').value) {
          return 'INPUTS.ERRORS.PASSWD_FIELD_2';
        }
      }
    }
  }

  isFirstInput() {
    return this.customId === 'password' || this.customId === 'currentPassword';
  }

  validation(value) {
    if (this.isFirstInput()) {
      this.isValid = value.length > 7;
      if (this.confirmPasswordComponent !== undefined) {
        this.confirmPasswordComponent.isValid = this.getValue().length > 7 &&
          this.getValue() === this.formGroup.get('confirmPassword').value;
        this.confirmPasswordComponent.displayErrors('confirmPassword');
      }
    } else {
      this.isValid = this.getValue().length > 7 && this.formGroup.get('password').value === value;
    }
  }

}
