import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../shared/features/auth/services/user.service';
import {AlertNotificationsComponent} from '../../../shared/components/alert-notifications/alert-notifications.component';
import {ActivatedRoute, Router} from '@angular/router';
import {DataValidator} from '../../../shared/components/inputs/utils/DataValidator';
import {FormUtils} from '../../../shared/components/inputs/utils/FormUtils';
import {PassWrapper} from './services/models/PassWrapper';
import {RecoverPassUserService} from './services/recover-pass-user.service';
import {LoaderComponent} from '../../../shared/components/loader/loader/loader.component';
import {InnComponent} from '../../../shared/components/inn-component/InnComponent';

@Component({
  selector: 'app-recover-pass-user',
  templateUrl: './recover-pass-user.component.html',
  styleUrls: ['./recover-pass-user.component.scss']
})
export class RecoverPassUserComponent extends InnComponent implements OnInit, OnDestroy {

  public recoverPassFormGroup: FormGroup;
  private toggleButton: any;
  private token: string;

  constructor(private userService: UserService, private recoverPassFormBuilder: FormBuilder,
              private element: ElementRef, private recoverPasswordService: RecoverPassUserService,
              private alertNotificationsComponent: AlertNotificationsComponent,
              private router: Router, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.userService.logoutWithoutNavigation();
    this.initNavbar();
    this.initRecoverPassFormBuilder();
    this.getToken();
  }

  private initRecoverPassFormBuilder() {
    this.recoverPassFormGroup = this.recoverPassFormBuilder.group({
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])]
    });
  }

  onInputsKeyDown(event) {
    if (event.key === 'Enter') {
      this.recoverPass();
    }
  }

  private initNavbar() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    body.classList.add('off-canvas-sidebar');
    const card = document.getElementsByClassName('card')[0];
    setTimeout(function () {
      card.classList.remove('card-hidden');
    }, 700);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    body.classList.remove('off-canvas-sidebar');
  }

  recoverPass() {
    if (this.recoverPassFormGroup.valid) {
      var passWrapper = new PassWrapper();
      passWrapper.pass = this.recoverPassFormGroup.get('password').value;
      passWrapper.token = this.token;
      LoaderComponent.show();
      const subscription = this.recoverPasswordService.recoverPasswdUser(passWrapper).subscribe(next => {
        LoaderComponent.hide();
        var self = this;
        self.alertNotificationsComponent.showChangeUserPasswordSuccess();
        setTimeout(function () {
          self.login(next.mail, passWrapper.pass);
        }, 1000);
      }, error => {
        LoaderComponent.hide();
        if(!DataValidator.isNullOrUndefined(error) &&
          !DataValidator.isNullOrUndefined(error.error) &&
          error.error.message == "UserNotFound") {
          this.alertNotificationsComponent.showUserOrPasswordError();
        } else {
          this.alertNotificationsComponent.showGenericDataError();
        }
      }, () => {
        LoaderComponent.hide();
      });
      this.pushSubscription(subscription);
    } else {
      FormUtils.markIncorrectFields(this.recoverPassFormGroup);
    }
  }

  login(mail, pass) {
      this.userService.loginGetInitInfoAndEnter(mail, pass);
  }

  private getToken() {
    const subscription = this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      if (DataValidator.isEmpty(this.token)) {
        this.alertNotificationsComponent.showGenericDataError();
        this.router.navigate(['/login']);
      }
    });
    this.pushSubscription(subscription);
  }
}
