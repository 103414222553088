import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FieldErrorDisplayModule} from '../../field-error-display/field-error.module';
import {MyMaterialsModule} from '../../../modules/my-materials.module';
import {TelInputComponent} from './tel-input.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-angular7';

@NgModule({
  imports: [
    MyMaterialsModule,
    TranslateModule,
    FieldErrorDisplayModule,
    NgxIntlTelInputModule,
    TranslateModule
  ],
  exports: [TelInputComponent],
  declarations: [TelInputComponent]
})

export class TelInputModule {
}
