import {Injectable} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {DataValidator} from '../../inputs/utils/DataValidator';
import {PaymentsService} from './payments.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {
  paymentMethodsData: JSON;
  private observers: Subscriber<JSON>[];

  constructor(private paymentsService: PaymentsService) {
    this.observers = [];
  }

  getPaymentMethods(reload = false): Observable<JSON> {
    return new Observable(observer => {
      if (!reload && !DataValidator.isNullOrUndefined(this.paymentMethodsData)) {
        observer.next(this.paymentMethodsData);
        observer.complete();
      } else {
        this.observers.push(observer);
        this.getData();
      }
      return {
        unsubscribe() {
        }
      };
    });
  }

  private getData() {
    this.paymentsService.getPaymentMethods().subscribe(value => {
        if (this.observers.length > 0) {
          this.paymentMethodsData = value;
          this.triggerObserversValue(value);
        }
      }, error => {
        this.triggerObserversError(error);
      },
      () => {
        this.triggerObserversComplete();
      });
  }

  private triggerObserversValue(value: JSON) {
    this.observers.forEach(observer => {
      observer.next(value);
    });
  }

  private triggerObserversError(error: ErrorEvent) {
    this.observers.forEach(observer => {
      observer.error(error);
    });
  }

  private triggerObserversComplete() {
    this.observers.forEach(observer => {
      observer.complete();
    });
  }
}
