export class LocalStorageHelper {

  static readonly LANG_KEY: string = 'lang';
  static readonly ATOKEN_KEY: string = 'atoken';

  static existsItem(key: string) {
    return localStorage.getItem(key) !== null;
  }

  static getItem(key: string) {
    return localStorage.getItem(key);
  }

  static setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  static removeItem(key: string) {
    return localStorage.removeItem(key);
  }
}
