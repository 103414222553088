import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {APP_BASE_HREF, CommonModule, DatePipe} from '@angular/common';
import {AppComponent} from './app.component';
import {SidebarModule} from './components/shared/components/sidebar/sidebar.module';
import {FooterModule} from './components/pages/shared-pages/footer/footer.module';
import {NavbarModule} from './components/shared/components/navbar/navbar.module';
import {CompanyAdminComponent} from './components/layouts/company-admin/company-admin.component';
import {ForwarderAdminComponent} from './components/layouts/forwarder-admin/forwarder-admin.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {HotkeyModule} from 'angular2-hotkeys';
import {AppRoutes} from './app-routing.module';
import {JwtModule} from '@auth0/angular-jwt';
import {LoginComponent} from './components/pages/shared-pages/login/login.component';
import {CompletePagesNavbarComponent} from './components/pages/shared-pages/complete-pages-navbar/complete-pages-navbar.component';
import {CompletePagesFooterComponent} from './components/pages/shared-pages/complete-pages-footer/complete-pages-footer.component';
import {RegisterComponent} from './components/pages/shared-pages/register/register.component';
import {LockComponent} from './components/pages/shared-pages/lock/lock.component';
import {NotFoundPageComponent} from './components/pages/shared-pages/not-found-page/not-found-page.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {LanguageSwitchItemModule} from './components/shared/components/language-switch-item/language-switch-item.module';
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';
import {BsDropdownModule} from 'ngx-bootstrap';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-angular7';
import {AuthenticationService} from './components/shared/features/auth/services/authentication.service';
import {UserService} from './components/shared/features/auth/services/user.service';
import {AuthGuard} from './components/shared/features/auth/guards/auth-guard.service';
import {AdminAuthGuard} from './components/shared/features/auth/guards/admin-auth-guard.service';
import {RequiredInputModule} from './components/shared/components/inputs/required-input/required-input.module';
import {FieldErrorDisplayModule} from './components/shared/components/field-error-display/field-error.module';
import {PasswdInputModule} from './components/shared/components/inputs/password-input/passwd-input.module';
import {EmailInputModule} from './components/shared/components/inputs/email-input/email-input.module';
import {CheckInputModule} from './components/shared/components/inputs/check-input/check-input-input.module';
import {AlertNotificationsComponent} from './components/shared/components/alert-notifications/alert-notifications.component';
import {TelInputModule} from './components/shared/components/inputs/tel-input/tel-input.module';
import {LockGuard} from './components/shared/features/auth/guards/lock-guard.service';
import {MyMaterialsModule} from './components/shared/modules/my-materials.module';
import {TOKEN_NAME} from './components/shared/features/auth/services/auth.constant';
import {NumberInputModule} from './components/shared/components/inputs/number-input/number-input.module';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material';
import {PaymentsModule} from './components/shared/components/payments/payments.module';
import {LoaderModule} from './components/shared/components/loader/loader/loader.module';
import {RecoverPassComponent} from './components/pages/shared-pages/recover-pass/recover-pass.component';
import {RecoverPassUserComponent} from './components/pages/shared-pages/recover-pass-user/recover-pass-user.component';
import {ChatDashboardModule} from './components/shared/components/chat/chat-dashboard.module';
import {CookieConsentComponent} from './components/shared/components/cookie-consent/cookie-consent.component';
import {ComanyOnBoardingModule} from './components/shared/components/on-boarding/company-on-boarding/comany-on-boarding.module';
import {ChatWorkerBackgroundModule} from './components/shared/components/chat/chat-worker-background/chat-worker-background.module';
import {ChangeSafetyGuard} from './components/shared/interfaces/ChangeSafetyGuard';
import {NavigationService} from './utils/NavigationService/navigation.service';
import {InnRadioGroupModule} from './components/shared/components/inputs/inn-radio-group/inn-radio-group.module';
import { SectionLoaderComponent } from './components/shared/components/section-loader/section-loader.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return localStorage.getItem(TOKEN_NAME);
}

@NgModule({
  exports: [
    MyMaterialsModule,

  ],
  declarations: [],
  imports: [
    TranslateModule,
    RouterModule
  ]
})

// TODO: Arreglar esto de los módulos
export class MaterialModule {
}

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes),
        MatNativeDateModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        HttpClientModule,
        LanguageSwitchItemModule,
        HotkeyModule.forRoot(),
        ReactiveFormsModule,
        MatPasswordStrengthModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxIntlTelInputModule,
        TelInputModule,
        RequiredInputModule,
        NumberInputModule,
        PasswdInputModule,
        FieldErrorDisplayModule,
        EmailInputModule,
        CheckInputModule,
        LoaderModule,
        ChatDashboardModule,
        ChatWorkerBackgroundModule,
        InnRadioGroupModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['127.0.0.1:8080', 'https://api.innporting.com', 'api.innporting.com', 'https://dev.api.innporting.com', 'dev.api.innporting.com']
            },
        }),
        PaymentsModule,
        ComanyOnBoardingModule
    ],
  declarations: [
    AppComponent,
    CompanyAdminComponent,
    ForwarderAdminComponent,
    LoginComponent,
    RegisterComponent,
    LockComponent,
    RecoverPassComponent,
    RecoverPassUserComponent,
    CompletePagesNavbarComponent,
    CompletePagesFooterComponent,
    AlertNotificationsComponent,
    NotFoundPageComponent,
    CookieConsentComponent
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    AuthenticationService,
    UserService,
    AuthGuard,
    AdminAuthGuard,
    LockGuard,
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    ChangeSafetyGuard,
    NavigationService
  ],
  bootstrap: [AppComponent]
})
// TODO: Do with ip MAT_DATE_LOCALE?
export class AppModule {
}

