import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AlertNotificationsComponent} from '../../alert-notifications/alert-notifications.component';
import {DataValidator} from '../../inputs/utils/DataValidator';
import {EmptyStateComponent} from '../../empty-state/empty-state.component';
import {PaymentMethodsService} from '../services/payment-methods.service';

@Component({
  selector: 'app-payment-method-selector',
  templateUrl: './payment-method-selector.component.html',
  styleUrls: ['./payment-method-selector.component.scss']
})
export class PaymentMethodSelectorComponent implements OnInit {
  static readonly BANK_TRANSFER_METHOD = 'bank_transfer';
  static readonly ALL_METHODS_ALLOWED = ['sepa_debit', 'card', 'bank_transfer'];
  static readonly ONLY_INSTANT_METHODS_ALLOWED = ['sepa_debit', 'card'];

  paymentsMethods = [];
  defaultPaymentMethod;
  choosenPaymentMethod;
  isLoading = true;

  @Input() methodsAllowed: string[] = PaymentMethodSelectorComponent.ALL_METHODS_ALLOWED;

  @Output() paymentMethodSelected: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(EmptyStateComponent) emptyStateComponent: EmptyStateComponent;

  constructor(private alertNotificationsComponent: AlertNotificationsComponent,
              private paymentMethodsService: PaymentMethodsService) {
  }

  ngOnInit() {
    this.getPaymentMethods(null);
  }

  public getPaymentMethods(paymentMethodToChoose, reload = false) {
    this.paymentMethodsService.getPaymentMethods(reload).subscribe((value => {
        this.paymentsMethods = value['paymentMethods'].filter(pm => this.methodsAllowed.includes(pm.type));
        if (this.paymentMethodIdPresentInList(value['defaultPaymentMethod'])) {
          this.defaultPaymentMethod = value['defaultPaymentMethod'];
        } else if (this.paymentsMethods.length > 0) {
          this.defaultPaymentMethod = this.paymentsMethods[0].id;
        }
        this.isLoading = false;
      }),
      error => {
        this.alertNotificationsComponent.showGenericDataError();
        this.isLoading = false;
      },
      () => {
        if (paymentMethodToChoose != null &&
          (this.paymentMethodIdPresentInList(paymentMethodToChoose) ||
            paymentMethodToChoose === PaymentMethodSelectorComponent.BANK_TRANSFER_METHOD)
        ) {
          this.choosenPaymentMethod = paymentMethodToChoose;
        } else {
          if (DataValidator.isNullOrUndefined(this.paymentsMethods) || this.paymentsMethods && this.paymentsMethods.length == 0) {
            this.choosenPaymentMethod = 'new_payment_method';
          } else {
            this.choosenPaymentMethod = this.defaultPaymentMethod;
          }
        }
        this.paymentMethodChanged();
        this.isLoading = false;
      });
  }

  paymentMethodChanged() {
    this.paymentMethodSelected.emit(this.choosenPaymentMethod);
  }

  private paymentMethodIdPresentInList(paymentMethodId: string) {
    let found = false, i = 0;
    while (!found && i < this.paymentsMethods.length) {
      found = this.paymentsMethods[i].id === paymentMethodId;
      i++;
    }
    return found;
  }
}
