/**
 * TI: TypeInput
 * TO: TypeOutput
 */
export abstract class TypeMapper<TI, TO> {

  protected input: TI;

  constructor(input: TI) {
    this.input = input;
  }

  abstract map(): TO;

  setInput(input: TI) {
    this.input = input;
  }
}
