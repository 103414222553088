import {AbstractControl} from '@angular/forms';

export class PasswordValidator {

  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors({MatchPassword: true});
    } else {
      if (confirmPassword !== undefined && confirmPassword !== null && confirmPassword.length > 7) {
        AC.get('confirmPassword').setErrors(null);
      }
    }
  }
}
