import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ValidableInputComponent} from '../validable-input/validable-input.component';
import {RegisterComponent} from '../../../../pages/shared-pages/register/register.component';

@Component({
  selector: 'app-email-input',
  templateUrl: '../validable-input/validable-input.component.html',
  styleUrls: ['../validable-input/validable-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailInputComponent extends ValidableInputComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  validation(value) {
    const re = RegisterComponent.EMAIL_REGEXP;
    this.isValid = re.test(String(value).toLowerCase());
  }

  getErrorText(): string {
    return 'INPUTS.ERRORS.EMAIL';
  }
}
