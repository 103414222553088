import {Subscription} from 'rxjs';
import {ChangeDetectorRef, OnDestroy} from '@angular/core';
import {DataValidator} from '../inputs/utils/DataValidator';

export class InnComponent implements OnDestroy {
  private subscriptions: Subscription[];
  private changeDetectorReference: ChangeDetectorRef;

  constructor(changeDetectorReference?: ChangeDetectorRef) {
    this.subscriptions = [];
    this.changeDetectorReference = changeDetectorReference;
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      try {
        subscription.unsubscribe();
      } catch (e) {
        // Do nothing
      }
    }
  }

  pushSubscription(sub: Subscription) {
    this.subscriptions.push(sub);
  }

  detectChanges() {
    try {
      this.changeDetectorReference.detectChanges();
    } catch (e) {
        if (DataValidator.isNullOrUndefined(this.changeDetectorReference)) {
          throw new Error('Missing changeDetectorRef: Change detector must be provided at constructor');
        }
    }
  }
}
