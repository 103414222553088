import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ChatDashboardService} from './chat-dashboard.service';

import {ChatUserService} from './chat-user/chat-user.service';
import {QBHelper} from './chat-helper/qbHelper';
import {ChatMessageService} from './chat-messages/chat-message.service';
import {DialogChatService} from './chat-dialogs/dialog-chat.service';
import {Globals} from '../../models/Globals';
import {GlobalService} from '../../service/global.service';
import {CreateChatDialogComponent} from './create-chat-dialog/create-chat-dialog.component';
import {InnportingChatServicesService} from './innporting-chat-services/innporting-chat-services.service';
import {ShipmentChatTrackNumber} from './innporting-chat-services/models/ShipmentChatTrackNumber';
import {AlertNotificationsComponent} from '../alert-notifications/alert-notifications.component';
import {LoaderComponent} from '../loader/loader/loader.component';
import {
  ShipmentDetailService
} from '../../../pages/shared-pages/my-shipments/shipment-detail/shipment-detail/services/shipment-detail.service';

@Component({
  selector: 'app-chat-dashboard',
  templateUrl: './chat-dashboard.component.html',
  styleUrls: ['./chat-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

@Injectable({
  providedIn: 'root'
})
export class ChatDashboardComponent implements OnInit {

  @Input()
  public myHidden?: boolean = false;

  @Input()
  public comesFromAppComponent?: boolean = false;

  @ViewChild(CreateChatDialogComponent)
  createChatDialogComponent: CreateChatDialogComponent;

  loggedinUser: any;

  public chats: any = [];

  chatsClicked = false; // For displaying OneToOne and Group Chats
  publicChatClicked = false; // For displaying Public Chats
  createGroupClicked = false; // For creating OneToOne and Group Chats
  onChatClick = false; // For displaying messages ( Dialog Component )
  welcomeChat = true; // Display default Welcome Chat screen
  updateDialog = false; // For displaying update dialog

  dialog: any;
  selectedChat: string;
  private successUnSubscribe$;

  comesFromOneShipment = false;

  constructor(
    private dashboardService: ChatDashboardService,
    public dialogService: DialogChatService,
    private userService: ChatUserService,
    private qbHelper: QBHelper,
    private messageService: ChatMessageService,
    public globals: Globals,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private innportingChatServices: InnportingChatServicesService,
    private alertNotificationsComponent: AlertNotificationsComponent,
    private changeDetectorRef: ChangeDetectorRef,
    private shipmentDetailService: ShipmentDetailService
  ) {
  }

  ngOnInit() {
    this.hideNotificationsWrapper();
    this.globalService.globalLoaded.subscribe(value => {
      if (value && this.qbHelper.getSession()) {
        this.login();
      }
    }, error => {
      this.alertNotificationsComponent.showGenericDataError();
    }, () => {
    });
  }

  hideNotificationsWrapper() {
    try {
      document.getElementById("notificationsWrapper1").style.display = 'none';
      document.getElementById("notificationsWrapper2").style.display = 'none';
    } catch (e) {
      // Do nothing
    }
  }

  public constructorMine() {
    this.dialogService.dialogsEvent.subscribe((chatData: any[]) => {
      this.comesFromOneShipment = this.router.url.includes("shipment-detail");

      if (this.comesFromOneShipment) {
        this.shipmentDetailService.waitForMyShipmentToBeLoaded().subscribe(() => {
          this.chats = Object.values(chatData).filter(chat=>chat.name.includes(this.shipmentDetailService.getMyShipment().trackNumber));
          if (this.chats.length > 0) {
            this.openChat(this.chats[0]);
            try {
              this.changeDetectorRef.detectChanges();
            } catch (e) {
              // Do nothing
            }
          }
        });
      } else {
        this.chats = Object.values(chatData);
      }
      try {
        this.changeDetectorRef.detectChanges();
      } catch (e) {
        // Do nothing
      }
    });
    this.dialogService.currentDialogEvent.subscribe((dialog) => {
      this.selectedChat = dialog._id;
      this.dialog = dialog;
    });
    this.dashboardService.componentsEvent.subscribe((components: Object) => {
      Object.entries(components).forEach(([key, value]) => {
        this[key] = value;
      });
    });
  }

  public onInit() {
    this.welcomeChat = true;
    this.loggedinUser = this.userService.user;
    // console.log('Logged In === ', this.loggedinUser);

    this.comesFromOneShipment = this.router.url.includes('shipment-detail');

    if (this.comesFromOneShipment) {
      this.shipmentDetailService.waitForMyShipmentToBeLoaded().subscribe(() => {
        const shipmentTrackNumber = this.shipmentDetailService.getMyShipment().trackNumber;
        const shipmentChatTrackNumber = new ShipmentChatTrackNumber();
        shipmentChatTrackNumber.shipmentChatTrackNumber = shipmentTrackNumber as string;
        this.innportingChatServices.getChatDialogByTrackNumber(shipmentChatTrackNumber).subscribe((
            value => {
              this.getChatList('chat', this.comesFromOneShipment, value.shipmentChatDialogId);
              this.messageService.setListeners(value.shipmentChatDialogId);
              this.createChatDialogComponent.onInit();
              try {
                this.changeDetectorRef.detectChanges();
              } catch (e) {
                // Do nothing
              }
            }),
          error => {
            this.alertNotificationsComponent.showGenericDataError();
          },
          () => {
          });
      });
    } else {
      this.getChatList('chat', this.comesFromOneShipment, undefined);
      this.messageService.setListeners();
      this.createChatDialogComponent.onInit();
    }
  }

  login() {
      if (this.comesFromAppComponent) {
        if (!this.isChatUrl()) {
          const self = this;
          try {
            self.qbHelper.qbLogout();
            self.userService.login({
              userLogin: self.globals.getChatUser().userLoginName,
              userName: self.globals.getChatUser().userName,
              userPass: self.globals.getChatUser().userPass
            }).then(() => {
              self.constructorMine();
              self.onInit();
              self.checkIfCanCreateDialog();
            });
          } catch (e) {
            // Do nothing
          }
        }
      } else {
        try {
          const self = this;
          self.qbHelper.qbLogout();
          self.userService.login({
            userLogin: self.globals.getChatUser().userLoginName,
            userName: self.globals.getChatUser().userName,
            userPass: self.globals.getChatUser().userPass
          }).then(() => {
            self.constructorMine();
            self.onInit();
            self.checkIfCanCreateDialog();
          });
        } catch (e) {
          // Do nothing
        }
      }
  }

  isChatUrl() {
    return window.location.href.includes("forwarder-chat") || window.location.href.includes("company-chat")
      || window.location.href.includes("shipment-detail");
  }

  checkIfCanCreateDialog() {
    try {
      if(sessionStorage.getItem("comesFromCreateChat") == "true") {
        sessionStorage.removeItem("comesFromCreateChat");
        var chatParams = JSON.parse(sessionStorage.getItem("chatParams"));
        var innportingChatParams = JSON.parse(sessionStorage.getItem("innportingChatParams"));
        this.createChatDialogComponent.createDialog(chatParams, innportingChatParams);
        sessionStorage.removeItem("chatParams");
        sessionStorage.removeItem("innportingChatParams");
      }
    } catch (e) {
      // console.log(e);
    }
  }

  // Logout
  logout(userId) {
    // console.log('Logout: ', userId);
    this.qbHelper.qbLogout();
  }

  // Chats List
  getChatList(type, comesFromOneShipment, dialogId) {

    var filter = {};

    // TO filter from one chat
    if(comesFromOneShipment) {
      filter = {
        limit: 1,
        sort_desc: 'updated_at',
        _id: dialogId
      };
    } else {
      filter = {
        limit: 200,
        sort_desc: 'updated_at',
      };
    }

    // All chats
    /*filter = {
      limit: 200,
      sort_desc: 'updated_at',
    };*/

    this.dashboardService.showComponent({
      'chatsClicked': type === 'chat',
      'publicChatClicked': type !== 'chat'
    });

    if (type === 'chat') {
      filter['type[in]'] = [3, 2].join(',');
    } else {
      filter['type'] = 1;
    }

    LoaderComponent.show();
    this.dialogService.getDialogs(filter)
      .then((res) => {
        if (res) {
          res['items'].forEach((chat, index, self ) => {
            if ( chat.xmpp_room_jid ) {
              this.dialogService.joinToDialog(chat);
            }
            self[index].last_message_date_sent = +chat.last_message_date_sent * 1000;
          });
          this.dialogService.setDialogs(res['items']);
        }
        LoaderComponent.hide();
      })
      .catch((err) => {
        LoaderComponent.hide();
        // console.log('Get chats error: ' + err);
      });
  }

  // Create New Group
  createNewGroup() {
    this.dashboardService.showComponent({
      'createGroupClicked': true,
      'updateDialog': false,
      'welcomeChat': false,
      'onChatClick': false
    });
  }

  // Open Chat
  openChat(chat) {
    document.querySelector('.j-chat-sidebar').classList.remove('active');
    this.selectedChat = chat._id;
    this.dialogService.currentDialog = chat;
    this.dialogService.currentDialogEvent.emit(chat);
    this.dashboardService.showComponent({
      'createGroupClicked': false,
      'updateDialog': false,
      'welcomeChat': false,
      'onChatClick': true
    });
  }

}
