import {CanDeactivate} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {ChangeSafetyComponent} from './ChangeSafetyComponent';

@Injectable()
export class ChangeSafetyGuard implements CanDeactivate<ChangeSafetyComponent> {

  constructor() {
  }

  canDeactivate(component: ChangeSafetyComponent): Observable<boolean> {
    return component.canDeactivate();
  }
}
