import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../shared/features/auth/services/authentication.service';
import {UserService} from '../../../shared/features/auth/services/user.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../shared/components/inputs/utils/FormUtils';
import {DataValidator} from '../../../shared/components/inputs/utils/DataValidator';
import {LocalStorageHelper} from '../../../../utils/LocalStorageHelper';
import {AlertNotificationsComponent} from '../../../shared/components/alert-notifications/alert-notifications.component';
import {MixPanelHelper} from '../../../../utils/mixPanel/MixPanelHelper';
import {LanguageHelper} from '../../../../utils/LanguageHelper';
import {take} from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-login-cmp',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  private toggleButton: any;
  private sidebarVisible: boolean;
  private nativeElement: Node;
  public loginAndMailFormGroup: FormGroup;

  constructor(private element: ElementRef, private authenticationService: AuthenticationService,
              private userService: UserService, private router: Router, private loginAndMailFormBuilder: FormBuilder,
              private alertNotificationComponent: AlertNotificationsComponent,
              private mixpanelHelper: MixPanelHelper, private languageHelper: LanguageHelper) {
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.userService.logout(false);
    this.initNavbar();
    this.initLoginAndMailFormBuilder();
    this.showValidationTokenIfNeed();

  }

  private showValidationTokenIfNeed() {
    if (!DataValidator.isEmpty(LocalStorageHelper.getItem(LocalStorageHelper.ATOKEN_KEY))) {
      this.languageHelper.waitForLanguageToBeLoaded().pipe(take(1)).subscribe(() => {
        this.alertNotificationComponent.showNeedAccessForValidateAccount();
      });
    }
  }

  private initNavbar() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    body.classList.add('off-canvas-sidebar');
    const card = document.getElementsByClassName('card')[0];
    setTimeout(function () {
      card.classList.remove('card-hidden');
    }, 700);
  }

  private initLoginAndMailFormBuilder() {
    this.loginAndMailFormGroup = this.loginAndMailFormBuilder.group({
      mail: [null, Validators.required],
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])]
    });
  }

  login() {
    if (this.loginAndMailFormGroup.valid) {
      this.userService.loginGetInitInfoAndEnter(this.loginAndMailFormGroup.get('mail').value,
        this.loginAndMailFormGroup.get('password').value, value => {
          this.sendSubmitEventMixPanel(value.mail, value.id);
        }, true);
    } else {
      FormUtils.markIncorrectFields(this.loginAndMailFormGroup);
    }
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    body.classList.remove('off-canvas-sidebar');
  }

  onInputsKeyDown(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  recoverPass() {
    this.router.navigate(['/recover-pass']);
  }

  private sendSubmitEventMixPanel(mail: string, id: string) {
    try {
      const props = {
        userMail: mail,
        user_db_id: id
      };
      this.mixpanelHelper.sendEvent('Login', props);
    } catch (e) {
      // Do nothing
    }
  }
}
