import {Component, Injectable, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ValidableInputComponent} from '../validable-input/validable-input.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-number-input',
  templateUrl: '../validable-input/validable-input.component.html',
  styleUrls: ['../validable-input/validable-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable(
  {providedIn: 'root'}
)
export class NumberInputComponent extends ValidableInputComponent implements OnInit, OnDestroy {

  constructor(public translate: TranslateService) {
    super();
    this.type = "number";
  }

  ngOnInit() {
    super.ngOnInit();
  }

  validation(value) {
    this.isValid = !!value && (parseInt(value) >= this.min && parseInt(value) <= this.max);
  }

  getErrorText(): string {
    if(this.getValue() === "" || this.getValue() === undefined || this.getValue() === null) {
      return 'INPUTS.ERRORS.REQUIRED';
    } else if(parseInt(this.getValue()) === 0) {
      return 'INPUTS.ERRORS.GREATER_THAN_0';
    } else if(parseInt(this.getValue()) < this.min || parseInt(this.getValue()) > this.max) {
      return this.translate.instant('INPUTS.ERRORS.MIN_MAX', {min: this.min, max: this.max});
    }
  }

  ngOnDestroy() {
  }

}
