import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {SidebarHelper} from './helpers/SidebarHelper';
import {Globals} from '../../models/Globals';
import {NavigationEnd, Router} from '@angular/router';
import {UserService} from '../../features/auth/services/user.service';
import {NavigationService} from '../../../../utils/NavigationService/navigation.service';

declare const $: any;
// TODO: Reactivar chat en un futuro
// declare var QB: any;

// Metadata
export interface RouteInfo {
  id: string;
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, AfterViewInit {

  private static readonly LANG_VISIBLE_ON_SIDEBAR_LIMIT = 992;

  @ViewChild('myAccountItem')
  private myAccountItem: ElementRef;

  @ViewChild('profileCollapseItem')
  private profileCollapseItem: ElementRef;

  // Menu Items
  public menuItems: any[];
  private sidebarHelper: SidebarHelper;

  public isMobileMenu = false;

  public avatarImage: String;

  constructor(sidebarHelper: SidebarHelper,
              private router: Router,
              private userService: UserService,
              public globals: Globals,
              private navigationService: NavigationService) {
    this.sidebarHelper = sidebarHelper;
    this.avatarImage = this.globals.getEnvironment().baseApiUrl + 'files/' + globals.avatar;
  }

  ngOnInit() {
    this.menuItems = this.sidebarHelper.getRoutes(this.globals.customerType).filter(menuItem => menuItem);
    this.setRouterAndSidebarGestion();
    if (this.isAccountLink()) {
      this.setMyAccountActive();
    }
    this.isMobileMenu = window.innerWidth < SidebarComponent.LANG_VISIBLE_ON_SIDEBAR_LIMIT;
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      const ps = new PerfectScrollbar(elemSidebar, {wheelSpeed: 2, suppressScrollX: true});
    }
  }

  isMac(): boolean {
    let isMac = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0
      || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      isMac = true;
    }
    return isMac;
  }

  getMyCompanyRoute(): string {
    return this.sidebarHelper.getMyAccountRoute(this.globals.customerType);
  }

  setMyAccountActive() {
    this.myAccountItem.nativeElement.classList.add('active');
    this.profileCollapseItem.nativeElement.classList.add('my-profile-active');
    $('#profileCollapse').collapse('show');
  }

  setMyShipmentsActive() {
    try {
      document.getElementById("my-shipments-tab").classList.add('active');
    } catch (e) {
      // Do nothing
    }
  }

  setMyAccountInactive() {
    this.myAccountItem.nativeElement.classList.remove('active');
    this.profileCollapseItem.nativeElement.classList.remove('my-profile-active');
    $('#profileCollapse').collapse('hide');
  }

  setMyShipmentsInActive() {
    try {
      document.getElementById("my-shipments-tab").classList.remove('active');
    } catch (e) {
      // Do nothing
    }
  }

  setRouterAndSidebarGestion() {
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      if (!this.isAccountLink()) {
        this.setMyAccountInactive();
      }
      if (!this.isMyShipmentsLink()) {
        this.setMyShipmentsInActive();
      } else {
        setTimeout(this.setMyShipmentsActive, 50);
      }
    });
  }

  isAccountLink() {
    return this.router.url.includes('/my-company-account') || this.router.url.includes('/my-forwarder-account');
  }

  isMyShipmentsLink() {
    const bool = this.router.url.includes('/company-shipment-detail')
      || this.router.url.includes('/forwarder-shipment-detail')
      || this.router.url.includes('/forwarder-shipments')
      || this.router.url.includes('/company-shipments');
    return bool;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileMenu = event.target.innerWidth < SidebarComponent.LANG_VISIBLE_ON_SIDEBAR_LIMIT;
  }

  signOut() {
    // TODO: Reactivar chat en un futuro
    /*this.logoutChatQB();
    this.userService.logout();*/
    this.navigationService.reset();
    this.router.navigate(['/login']);
  }

  lock() {
    // TODO: Reactivar chat en un futuro
    // this.logoutChatQB();
    this.userService.lock();
  }

  ngAfterViewInit(): void {
    if (this.isMyShipmentsLink()) {
      this.setMyShipmentsActive();
    }
  }

  logoutChatQB() {
    // TODO: Reactivar chat en un futuro
    /*
    try {
      QB.chat.disconnect();
    } catch (e) {

    }*/
  }
}
