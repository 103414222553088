import {LanguageSwitchItemComponent} from './language-switch-item.component';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
  imports: [BrowserModule],
  exports: [LanguageSwitchItemComponent],
  declarations: [LanguageSwitchItemComponent],
  providers: [],
})

export class LanguageSwitchItemModule {
}
