import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientIpAddressService {
  public static CLIENT_IP_ADDRESS_ENDPOINT = environment.baseApiUrl + 'get-client-ip-address';

  constructor(private httpauth: HttpClient) { }

  getClientIpAddress() {
    return this.httpauth.get(ClientIpAddressService.CLIENT_IP_ADDRESS_ENDPOINT);
  }
}
