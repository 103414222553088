import {Phone} from '../../../../../../../shared/models/Phone';

export class RegisterRequest {
  name: string;
  surname: string;
  mail: string;
  enterprise: string;
  phone: Phone;
  nif: string;
  password: string;
  enterpriseType: string;
  lang: string;
  invitation: string;
}
