import {Hotkey, HotkeysService} from 'angular2-hotkeys';

export abstract class ShortcutPage {

  abstract onLockShortcut();

  constructor(protected _hotkeysService: HotkeysService) {
    this._hotkeysService.add(new Hotkey('shift+l', (event: KeyboardEvent): boolean => {
      this.onLockShortcut();
      return false;
    }));
  }
}
