import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Globals} from '../models/Globals';
import {AlertNotificationsComponent} from '../components/alert-notifications/alert-notifications.component';
import {RouteHelper} from '../../../utils/RouteHelper';
import {UserService} from '../features/auth/services/user.service';
import {LanguageHelper} from '../../../utils/LanguageHelper';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CanActivateAccountActivatedGuard implements CanActivate {

  constructor(private globals: Globals, private router: Router,
              private alertNotificationComponent: AlertNotificationsComponent,
              private routeHelper: RouteHelper, private userService: UserService,
              private languageHelper: LanguageHelper) {
  }

  canActivate() {
    if (!this.globals.isNeverLoaded()) {
      if (!this.globals.isAccountActivated()) {
        this.languageHelper.waitForLanguageToBeLoaded().pipe(take(1)).subscribe(() => {
          if (this.globals.isForwarder()) {
            this.alertNotificationComponent.showNeedVerifyAccountMessage();
          } else {
            this.alertNotificationComponent.showNoActivatedAccountMessage();
          }
        });
        this.userService.setProfileRouteByCompanyType();
        return false;
      }
    } else {
      this.userService.getInitInfoAndCheckAccountActivated();
      return false;
    }
    return true;
  }
}
