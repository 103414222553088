import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CompanyOnBoardingVideoComponent} from './components/on-boarding-video/company-on-boarding-video.component';
import {Globals} from '../../../models/Globals';
import {LocalStorageHelper} from '../../../../../utils/LocalStorageHelper';

@Component({
  selector: 'app-company-on-boarding',
  templateUrl: './company-on-boarding.component.html',
  styleUrls: ['./company-on-boarding.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyOnBoardingComponent implements OnInit {
  static readonly LOCAL_STORAGE_ONBOARDING_DONE_KEY = 'on-boarding-done';

  @ViewChild(CompanyOnBoardingVideoComponent) videoComponent: CompanyOnBoardingVideoComponent;

  public opened = false;

  constructor(private globals: Globals) {
  }

  ngOnInit() {
    if (this.globals.isAccountActivated()
      && !LocalStorageHelper.existsItem(CompanyOnBoardingComponent.LOCAL_STORAGE_ONBOARDING_DONE_KEY)) {
      setTimeout(() => {
        // this.open();
      }, 1000);
    }
  }

  open() {
    document.body.classList.add('overflow-hidden');
    document.getElementById('on-boarding-wrapper').style.display = 'flex';
    this.opened = true;
  }

  close() {
    document.body.classList.remove('overflow-hidden');
    this.opened = false;
    this.videoComponent.pauseVideo();
    LocalStorageHelper.setItem(CompanyOnBoardingComponent.LOCAL_STORAGE_ONBOARDING_DONE_KEY, 'true');
    setTimeout(() => {
      document.getElementById('on-boarding-wrapper').style.display = 'none';
      this.videoComponent.pauseVideo();
    }, 2000);
  }
}
