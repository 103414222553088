import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-complete-pages-navbar',
  templateUrl: './complete-pages-navbar.component.html',
  styleUrls: ['./complete-pages-navbar.component.scss', './complete-pages-navbar.mediaquery.component.scss']
})
export class CompletePagesNavbarComponent implements OnInit, AfterViewInit {
  private readonly NAVBAR_CLOSED_WIDTH_ISSUE = 992;
  private readonly NAVBAR_CLOSE_INDICATOR_WIDTH = 991;

  private toggleButton: any;
  private sidebarVisible: boolean;
  mobile_menu_visible: any = 0;
  private _router: Subscription;
  @ViewChild('navbarContainer') navBarContainer: ElementRef;

  constructor(private router: Router, private element: ElementRef, @Inject(DOCUMENT) public document: any) {
    this.sidebarVisible = false;
  }

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.sidebarClose();
    });
  }

  sidebarOpen() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('overflow-hidden');
    const navBar: Element = this.element.nativeElement.getElementsByClassName('collapse navbar-collapse')[0];
    navBar.removeAttribute('style');
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    toggleButton.classList.add('toggled');
    body.classList.add('nav-open');
    const $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');
    if (body.querySelectorAll('.wrapper-full-page')) {
      document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
    } else if (body.classList.contains('off-canvas-sidebar')) {
      document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
    }
    setTimeout(function () {
      $layer.classList.add('visible');
    }, 100);
    $layer.onclick = () => this.sidebarClose();

    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('overflow-hidden');
    const body = document.getElementsByTagName('body')[0];
    const $layer = document.getElementsByClassName('close-layer visible')[0];

    if ($layer) {
      $layer.remove();
    }

    body.classList.remove('nav-open');
    this.mobile_menu_visible = 0;

    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();

    } else {
      this.sidebarClose();
    }
  }

  public getNavbarTitle(): string {

    if (this.router.url.includes("/login")) {
      return 'LOGIN.LOGIN';
    }
    else if(this.router.url.includes("/register")) {
      return 'REGISTER.REGISTER';
    }
    else if(this.router.url.includes("/lock")) {
      return 'MENU_COMPANY.LOCK';
    } else {
      return '';
    }
  }

  ngAfterViewInit(): void {
    if (this.router.url === '/lock') {
      this.navBarContainer.nativeElement.remove();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.router.url !== '/lock') {
      const navBarIssueWidth = this.NAVBAR_CLOSED_WIDTH_ISSUE;
      const navBar: Element = this.element.nativeElement.getElementsByClassName('navbar-container')[0];
      navBar.setAttribute('style',
        event.target.innerWidth < navBarIssueWidth ? 'display:block;' : 'display:none;');
      if (event.target.innerWidth > this.NAVBAR_CLOSE_INDICATOR_WIDTH
        && this.sidebarVisible) {
        this.sidebarClose();
      }
    }
  }
}
