import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CookiesService} from './services/cookies.service';
import {Router} from '@angular/router';
import {InnComponent} from '../inn-component/InnComponent';
import {TranslateService} from '@ngx-translate/core';
import {LanguageHelper} from '../../../../utils/LanguageHelper';

declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss', './cookie-consent-mediaquery.component.scss']
})
export class CookieConsentComponent extends InnComponent implements OnInit {
  public static readonly COOKIE_NAME = 'cookies-accepted';
  public isAbleToShow: boolean;

  @ViewChild('cookieConsentBanner', {read: ElementRef}) banner: ElementRef;

  constructor(private cookieService: CookiesService, private router: Router, private changeDetectorRef: ChangeDetectorRef,
              private languageHelper: LanguageHelper) {
    super(changeDetectorRef);
    this.isAbleToShow = false;
    this.languageHelper.waitForLanguageToBeLoaded().subscribe(() => {
      // tslint:disable-next-line:max-line-length
      this.isAbleToShow = !this.cookieService.checkCookieExistence(CookieConsentComponent.COOKIE_NAME) && !this.cookieService.checkCookieExistence('cookies-rejected');
    });
  }

  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    if (!this.cookieService.checkCookieExistence(CookieConsentComponent.COOKIE_NAME) && !this.cookieService.checkCookieExistence('cookies-rejected')) {
      this.setInitialConsentMode();
    }
  }

  setInitialConsentMode() {
    try {
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied',
        'wait_for_update': 500
      });
    } catch (e) {
      console.log(e);
      // Do nothing
    }
  }

  allConsentGranted() {
    try {
      gtag('consent', 'update', {
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
    } catch (e) {
      console.log(e);
      // Do nothing
    }
  }

  acceptCookies() {
    this.banner.nativeElement.classList.add('fadeOut');
    setTimeout(() => {
      this.cookieService.setCookie(CookieConsentComponent.COOKIE_NAME, true);
      this.isAbleToShow = false;
      this.allConsentGranted();
    }, 800);
  }

  rejectCookies() {
    this.banner.nativeElement.classList.add('fadeOut');
    setTimeout(() => {
      this.cookieService.setCookie('cookies-rejected', true);
      this.isAbleToShow = false;
    }, 800);
  }
}
