import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FieldErrorDisplayModule} from '../../field-error-display/field-error.module';

import {MyMaterialsModule} from '../../../modules/my-materials.module';
import {CheckInputComponent} from './check-input.component';

@NgModule({
  imports: [
    MyMaterialsModule,
    TranslateModule,
    FieldErrorDisplayModule,
    TranslateModule
  ],
  exports: [CheckInputComponent],
  declarations: [CheckInputComponent]
})

export class CheckInputModule {
}
