import {TypeMapper} from '../../../../../../../shared/models/TypeMapper';
import {RegisterResponse} from '../../models/response/RegisterResponse';

export class RegisterResponseMapper extends TypeMapper<JSON, RegisterResponse> {
  map(): RegisterResponse {
    const registerResponse = new RegisterResponse();
    registerResponse.id = this.input['id'];
    return registerResponse;
  }
}
