import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ValidableInputComponent} from '../validable-input/validable-input.component';
import {DataValidator} from '../utils/DataValidator';

@Component({
  selector: 'app-required-input',
  templateUrl: '../validable-input/validable-input.component.html',
  styleUrls: ['../validable-input/validable-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequiredInputComponent extends ValidableInputComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  validation(value) {
    this.isValid = !!value;
  }

  getErrorText(): string {
    if(DataValidator.isNullOrUndefined(this.errorText)) {
      return 'INPUTS.ERRORS.REQUIRED';
    } else {
      return this.errorText;
    }
  }
}
