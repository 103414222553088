import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../shared/features/auth/services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RecoverPasswordService} from './services/recover-password.service';
import {FormUtils} from '../../../shared/components/inputs/utils/FormUtils';
import {MailWrapper} from './services/models/MailWrapper';
import {AlertNotificationsComponent} from '../../../shared/components/alert-notifications/alert-notifications.component';
import {DataValidator} from '../../../shared/components/inputs/utils/DataValidator';
import {Router} from '@angular/router';
import {LoaderComponent} from '../../../shared/components/loader/loader/loader.component';

@Component({
  selector: 'app-recover-pass',
  templateUrl: './recover-pass.component.html',
  styleUrls: ['./recover-pass.component.scss']
})
export class RecoverPassComponent implements OnInit, OnDestroy {

  public recoverPassFormGroup: FormGroup;
  private toggleButton: any;

  constructor(private userService: UserService, private recoverPassFormBuilder: FormBuilder,
              private element: ElementRef, private recoverPasswordService:RecoverPasswordService,
              private alertNotificationsComponent: AlertNotificationsComponent,
              private router: Router) { }

  ngOnInit() {
    this.userService.logoutWithoutNavigation();
    this.initNavbar();
    this.initRecoverPassFormBuilder();
  }

  private initRecoverPassFormBuilder() {
    this.recoverPassFormGroup = this.recoverPassFormBuilder.group({
      mail: [null, Validators.required]
    });
  }

  private initNavbar() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    body.classList.add('off-canvas-sidebar');
    const card = document.getElementsByClassName('card')[0];
    setTimeout(function () {
      card.classList.remove('card-hidden');
    }, 700);
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    body.classList.remove('off-canvas-sidebar');
  }

  onInputsKeyDown(event) {
    if (event.key === 'Enter') {
      this.recoverPass();
    }
  }

  recoverPass() {
    if (this.recoverPassFormGroup.valid) {
      var mailWrapper = new MailWrapper();
      mailWrapper.mail = this.recoverPassFormGroup.get('mail').value;
      LoaderComponent.show();
      this.recoverPasswordService.recoverPasswd(mailWrapper).subscribe(next => {
        LoaderComponent.hide();
        var self = this;
        self.alertNotificationsComponent.showChangePasswordSuccess();
        setTimeout(function () {
          self.router.navigate(['/login']);
        }, 1000);
      }, error => {
        LoaderComponent.hide();
        if(!DataValidator.isNullOrUndefined(error) &&
          !DataValidator.isNullOrUndefined(error.error) &&
          error.error.message == "UserNotFound") {
          this.alertNotificationsComponent.showUserOrPasswordError();
        } else {
          this.alertNotificationsComponent.showGenericDataError();
        }
      }, () => {
        LoaderComponent.hide();
      });
    } else {
      FormUtils.markIncorrectFields(this.recoverPassFormGroup);
    }
  }
}
