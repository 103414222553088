import {Injectable} from '@angular/core';
import {CustomerType} from './CustomerType';
import {environment} from '../../../../environments/environment';
import {ChatUser} from '../components/chat/innporting-chat-services/models/ChatUser';
import {CompanyBillingOptions} from './CompanyBillingOptions';

@Injectable(
  {providedIn: 'root'}
)
export class Globals {
  private _customerType: CustomerType;
  private _activated: number;
  private _role: string;
  private _lang: string;
  private _mail: string;
  private _name: String;
  private _avatar: String;
  private _userId: number;
  private _mixPanelId: String;
  private _surname: String;
  private _tfn: String;
  private _createdAt: Date;
  private _enterpriseLogo: String;
  private _enterpriseDir: String;
  private _enterpriseCountry: String;
  private chatUser: ChatUser;
  private companyName: string;
  private companyCifNif: string;
  private isChatBackgroundWorkingAttr: boolean;
  private _companyBillingOptions: CompanyBillingOptions;
  public gasPrices;
  public showIO;

  get activated(): number {
    return this._activated;
  }

  set activated(value: number) {
    this._activated = value;
  }

  get role(): string {
    return this._role;
  }

  set role(value: string) {
    this._role = value;
  }

  get lang(): string {
    return this._lang;
  }

  set lang(value: string) {
    this._lang = value;
  }

  get mail(): string {
    return this._mail;
  }

  set mail(value: string) {
    this._mail = value;
  }

  get name(): String {
    return this._name;
  }

  set name(value: String) {
    this._name = value;
  }

  isNeverLoaded(): boolean {
    return this.mail === undefined;
  }

  get customerType(): CustomerType {
    return this._customerType;
  }

  set customerType(value: CustomerType) {
    this._customerType = value;
  }

  get avatar(): String {
    return this._avatar;
  }

  set avatar(value: String) {
    this._avatar = value;
  }

  get userId(): number {
    return this._userId;
  }

  set userId(value: number) {
    this._userId = value;
  }

  get mixPanelId(): String {
    return this._mixPanelId;
  }

  set mixPanelId(value: String) {
    this._mixPanelId = value;
  }

  get surname(): String {
    return this._surname;
  }

  set surname(value: String) {
    this._surname = value;
  }

  get tfn(): String {
    return this._tfn;
  }

  set tfn(value: String) {
    this._tfn = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  isCompany() {
    return this.customerType === CustomerType.COMPANY;
  }

  isForwarder() {
    return this.customerType === CustomerType.FORWARDER;
  }

  isAccountActivated() {
    return this.activated === 1;
  }

  getEnvironment() {
    return environment;
  }

  get enterpriseLogo(): String {
    return this._enterpriseLogo;
  }

  set enterpriseLogo(value: String) {
    this._enterpriseLogo = value;
  }

  get enterpriseDir(): String {
    return this._enterpriseDir;
  }

  set enterpriseDir(value: String) {
    this._enterpriseDir = value;
  }

  get enterpriseCountry(): String {
    return this._enterpriseCountry;
  }

  set enterpriseCountry(value: String) {
    this._enterpriseCountry = value;
  }

  get companyBillingOptions(): CompanyBillingOptions {
    return this._companyBillingOptions;
  }

  set companyBillingOptions(value: CompanyBillingOptions) {
    this._companyBillingOptions = value;
  }

  getChatUser(): ChatUser {
    return this.chatUser;
  }

  setChatUser(chatUser: ChatUser) {
    this.chatUser = chatUser;
  }

  getCompanyName(): string {
    return this.companyName;
  }

  setCompanyName(companyName: string) {
    this.companyName = companyName;
  }

  setCompanyCifNif(companyCifNif: string) {
    this.companyCifNif = companyCifNif;
  }

  getCompanyCifNif(): string {
    return this.companyCifNif;
  }

  setIsChatBackgroundWorking(working: boolean) {
    this.isChatBackgroundWorkingAttr = working;
  }

  isChatBackgroundWorking(): boolean {
    return this.isChatBackgroundWorkingAttr;
  }
}

