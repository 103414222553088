import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CountryISO, NgxIntlTelInputComponent, SearchCountryField, TooltipLabel} from 'ngx-intl-tel-input-angular7';
import {ValidableInputComponent} from '../validable-input/validable-input.component';

@Component({
  selector: 'app-tel-input',
  templateUrl: './tel-input.component.html',
  styleUrls: ['../validable-input/validable-input.component.scss', './tel-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TelInputComponent extends ValidableInputComponent implements OnInit {

  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.Spain];
  public selectedCountryISOVar = 'es';

  @ViewChild(NgxIntlTelInputComponent) private telComponent: NgxIntlTelInputComponent;

  @Input()
  formGroup: FormGroup;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getErrorText(): string {
    return 'INPUTS.ERRORS.TFN';
  }

  validation(value) {
    // No implementation
  }

  setCustomCountry(countryCode: string) {
    this.selectedCountryISOVar = countryCode;
    this.telComponent.getSelectedCountry();
  }

  updateCountry() {
    this.telComponent.getSelectedCountry();
  }

}
