import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HubSpotService {
  private static HUBSPOT_IDENTITY_TOKEN = environment.baseApiUrl + 'hubspot-identity-token';

  constructor(private http: HttpClient) {
  }

  getIdentifyToken() {
    return this.http.get(HubSpotService.HUBSPOT_IDENTITY_TOKEN);
  }

}
