import {NgModule} from '@angular/core';
import {FieldErrorDisplayComponent} from './field-error-display.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [FieldErrorDisplayComponent],
  declarations: [FieldErrorDisplayComponent]
})

export class FieldErrorDisplayModule {
}
