import {NgModule} from '@angular/core';
import {LoaderComponent} from './loader.component';

@NgModule({
  imports: [
  ],
  declarations: [LoaderComponent],
  exports: [
    LoaderComponent
  ],
})

export class LoaderModule {
}
