import {Component, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../../../shared/models/Globals';
import {CustomerType} from '../../../shared/models/CustomerType';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit, OnDestroy {

  constructor(private globals: Globals) {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('not-found-page');
    body.classList.add('off-canvas-sidebar');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('not-found-page');
    body.classList.remove('off-canvas-sidebar');
  }

  getOverviewRoute(): String {
    return this.globals.customerType === CustomerType.COMPANY ?
      '/company-overview' : '/forwarder-overview';
  }
}
