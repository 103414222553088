import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit {
  private static readonly MIN_TIME_TO_SHOW_LOADER = 500;

  private static hidden = false;
  public static actualScrollY: number;

  static show(callback = null) {
    try {
      this.hidden = false;
      this.actualScrollY = window.scrollY;
      setTimeout(() => {
        if (!this.hidden) {
          document.getElementById('innportingLoader').style.display = 'flex';
          document.body.classList.add('position-fixed');
          document.body.style.top = `-${this.actualScrollY}px`;
        }
        if (callback) {
          callback();
        }
      }, this.MIN_TIME_TO_SHOW_LOADER);
    } catch (e) {
      // Do nothing
    }
  }

  static hide() {
    try {
      this.hidden = true;
      document.body.classList.remove('position-fixed');
      document.body.style.removeProperty('top');
      window.scrollTo(0, this.actualScrollY);
      document.getElementById('innportingLoader').style.display = 'none';
    } catch (e) {
      // Do nothing
    }
  }

  constructor() {
    // TODO: Da problemas, con requests largas, se quita antes, buscar otra solución
    // setTimeout(LoaderComponent.hide, 10000);
    document.addEventListener('readystatechange', function () {
      if (document.readyState === 'interactive') {
        LoaderComponent.hide();
      }
    });
  }

  ngOnInit() {
  }

}
