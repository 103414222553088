import {TypeMapper} from '../../../../../../../shared/models/TypeMapper';
import {Phone} from '../../../../../../../shared/models/Phone';

export class PhoneMapper extends TypeMapper<JSON, Phone> {
  map(): Phone {
    const phone = new Phone();
    phone.number = this.input['number'];
    phone.internationalNumber = this.input['internationalNumber'];
    phone.nationalNumber = this.input['nationalNumber'];
    phone.countryCode = this.input['countryCode'];
    phone.dialCode = this.input['dialCode'];
    return phone;
  }
}
