import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  private static readonly DOMAIN = 'innporting.com';

  constructor() {
  }

  checkCookieExistence(cookieName: String) {
    return document.cookie.includes(cookieName + '=');
  }

  getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  setCookie(cookieName: String, crossDomain?: boolean, cookieValue?: any, cookiePath?: String, cookieExpirationDays?: number) {
    const value = cookieValue || '1';
    const expirationDays = cookieExpirationDays || 365;
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 86400000);
    const expiration = date.toString();
    const path = cookiePath || '/';
    const domain = this.getDomain(crossDomain);
    document.cookie = `${cookieName}=${value};expires=${expiration}; path=${path};domain=${domain}`;
  }

  private getDomain(crossDomain = false) {
    if (environment.production) {
      return crossDomain ? '.' + CookiesService.DOMAIN : CookiesService.DOMAIN;
    } else {
      return 'localhost';
    }
  }
}
