import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, Scroll, Event} from '@angular/router';
import {Globals} from './components/shared/models/Globals';
import {Location, ViewportScroller} from '@angular/common';
import {LanguageHelper} from './utils/LanguageHelper';
import {UserService} from './components/shared/features/auth/services/user.service';
import {AlertNotificationsComponent} from './components/shared/components/alert-notifications/alert-notifications.component';
import {RouteHelper} from './utils/RouteHelper';
import {environment} from '../environments/environment';
import {GoogleAnalyticsHelper} from './utils/GoogleAnalyticsHelper';
import {MixPanelHelper} from './utils/mixPanel/MixPanelHelper';
import {HubSpotHelper} from './utils/HubSpot/HubSpotHelper';
import {DataValidator} from './components/shared/components/inputs/utils/DataValidator';
import {Title} from '@angular/platform-browser';
import {NavigationService} from './utils/NavigationService/navigation.service';
import {filter, pairwise} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  private _router: Subscription;
  private lastActivatedRouteSnapshot: ActivatedRouteSnapshot;

  constructor(private router: Router, private globals: Globals, private location: Location,
              private languageHelper: LanguageHelper,
              private userService: UserService,
              private alertNotificationsComponent: AlertNotificationsComponent,
              private routeHelper: RouteHelper,
              private googleAnalyticsHelper: GoogleAnalyticsHelper,
              private mixpanelHelper: MixPanelHelper,
              private hubSpotHelper: HubSpotHelper,
              private titleService: Title,
              private navigationService: NavigationService,
              private viewportScroller: ViewportScroller,
              private http: HttpClient) {
    this.init();
    this.viewportScroller.setHistoryScrollRestoration('manual');
    this.handleNavigationScroll();
  }

  ngOnInit() {
    this.initRouter();
    this.getInitInfoIfNeeded();
    this.initGasPrices();
    this.initIO();
    // this.initHubSpotSession();
  }

  initRouter() {
    this._router = this.router.events.filter
    (event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.setTitle();
      this.init();
      this.trackRoute(event.urlAfterRedirects);
      this.uiGestion();
      this.getInitInfoIfNeeded();
      this.logoutIfNeed();
      this.logoutIfRoleHasChanged();
      this.loadIOIfNeed();
      this.loadPricesIfNeed();
    });
  }

  logoutIfRoleHasChanged() {
    if (this.userService.isLogged() && !this.globals.isNeverLoaded()) {
      // tslint:disable-next-line:triple-equals
      if (this.globals.role == 'Admin' && !this.userService.isAdminUser()) {
        this.userService.logoutWithoutNavigation();
        this.alertNotificationsComponent.showChangeRoleAlert();
        window.location.reload();
      }
      // tslint:disable-next-line:triple-equals
      if (this.globals.role == 'User' && !this.userService.isUser()) {
        this.userService.logoutWithoutNavigation();
        this.alertNotificationsComponent.showChangeRoleAlert();
        window.location.reload();
      }
    }
  }

  uiGestion() {
    const body = document.getElementsByTagName('body')[0];
    const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
    if (body.classList.contains('modal-open')) {
      body.classList.remove('modal-open');
      modalBackdrop.remove();
    }
  }

  logoutIfNeed() {
    if (!this.userService.isLogged()) {
      if (!this.routeHelper.areAuthPages() && !this.routeHelper.areTrackPage()) {
        this.userService.logout();
        setTimeout(() => {
          this.alertNotificationsComponent.showNotLoggedMessage();
        }, 300);
      }
    }
  }

  init() {
    if (this.routeHelper.areExternalPagesOrEmpty()) {
      this.languageHelper.initLanguage();
    }
    this.navigationService.init();
  }

  getInitInfoIfNeeded() {
    if (this.userService.isLogged()) {
      if (!this.routeHelper.areAuthPages() && this.globals.isNeverLoaded()) {
        this.userService.getInitInfoAppModule();
      }
    }
  }

  isChatUrl() {
    return window.location.href.includes('chat');
  }

  private initHubSpotSession() {
    if (this.userService.isLogged()) {
      this.hubSpotHelper.identifyHubSpotUser();
    }
  }

  private trackRoute(url) {
    // TODO: Delete true condition for only-production tracking
    if (true || environment.production) {
      this.googleAnalyticsHelper.pageLoaded(url);
      this.mixpanelHelper.sendPageLoadedEvent(url);
    }
  }

  private setTitle() {
    let route: ActivatedRoute = this.router.routerState.root;
    if (!DataValidator.isNullOrUndefined(route)) {
      while (route.firstChild) {
        route = route.firstChild;
      }
      if (route.snapshot.data['title']) {
        this.titleService.setTitle(`${route.snapshot.data['title']} - InnPorting Dashboard`);
      } else {
        this.titleService.setTitle('InnPorting Dashboard');
      }
    }
  }

  private handleNavigationScroll() {
    this.router.events.pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll),
      pairwise()
    ).subscribe((e: Scroll[]) => {
      try {
        const previous = e[0], current = e[1];
        if (current.position) {
          // Backward navigation
          this.viewportScroller.scrollToPosition(current.position);
        } else if (current.anchor) {
          // Anchor navigation
          this.viewportScroller.scrollToAnchor(current.anchor);
        } else {
          // Check if routes match, or if it is only a child router change or a query param change
          if (this.getBaseRoute(previous.routerEvent.urlAfterRedirects) !== this.getBaseRoute(current.routerEvent.urlAfterRedirects)) {
            // Forward navigation
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      } catch (e) {
        // Do nothing
      }
    });
  }

  private getBaseRoute(url: string): string {
    try {
      const urlElems = url.split('/');
      if (urlElems.length > 2) {
        // return url without last url part to check child routes change
        return url.replace(urlElems[urlElems.length - 1], '');
      } else {
        return url.split('?')[0];
      }
    } catch (e) {
      // Do nothing
    }
  }

  initGasPrices() {
    try {
      this.http.get(environment.baseApiUrl + 'ftl-prices').subscribe(
        value => {
          this.globals.gasPrices = value;
        }
      );
    } catch (e) {
      // Do nothing
    }
  }

  initIO() {
    try {
      this.http.get(environment.baseApiUrl + 'get-showio').subscribe(
        value => {
          this.globals.showIO = value === 1;
        }
      );
    } catch (e) {
      // Do nothing
    }
  }

  private loadIOIfNeed() {
    try {
      // tslint:disable-next-line:triple-equals
      if (this.globals.showIO == undefined) {
        this.initIO();
      }
    } catch (e) {

    }
  }

  private loadPricesIfNeed() {
    try {
      // tslint:disable-next-line:triple-equals
      if (this.globals.gasPrices == undefined) {
        this.initGasPrices();
      }
    } catch (e) {

    }
  }
}
