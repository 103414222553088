import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FieldErrorDisplayModule} from '../../field-error-display/field-error.module';
import {MyMaterialsModule} from '../../../modules/my-materials.module';
import {NumberInputComponent} from './number-input.component';

@NgModule({
  imports: [
    MyMaterialsModule,
    TranslateModule,
    FieldErrorDisplayModule,
    TranslateModule
  ],
  exports: [NumberInputComponent],
  declarations: [NumberInputComponent]
})

export class NumberInputModule {
}
