import {ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DataValidator} from '../utils/DataValidator';

export abstract class ValidableInputComponent implements OnInit {

  @Input()
  customId?: string;

  @Input()
  formGroup?: FormGroup;

  @Input()
  icon?: string;

  @Input()
  text?: string;

  @Input()
  type?: string;

  @Input()
  errorText?: string;

  @Input()
  currentValue?: string;

  @Input()
  min?: number;

  @Input()
  max?: number;

  @Input()
  maximumLength?: number;

  @Input()
  classes = '';

  @Output()
  error?: EventEmitter<string>;

  @ViewChild('internalInput')
  internalInput: ElementRef;

  public isValid = false;
  private errorSent: boolean;

  protected constructor() {
    this.error = new EventEmitter<string>();
    this.errorSent = false;
  }

  ngOnInit() {
    if(!DataValidator.isEmpty(this.currentValue)) {
      this.formGroup.get(this.customId).setValue(this.currentValue);
    }
  }

  abstract validation(value);

  abstract getErrorText(): string;

  protected displayErrors(field: string) {
    return {
      'has-error': this.isFieldNotValid(field),
      'has-feedback': this.isFieldNotValid(field)
    };
  }

  public isFieldNotValid(field: string) {
    if (!this.formGroup.get(field).valid && this.formGroup.get(field).touched) {
      this.emitError(field);
      return true;
    } else {
      this.cleanErrorSentFlag();
      return false;
    }
  }

  public getValue(): string {
    return this.formGroup.get(this.customId).value === null ||
    this.formGroup.get(this.customId).value === undefined ? '' :
      this.formGroup.get(this.customId).value;
  }

  public isFieldValid(field: string) {
    return this.formGroup.get(field).valid;
  }

  public valueIsEmpty(): boolean {
    return this.getValue() === null || this.getValue() === undefined || this.getValue() === '';
  }

  public componentIsValid(): boolean {
    return this.isValid;
  }

  private emitError(field: string) {
    if (!this.errorSent) {
      this.errorSent = true;
      this.error.emit(field);
    }
  }

  private cleanErrorSentFlag() {
    this.errorSent = false;
  }
}
