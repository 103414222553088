import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../shared/features/auth/services/authentication.service';
import {UserService} from '../../../shared/features/auth/services/user.service';
import {Router} from '@angular/router';
import {Globals} from '../../../shared/models/Globals';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormUtils} from '../../../shared/components/inputs/utils/FormUtils';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})

export class LockComponent implements OnInit, OnDestroy {

  public passwordFormGroup: FormGroup;

  constructor(private authenticationService: AuthenticationService,
              private userService: UserService, private router: Router,
              public globals: Globals, private passwordFormBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.initUI();
    this.initFormBuilder();
  }

  private initUI() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('lock-page');
    body.classList.add('off-canvas-sidebar');
    const card = document.getElementsByClassName('card')[0];
    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      card.classList.remove('card-hidden');
    }, 700);
  }

  private initFormBuilder() {
    this.passwordFormGroup = this.passwordFormBuilder.group({
      currentPassword: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
    });
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('lock-page');
    body.classList.remove('off-canvas-sidebar');
  }

  login() {
    if (this.passwordFormGroup.valid) {
      this.userService.loginFromLock(this.globals.mail, this.passwordFormGroup.get('currentPassword').value);
    } else {
      FormUtils.markIncorrectFields(this.passwordFormGroup);
    }
  }

  onInputsKeyDown(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }
}
