import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ValidableInputComponent} from '../validable-input/validable-input.component';

@Component({
  selector: 'app-check-input',
  templateUrl: './check-input.component.html',
  styleUrls: ['../validable-input/validable-input.component.scss', './check-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckInputComponent extends ValidableInputComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getErrorText(): string {
    return this.errorText;
  }

  validation(value) {
    // Do nothing
  }
}
