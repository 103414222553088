import {Injectable, Injector} from '@angular/core';

import {TOKEN_NAME} from './auth.constant';
import {Router} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {AlertNotificationsComponent} from '../../../components/alert-notifications/alert-notifications.component';
import {CustomerType} from '../../../models/CustomerType';
import {InitInfoService} from '../../get-init-info/services/init-info.service';
import {Globals} from '../../../models/Globals';
import {Location} from '@angular/common';
import {JwtHelperService} from '@auth0/angular-jwt';
import {RouteHelper} from '../../../../../utils/RouteHelper';
import {MixPanelHelper} from '../../../../../utils/mixPanel/MixPanelHelper';
import {environment} from '../../../../../../environments/environment';
import {LanguageHelper} from '../../../../../utils/LanguageHelper';
import {DataValidator} from '../../../components/inputs/utils/DataValidator';
import {LocalStorageHelper} from '../../../../../utils/LocalStorageHelper';
import {ChatUser} from '../../../components/chat/innporting-chat-services/models/ChatUser';
import {ChatUserService} from '../../../components/chat/chat-user/chat-user.service';
import {RandomUtils} from '../../../../../utils/RandomUtils';
import {InnportingChatServicesService} from '../../../components/chat/innporting-chat-services/innporting-chat-services.service';
// TODO: Reactivar chat en un futuro
// import {QBconfig} from '../../../components/chat/QBconfig';
import {GlobalService} from '../../../service/global.service';
import {LoaderComponent} from '../../../components/loader/loader/loader.component';
import {HubSpotHelper} from '../../../../../utils/HubSpot/HubSpotHelper';
import {NavigationService} from '../../../../../utils/NavigationService/navigation.service';


// TODO: Reactivar chat en un futuro
// declare var QB: any;


@Injectable()
export class UserService {

  public static readonly COMPANY_TYPE = 'Company';
  public static readonly BAD_REQUEST = 400;
  public static readonly UNAUTHORIZED_REQUEST = 401;
  public userIsAdmin: boolean;

  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private router: Router, private authenticationService: AuthenticationService,
              private alertNotificationsComponent: AlertNotificationsComponent,
              private initInfoService: InitInfoService,
              private globals: Globals, private location: Location,
              private routeHelper: RouteHelper, private mixpanelHelper: MixPanelHelper,
              private injector: Injector, private chatUserService: ChatUserService,
              private innportingChatServices: InnportingChatServicesService,
              private globalService: GlobalService,
              private hubSpotHelper: HubSpotHelper,
              private navigationService: NavigationService) {
  }

  loginAndReload(mail: string, pass: string) {
    LoaderComponent.show();
    this.authenticationService.login(mail.toLowerCase(), pass)
      .subscribe(
        result => {
          LoaderComponent.hide();
          if (result) {
            this.login(result);
            window.location.reload();
          } else {
            this.alertNotificationsComponent.showUserOrPasswordError();
          }
        },
        error => {
          LoaderComponent.hide();
          this.gestionLoginError(error);
        }
      );
  }

  loginGetInitInfoAndEnter(mail: string, pass: string, callback = null, navigateToPrevPage = false) {
    LoaderComponent.show();
    this.authenticationService.login(mail.toLowerCase(), pass)
      .subscribe(
        result => {
          LoaderComponent.hide();
          if (result) {
            this.login(result);
            this.getInitInfo(callback, navigateToPrevPage);
            // this.hubSpotHelper.identifyHubSpotUser();
          } else {
            this.alertNotificationsComponent.showUserOrPasswordError();
          }
        },
        error => {
          LoaderComponent.hide();
          this.gestionLoginError(error);
        }
      );
  }

  loginFromLock(mail: string, pass: string) {
    if (mail === undefined || mail === '' || mail === null) {
      this.alertNotificationsComponent.showGenericDataError();
    } else {
      this.authenticationService.login(mail.toLowerCase(), pass)
        .subscribe(
          result => {
            if (result) {
              this.login(result);
              this.setRouteByCompanyType();
            } else {
              this.alertNotificationsComponent.showUserOrPasswordError();
            }
          },
          error => {
            this.gestionLoginError(error);
          }
        );
    }
  }

  getInitInfo(callback = null, navigateToPrevPage = false) {
    this.initInfoService.getInitInfo().subscribe((value => {
        this.saveGlobals(value);
        if (callback) {
          callback(value);
        }
      }),
      error => {
        this.alertNotificationsComponent.showGenericDataError();
        this.logout();
      },
      () => {
        if (!DataValidator.isEmpty(LocalStorageHelper.getItem(LocalStorageHelper.ATOKEN_KEY))) {
          this.setValidationRouteByCompanyType();
        } else {
          this.setRouteByCompanyType();
        }
        /*else if (navigateToPrevPage && (
          !DataValidator.isNullOrUndefined(this.navigationService.getPreviousRoute())
          && this.navigationService.getPreviousRoute().url !== '/')) {
            this.navigationService.goBack();
        }*/
      });
  }

  setValidationRouteByCompanyType() {
    if (this.globals.isForwarder()) {
      this.router.navigate(['/my-forwarder-account'], {replaceUrl: true});
    } else {
      this.router.navigate(['/my-company-account'], {replaceUrl: true});
    }
  }

  getInitInfoAndCheckProfile() {
    this.initInfoService.getInitInfo().subscribe((value => {
        this.saveGlobals(value);
      }),
      error => {
        this.alertNotificationsComponent.showGenericDataError();
        this.logout();
      },
      () => {
        if (this.globals.isCompany()) {
          if (this.routeHelper.isForwarderRoute()) {
            this.alertNotificationsComponent.showNotForwarderMessage();
            this.setRouteByCompanyType();
          }
        }
        if (this.globals.isForwarder()) {
          if (this.routeHelper.isCompanyRoute()) {
            this.alertNotificationsComponent.showNotCompanyMessage();
            this.setRouteByCompanyType();
          }
        }
      });
  }

  getInitInfoAndCheckProfileSynchronous() {
    return this.initInfoService.getInitInfoSynchronous();
  }

  getInitInfoAndCheckAccountActivated() {
    this.initInfoService.getInitInfo().subscribe((value => {
        this.saveGlobals(value);
      }),
      error => {
        this.alertNotificationsComponent.showGenericDataError();
        this.logout();
      },
      () => {
        if (!this.globals.isAccountActivated()) {
          if (this.globals.isForwarder()) {
            this.alertNotificationsComponent.showNeedVerifyAccountMessage();
          } else {
            this.alertNotificationsComponent.showNoActivatedAccountMessage();
          }

          this.setProfileRouteByCompanyType();
        }
      });
  }

  getInitInfoAppModule() {
    this.initInfoService.getInitInfo().subscribe((value => {
        this.saveGlobals(value);
      }),
      error => {
        this.alertNotificationsComponent.showGenericDataError();
        this.logout();
      },
      () => {
        this.setDefaultRoute();
      });
  }

  saveGlobals(value) {
    try {
      this.globals.customerType = CustomerType.FORWARDER;
      if (value.companyType === UserService.COMPANY_TYPE) {
        this.globals.customerType = CustomerType.COMPANY;
      }
      this.globals.activated = value.activated;
      this.globals.role = value.role;
      this.globals.lang = value.lang;
      this.globals.mail = value.mail;
      this.globals.name = value.name;
      this.globals.surname = value.surname;
      this.globals.avatar = value.avatar;
      this.globals.userId = value.id;
      this.globals.createdAt = value.createdAt;
      this.globals.tfn = value.tfn;
      this.globals.mixPanelId = value.mixPanelId;
      this.globals.enterpriseLogo = value.enterpriseLogo;
      this.globals.enterpriseDir = value.enterpriseDir;
      this.globals.enterpriseCountry = value.enterpriseCountry;
      this.globals.setCompanyName(value.companyName);
      this.globals.setCompanyCifNif(value.companyCifNif);
      this.globals.companyBillingOptions = value.companyBillingOptions;

      this.mixpanelHelper.identify();

      this.mixpanelHelper.setProperties({
        '$email': this.globals.mail,
        '$first_name': this.globals.name,
        '$last_name': this.globals.surname,
        '$name': this.globals.name + ' ' + this.globals.surname,
        '$phone': this.globals.tfn,
        '$avatar': environment.baseApiUrl + 'files/' + this.globals.avatar,
        '$created': this.globals.createdAt,
        'user_db_id': this.globals.userId,
        'user_role': this.globals.role,
        'user_lang': this.globals.lang,
        'user_company_type': this.getCompanyType(),
        'user_company_name': this.globals.getCompanyName(),
        'user_company_cif': this.globals.getCompanyCifNif(),
        'user_company_direction': this.globals.enterpriseDir,
        'user_company_logo': environment.baseApiUrl + 'files/' + this.globals.enterpriseLogo
      });
      const language = this.injector.get<LanguageHelper>(LanguageHelper);
      language.initDirectLanguage(this.globals.lang);

      // this.saveChatUser(value.chatUser);
    } catch (e) {
      // Do nothing
    }


  }

  // TODO: Image
  private saveChatUser(chatUser: ChatUser) {
    const self = this;

    // TODO: Reactivar chat en un futuro
    /*QB.init(QBconfig.credentials.appId, QBconfig.credentials.authKey, QBconfig.credentials.authSecret, QBconfig.credentials.accountKey, QBconfig.appConfig);
    QB.createSession(function (error, result) {
      if (self.existsChatUser(chatUser)) {
        self.globals.setChatUser(chatUser);
        self.globalService.onGlobalLoaded(true);
      } else {
        var randomLogin = RandomUtils.makeRandomAlf(8);
        const user = {
          login: randomLogin,
          password: 'innchatftps',
          full_name: self.globals.getCompanyName(),
          email: self.globals.mail
        };*/

        /* TODO: Put mail email: self.globals.mail*/

        /*self.chatUserService.createUser(user).then(createRes => {
          // console.log('create user success :', createRes);

          var chatUser = new ChatUser();
          chatUser.userLoginName = randomLogin;
          chatUser.userPass = 'innchatftps';
          chatUser.userName = self.globals.getCompanyName();
          // TODO: Put on the future
          chatUser.userMail = self.globals.mail;
          chatUser.imageUrl = '';
          chatUser.userChatId = createRes.id;

          self.globals.setChatUser(chatUser);
          self.globalService.onGlobalLoaded(true);

          self.innportingChatServices.createChatUser(chatUser).subscribe((value => {
            }),
            error => {
              //console.log(error);
            },
            () => {
            });

        }).catch(createErr => {
          // console.log(createErr);
        });
      }
    });*/

  }

  private existsChatUser(chatUser: ChatUser) {
    return !DataValidator.isNullOrUndefined(chatUser) && !DataValidator.isNullOrUndefined(chatUser.userLoginName);
  }

  private getCompanyType() {
    if (this.globals.customerType === CustomerType.COMPANY) {
      return 'Company';
    } else if (this.globals.customerType === CustomerType.FORWARDER) {
      return 'Forwarder';
    } else {
      return '';
    }
  }

  gestionLoginError(error) {
    if (error.status === UserService.UNAUTHORIZED_REQUEST || error.status === UserService.BAD_REQUEST) {
      this.alertNotificationsComponent.showUserOrPasswordError();
    } else {
      this.alertNotificationsComponent.showGenericDataError();
    }
  }

  setRouteByCompanyType() {
    if (this.globals.isForwarder()) {
      this.router.navigate(['/forwarder-overview'], {replaceUrl: true});
    } else {
      this.router.navigate(['/company-overview'], {replaceUrl: true});
    }
  }

  setProfileRouteByCompanyType() {
    if (this.globals.isForwarder()) {
      this.router.navigate(['/my-forwarder-account'], {replaceUrl: true});
    } else {
      this.router.navigate(['/my-company-account'], {replaceUrl: true});
    }
  }

  setDefaultRoute() {
    if (this.location.path() === '') {
      const route =
        this.globals.isCompany() ? '/company-overview' : '/forwarder-overview';
      this.router.navigate([route], {replaceUrl: true});
    }
  }

  login(accessToken: string) {
    const decodedToken = this.jwtHelper.decodeToken(accessToken);
    localStorage.setItem(TOKEN_NAME, accessToken);
  }

  logout(redirect = true) {
    localStorage.removeItem(TOKEN_NAME);
    if (redirect) {
      this.router.navigate(['/login']);
    }
  }

  logoutWithoutNavigation() {
    localStorage.removeItem(TOKEN_NAME);
  }

  getDecodedToken() {
    if (this.getLocalStorageAccessToken() !== '') {
      const decodedToken = this.jwtHelper.decodeToken(localStorage.getItem(TOKEN_NAME));
      if (decodedToken) {
        return decodedToken;
      } else {
        return null;
      }
    }
    return null;
  }

  isLogged() {
    if (this.getDecodedToken() !== null && this.getDecodedToken() !== undefined && this.isTokenNotExpired()) {
      const decodedToken = this.getDecodedToken();
      if (decodedToken) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  isTokenNotExpired() {
    return !this.jwtHelper.isTokenExpired(this.getLocalStorageAccessToken());
  }

  lock() {
    localStorage.removeItem(TOKEN_NAME);
    this.router.navigate(['/lock']);
  }

  getLocalStorageAccessToken() {
    return localStorage.getItem(TOKEN_NAME) !== null && localStorage.getItem(TOKEN_NAME) !== undefined &&
    localStorage.getItem(TOKEN_NAME) !== '' ? localStorage.getItem(TOKEN_NAME) : '';
  }

  isAdminUser(): boolean {
    if (this.getLocalStorageAccessToken() !== '') {
      const decodedToken = this.jwtHelper.decodeToken(this.getLocalStorageAccessToken());
      return decodedToken.authorities.some(el => el === 'Admin');
    }
    return false;
  }

  isUser(): boolean {
    if (this.getLocalStorageAccessToken() !== '') {
      const decodedToken = this.jwtHelper.decodeToken(this.getLocalStorageAccessToken());
      return decodedToken.authorities.some(el => el === 'User');
    }
    return false;
  }
}
