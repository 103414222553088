import {AbstractControl} from '@angular/forms';
import {validateSpanishId} from 'spain-id';
import {DataValidator} from './DataValidator';

export class CifNifDniValidator {

  static MatchCifNifDni(AC: AbstractControl) {
    if(!DataValidator.isNullOrUndefined(AC)) {
      const nif = CifNifDniValidator.getControl(AC).value;
      if(DataValidator.isEmpty(nif)) {
        CifNifDniValidator.getControl(AC).setErrors({MatchCifNifDni: true});
      } else {
        if (!validateSpanishId(nif)) {
          CifNifDniValidator.getControl(AC).setErrors({MatchCifNifDni: true});
        } else {
          CifNifDniValidator.getControl(AC).setErrors(null);
        }
      }
    }
  }

  static getControl(AC: AbstractControl) {
    if(DataValidator.isNullOrUndefined(AC.get('companyCif'))) {
      return AC.get('nif');
    } else {
      return AC.get('companyCif');
    }
  }
}
