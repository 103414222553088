import {Injectable} from '@angular/core';
import {MyShipmentDetail} from '../../services/models/MyShipmentDetail';
import {Observable, Subscriber} from 'rxjs';
import {DataValidator} from '../../../../../../shared/components/inputs/utils/DataValidator';
import {FinalSeaTrackData} from '../../services/models/FinalSeaTrackData';
import Data = google.maps.Data;
import {MyCompleteShipment} from '../models/MyCompleteShipment';
import {ShipmentUnitsSummaryWrapper} from '../shipment-commodity-summary/services/models/ShipmentUnitsSummaryWrapper';
import {ShipmentTimelineStep} from '../shipment-timeline-details/services/models/ShipmentTimelineStep';

@Injectable({
  providedIn: 'root'
})
export class ShipmentDetailService {
  private static SHIPMENT_STATUS_CANCELLED = 9;
  private static SHIPMENT_STATUS_PENDING_PAYMENT = 8;
  private static SHIPMENT_STATUS_DELIVERED = 6;
  private static SHIPMENT_STATUS_ARRIVED_AT_DESTINATION = 5;

  private myCompleteShipment: MyCompleteShipment;
  private observer: Subscriber<unknown>;
  private myShipmentIsLoaded: boolean;
  private trackView: boolean;
  private seaTrackDataObserver: Subscriber<FinalSeaTrackData>;
  private finalSeaTrackData: FinalSeaTrackData;
  private finalEta: string;
  private finalEtaObserver: Subscriber<string>;

  constructor() {
    this.myShipmentIsLoaded = false;
    this.trackView = false;
    this.myCompleteShipment = new MyCompleteShipment();
  }

  getMyShipment() {
    return this.myCompleteShipment.myShipment;
  }

  setMyShipment(myShipment: MyShipmentDetail) {
    this.myCompleteShipment.myShipment = myShipment;
    this.checkIfMyShipmentIsLoaded();
  }

  checkIfMyShipmentIsLoaded() {
    if (!DataValidator.isNullOrUndefined(this.myCompleteShipment.myShipment) &&
      !DataValidator.isNullOrUndefined(this.myCompleteShipment.shipmentUnitsSummary) &&
      !DataValidator.isNullOrUndefined(this.myCompleteShipment.shipmentTimelineSteps)
    ) {
      this.setMyShipmentLoaded();
    }
  }

  setCommodities(shipmentUnits: ShipmentUnitsSummaryWrapper) {
    this.myCompleteShipment.shipmentUnitsSummary = shipmentUnits;
    this.checkIfMyShipmentIsLoaded();
  }

  setTimeline(timelineSteps: [ShipmentTimelineStep]) {
    this.myCompleteShipment.shipmentTimelineSteps = timelineSteps;
    this.checkIfMyShipmentIsLoaded();
  }

  setMyShipmentLoaded() {
    this.myShipmentIsLoaded = true;
    if (!DataValidator.isNullOrUndefined(this.observer)) {
      this.observer.next();
    }
  }

  waitForMyShipmentToBeLoaded() {
    return new Observable((observer) => {
      if (this.myShipmentIsLoaded) {
        observer.next();
      } else {
        this.observer = observer;
      }
      return {
        unsubscribe() {
        }
      };
    });
  }

  myShipmentLoaded() {
    return this.myShipmentIsLoaded;
  }

  public isAirShipmentForTrack(shipment?: MyShipmentDetail): boolean {
    if (DataValidator.isNullOrUndefined(shipment)) {
      shipment = this.myCompleteShipment.myShipment;
    }
    if (shipment.transportType === 'Air') {
      if (shipment.shipmentStatusId !== ShipmentDetailService.SHIPMENT_STATUS_CANCELLED &&
        shipment.shipmentStatusId !== ShipmentDetailService.SHIPMENT_STATUS_PENDING_PAYMENT
        && shipment.shipmentStatusId !== ShipmentDetailService.SHIPMENT_STATUS_DELIVERED
        && !DataValidator.isEmpty(shipment.flightNumber)) {
        return true;
      }
    }
    return false;
  }

  public isSeaShipmentForTrack(shipment?: MyShipmentDetail): boolean {
    if (DataValidator.isNullOrUndefined(shipment)) {
      shipment = this.myCompleteShipment.myShipment;
    }
    if (shipment.transportType === 'FCL' || shipment.transportType === 'LCL') {
      if (shipment.shipmentStatusId !== ShipmentDetailService.SHIPMENT_STATUS_CANCELLED &&
        shipment.shipmentStatusId !== ShipmentDetailService.SHIPMENT_STATUS_PENDING_PAYMENT
        && shipment.shipmentStatusId !== ShipmentDetailService.SHIPMENT_STATUS_DELIVERED
        && shipment.shipmentStatusId !== ShipmentDetailService.SHIPMENT_STATUS_ARRIVED_AT_DESTINATION
        && !DataValidator.isEmpty(shipment.imoNumber)) {
        return true;
      }
    }
    return false;
  }

  public isTrackView() {
    return this.trackView;
  }

  setTrackView(trackView: boolean) {
    this.trackView = trackView;
  }

  setFinalSeaTrackData(finalSeaTrackData: FinalSeaTrackData) {
    if (!DataValidator.isNullOrUndefined(this.seaTrackDataObserver)) {
      this.finalSeaTrackData = finalSeaTrackData;
      this.seaTrackDataObserver.next(finalSeaTrackData);
    }
  }

  getFinalSeaTrackData(): Observable<FinalSeaTrackData> {
    return new Observable((observer) => {
      if (this.finalSeaTrackData) {
        observer.next(this.finalSeaTrackData);
      } else {
        this.seaTrackDataObserver = observer;
      }
      return {
        unsubscribe() {
        }
      };
    });
  }

  setAirEta(finalEta: string) {
    this.finalEta = finalEta;
    if (!DataValidator.isNullOrUndefined(this.finalEtaObserver)) {
      this.finalEtaObserver.next(finalEta);
    }
  }

  getAirEta(): Observable<string> {
    return new Observable((observer) => {
      if (this.finalEta) {
        observer.next(this.finalEta);
      } else {
        this.finalEtaObserver = observer;
      }
      return {
        unsubscribe() {
        }
      };
    });
  }

  reset() {
    this.myCompleteShipment = new MyCompleteShipment();
    this.observer = null;
    this.myShipmentIsLoaded = false;
    this.trackView = false;
    this.seaTrackDataObserver = null;
    this.finalSeaTrackData = null;
    this.finalEta = '';
    this.finalEtaObserver = null;
  }

  getCommodities() {
    return this.myCompleteShipment.shipmentUnitsSummary;
  }

  getTimeline() {
    return this.myCompleteShipment.shipmentTimelineSteps;
  }
}
