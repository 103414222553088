import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from '../services/user.service';
import {AlertNotificationsComponent} from '../../../components/alert-notifications/alert-notifications.component';
import {DataValidator} from '../../../components/inputs/utils/DataValidator';
import {LocalStorageHelper} from '../../../../../utils/LocalStorageHelper';
import {TranslateService} from '@ngx-translate/core';
import {LanguageHelper} from '../../../../../utils/LanguageHelper';
import {NavigationService} from '../../../../../utils/NavigationService/navigation.service';
import {take} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService,
              private alertNotificationComponent: AlertNotificationsComponent,
              private languageHelper: LanguageHelper,
              private navigationService: NavigationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.setAtokenIfNeeded(route);
    if (this.userService.isLogged()) {
      return true;
    } else {
      if (state.url !== '/') {
        this.alertNotificationComponent.showNotLoggedMessage();
      }
      this.navigationService.pushInnRoute({url: state.url, scrollY: 0});
      this.router.navigate(['/login'], {replaceUrl: true});
      return false;
    }
  }

  private setAtokenIfNeeded(route: ActivatedRouteSnapshot) {
    if (!DataValidator.isEmpty(route.queryParamMap.get('atoken'))) {
      const atoken = route.queryParamMap.get('atoken');
      LocalStorageHelper.setItem(LocalStorageHelper.ATOKEN_KEY, atoken);
    }
  }
}
