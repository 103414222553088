import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PasswordValidator} from '../../../shared/components/inputs/utils/PasswordValidator';
import {PasswordInputComponent} from '../../../shared/components/inputs/password-input/password-input.component';
import {FormUtils} from '../../../shared/components/inputs/utils/FormUtils';
import {RegisterService} from './services/register/register.service';
import {LanguageHelper} from '../../../../utils/LanguageHelper';
import {AuthenticationService} from '../../../shared/features/auth/services/authentication.service';
import {UserService} from '../../../shared/features/auth/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InitInfoService} from '../../../shared/features/get-init-info/services/init-info.service';
import {Globals} from '../../../shared/models/Globals';
import {AlertNotificationsComponent} from '../../../shared/components/alert-notifications/alert-notifications.component';
import {CifNifDniValidator} from '../../../shared/components/inputs/utils/CifNifDniValidator';
import {LoaderComponent} from '../../../shared/components/loader/loader/loader.component';
import {MixPanelHelper} from '../../../../utils/mixPanel/MixPanelHelper';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RegisterComponent implements OnInit, OnDestroy, AfterViewInit {

  public static readonly EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
  public static readonly NOT_ACCEPTABLE_REQUEST = 406;

  registerFormGroup: FormGroup;

  public companytype = undefined;
  public email = undefined;
  public companyname = undefined;
  public companyid = undefined;
  public comesFromInvi = false;

  @Input()
  confirmPasswordComponent: PasswordInputComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder, private registerService: RegisterService,
              public languageHelper: LanguageHelper, private authenticationService: AuthenticationService,
              private userService: UserService, private router: Router, private initInfoService: InitInfoService,
              private globals: Globals, private alertNotificationComponent: AlertNotificationsComponent,
              private mixpanelHelper: MixPanelHelper) {
  }

  ngOnInit() {
    this.userService.logoutWithoutNavigation();
    this.initQueryParams();
    this.init();
    this.initFormBuilder();
    this.setDefaultEnterpriseType();
    this.setDefaultLang();
  }

  private initQueryParams() {
    const companytype = this.activatedRoute.snapshot.queryParamMap.get('companytype');
    const email = this.activatedRoute.snapshot.queryParamMap.get('email');
    const companyname = this.activatedRoute.snapshot.queryParamMap.get('companyname');
    const companyid = this.activatedRoute.snapshot.queryParamMap.get('companyid');
    if(companytype !== null && email !== null && companyname !== null && companyid !== null) {
      this.companytype = companytype;
      this.email = email;
      this.companyname = companyname;
      this.companyid = companyid;
      this.comesFromInvi = true;
    }
  }

  private init() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('register-page');
    body.classList.add('off-canvas-sidebar');
  }

  private initFormBuilder() {
    /** nif: [null, Validators.required], **/
    this.registerFormGroup = this.formBuilder.group({
      name: [null, Validators.required],
      surname: [null, Validators.required],
      mail: [null, [Validators.required, Validators.pattern(RegisterComponent.EMAIL_REGEXP)]],
      enterprise: [null, Validators.required],
      phone: [null, Validators.required],
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      checkTerms: [null, Validators.requiredTrue],
      enterpriseType: [null, Validators.required],
      lang: [null, Validators.required],
      nif: [null, Validators.required],
      invitation: null
    }, {
      validator: [PasswordValidator.MatchPassword, CifNifDniValidator.MatchCifNifDni]
    });
  }

  private setDefaultEnterpriseType() {
    this.registerFormGroup.get('enterpriseType').setValue('company');
  }

  public checkFields() {
    if (this.registerFormGroup.valid) {
      this.register();
    } else {
      FormUtils.markIncorrectFields(this.registerFormGroup);
    }
  }

  private register() {
    this.registerFormGroup.get('lang').setValue(this.languageHelper.getCurrentLang());
    this.registerFormGroup.get('invitation').setValue(this.comesFromInvi ? 'S' : 'N');
    LoaderComponent.show();
    this.registerService.register(this.registerFormGroup.value).subscribe((value => {
        try {
          this.setPhoneCountryLocalStorage(this.registerFormGroup.get('phone').value.countryCode.toLowerCase());
        } catch (e) {
          // Do nothing
        }
        this.sendSubmitEventMixPanel(value.getId());
        LoaderComponent.hide();
      }),
      error => {
        LoaderComponent.hide();
        if (error.status === RegisterComponent.NOT_ACCEPTABLE_REQUEST) {
          try {
            if (error.error.message.indexOf('Enterprise') !== -1) {
              this.alertNotificationComponent.showAlreadyRegisteredEnterprise();
            } else {
              this.alertNotificationComponent.showAlreadyRegisteredMail();
            }
          } catch (e) {
            this.alertNotificationComponent.showGenericDataError();
          }
        } else {
          this.alertNotificationComponent.showGenericDataError();
        }
      },
      () => {
        LoaderComponent.hide();
        this.login();
      });
  }

  login() {
    this.userService.loginGetInitInfoAndEnter(this.registerFormGroup.get('mail').value,
      this.registerFormGroup.get('password').value);
  }

  public setUserType(role: string) {
    this.registerFormGroup.get('enterpriseType').setValue(role);
  }

  private setDefaultLang() {
    this.registerFormGroup.get('lang').setValue(this.languageHelper.getCurrentLang());
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('register-page');
    body.classList.remove('off-canvas-sidebar');
  }

  ngAfterViewInit(): void {
    this.inviteGestion();
  }

  inviteGestion() {
    if(this.comesFromInvi) {
      this.registerFormGroup.get('mail').setValue(this.email);
      this.registerFormGroup.get('enterprise').setValue(this.companyname);
      this.registerFormGroup.get('nif').setValue(this.companyid);
      if(this.companytype === 'C') {
        document.getElementById('tabsContainer').style.display = "none";
        this.setUserType('company');
      } else if(this.companytype === 'F'){
        document.getElementById('tabsContainer').style.display = "none";
        this.setUserType('forwarder');
      }
    }
  }

  private sendSubmitEventMixPanel(id: string) {
    try {
      const props = {
        userName: this.registerFormGroup.get('name').value + ' ' + this.registerFormGroup.get('surname').value,
        userMail: this.registerFormGroup.get('mail').value,
        userTfn: this.registerFormGroup.get('phone').value.internationalNumber,
        enterprise: this.registerFormGroup.get('enterprise').value,
        enterpriseNif: this.registerFormGroup.get('nif').value,
        user_db_id: id
      };
      this.mixpanelHelper.sendEvent('Register', props);
    } catch (e) {
      // Do nothing
    }
  }

  public sendNifErrorEventMixPanel() {
    try {
      let completeName = null;
      if (this.registerFormGroup.get('name').value && this.registerFormGroup.get('surname').value) {
        completeName = this.registerFormGroup.get('name').value + ' ' + this.registerFormGroup.get('surname').value;
      }
      let phone = null;
      if (this.registerFormGroup.get('phone').value) {
        phone = this.registerFormGroup.get('phone').value.internationalNumber;
      }

      const props = {
        userName: completeName,
        userMail: this.registerFormGroup.get('mail').value,
        userTfn: phone,
        enterprise: this.registerFormGroup.get('enterprise').value,
        enterpriseNif: this.registerFormGroup.get('nif').value
      };
      this.mixpanelHelper.sendEvent('Register Nif Error', props);
    } catch (e) {
      // Do nothing
    }
  }

  setPhoneCountryLocalStorage(phone) {
    try {
      const countryCode = phone.countryCode.toLowerCase();
      localStorage.setItem('itemAccountPhoneIso', countryCode);
    } catch (e) {
      // Do nothing
    }
  }
}
