import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import {AddPaymentMethodComponent} from '../add-payment-method/add-payment-method.component';
import {ShipmentPaymentRequest} from '../models/ShipmentPaymentRequest';
import {AlertNotificationsComponent} from '../../alert-notifications/alert-notifications.component';
import {PaymentsService} from '../services/payments.service';
import {PaymentMethodSelectorComponent} from '../payment-method-selector/payment-method-selector.component';
import {InnComponent} from '../../inn-component/InnComponent';
import {Globals} from '../../../models/Globals';
import {DateUtils} from '../../../../../utils/DateUtils';
import {PaymentMethodsService} from '../services/payment-methods.service';
import {TranslateService} from '@ngx-translate/core';
import {DataValidator} from '../../inputs/utils/DataValidator';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [PaymentMethodsService]
})
export class PaymentComponent extends InnComponent implements OnInit, AfterViewInit {

  static readonly INSTANT_PAYMENT_TAB = 'instant_payment';
  static readonly LATER_PAYMENT_TAB = 'later_payment';

  static readonly NEW_PAYMENT_METHOD = 'new_payment_method';
  static readonly DEFAULT_ACTIVE_TAB = 'instant_payment';
  static readonly DEFAULT_ACTIVE_LATER_PAYMENT_OPTION = 'download';

  static readonly SEPA_DIRECT_DEBIT_PAYMENT_STRATEGY = 'sepa_direct_debit';

  static readonly AUTO_STRIPE_BILL_STRATEGY_ID = 1;
  static readonly STRIPE_BILL_STRATEGY_ID = 2;
  static readonly SEPA_DIRECT_DEBIT_PAYMENT_STRATEGY_ID = 3;
  static readonly STRIPE_BILL_NOW_STRATEGY_ID = 4;

  @Output() onPaymentSucceed = new EventEmitter<any>();

  @ViewChild('payLaterAnchor')
  private payLaterAnchor: ElementRef;

  @ViewChild('payNowAnchor')
  private payNowAnchor: ElementRef;

  @ViewChild(AddPaymentMethodComponent)
  addPaymentMethodComponent: AddPaymentMethodComponent;

  @ViewChild('instantPaymentMethodSelector')
  paymentMethodSelector: PaymentMethodSelectorComponent;

  @ViewChild('laterPaymentMethodSelector')
  laterPaymentMethodSelector: PaymentMethodSelectorComponent;

  @ViewChild('movingTab')
  private movingTab: ElementRef;

  @ViewChild('masterNavigation')
  private masterNavigation: ElementRef;

  private shipmentId: number;
  private quoteId: number;
  public priceAmount: number;
  selectedSavedPaymentMethod: string;
  paymentMethodToUse: string;
  laterPaymentActiveOption = PaymentComponent.DEFAULT_ACTIVE_LATER_PAYMENT_OPTION;
  activeTab = PaymentComponent.DEFAULT_ACTIVE_TAB;
  scheduledPaymentDate: string;

  constructor(private alertNotificationsComponent: AlertNotificationsComponent,
              private paymentsService: PaymentsService,
              private changeDetectorRef: ChangeDetectorRef,
              public globals: Globals,
              private dateUtils: DateUtils,
              private translateService: TranslateService) {
    super(changeDetectorRef);
    this.scheduledPaymentDate = this.dateUtils.sumDaysToToday(this.globals.companyBillingOptions.invoicesDueDays);
  }

  @HostListener('window:resize')
  resize() {
    this.moveMovingTabWithDelay();
  }

  ngOnInit() {
    if (this.globals.companyBillingOptions.verified) {
      this.navigateToTab('later_payment');
    }
    this.translateService.onLangChange.subscribe(() => {
      this.moveMovingTabWithDelay();
    });
  }

  ngAfterViewInit() {
    this.moveMovingTabWithDelay();
  }

  paymentMethodSelected(paymentMethod: string) {
    this.selectedSavedPaymentMethod = paymentMethod;
  }

  submitPayment() {
    this.changeLoadingStateTS(true);
    if (this.selectedSavedPaymentMethod !== PaymentComponent.NEW_PAYMENT_METHOD) {
      this.paymentMethodToUse = this.selectedSavedPaymentMethod;
      this.makePayment(null);
    } else {
      this.addPaymentMethodComponent.submitPaymentMethod();
    }
  }

  payByAutomaticInvoice() {
    this.changeLoadingStateTS(true, 'submitButton');
    if (this.selectedSavedPaymentMethod !== PaymentComponent.NEW_PAYMENT_METHOD) {
      this.paymentMethodToUse = this.selectedSavedPaymentMethod;
      this.makePaymentByAutomaticInvoice();
    } else {
      this.addPaymentMethodComponent.submitPaymentMethod();
    }
  }

  changeLoadingStateTS(isLoading, buttonId = '') {
    try {
      let buttons = [];
      if (buttonId !== '') {
        buttons.push(document.getElementById(buttonId));
      } else {
        buttons = Array.from(document.getElementsByClassName('submit-payment-button'));
      }
      buttons.forEach(button => {
        if (isLoading) {
          button.setAttribute('disabled', 'true');
          button.classList.add('loader-on');
          button.getElementsByClassName('spinner')[0].classList.add('loading');
          button.getElementsByClassName('button-text')[0].classList.add('hidden');
        } else {
          button.removeAttribute('disabled');
          button.classList.remove('loader-on');
          button.getElementsByClassName('spinner')[0].classList.remove('loading');
          button.getElementsByClassName('button-text')[0].classList.remove('hidden');
        }
      });
    } catch (e) {
      // Do nothing
    }
  }

  savePaymentMethodIdAndPay(paymentMethodId) {
    this.paymentMethodToUse = paymentMethodId;
    // this.makePayment(null);
    this.makePaymentByAutomaticInvoice();
    this.paymentMethodSelector.getPaymentMethods(paymentMethodId, true);
  }

  public makePayment(paymentIntentId) {
    var shipmentPaymentRequest = new ShipmentPaymentRequest();
    shipmentPaymentRequest.shipmentId = this.shipmentId;
    shipmentPaymentRequest.quoteId = this.quoteId;
    shipmentPaymentRequest.paymentMethodId = this.paymentMethodToUse;
    if (paymentIntentId != null) {
      shipmentPaymentRequest.paymentIntentId = paymentIntentId;
    }

    this.paymentsService.pay(shipmentPaymentRequest).subscribe((value => {
        this.handlePaymentAction(value);
      }),
      error => {
        this.changeLoadingStateTS(false);
        try {
          let errorMessage = error.error.message;
          if (errorMessage.startsWith('visible: ')) {
            errorMessage = errorMessage.replace('visible: ', '');
            this.alertNotificationsComponent.showErrorMessage(errorMessage);
          } else {
            this.alertNotificationsComponent.showGenericDataError();
          }
        } catch (e) {
          this.alertNotificationsComponent.showGenericDataError();
        }
      },
      () => {
        this.changeLoadingStateTS(false);
      });
  }

  public makePaymentByAutomaticInvoice() {
    const component = this;
    var shipmentPaymentRequest = new ShipmentPaymentRequest();
    shipmentPaymentRequest.shipmentId = this.shipmentId;
    shipmentPaymentRequest.quoteId = this.quoteId;
    shipmentPaymentRequest.paymentMethodId = this.paymentMethodToUse;
    this.onPaymentSucceed.emit('auto_stripe_bill');
  }

  public handlePaymentAction(response: JSON) {
    const component = this;
    if (response['error']) {
      component.alertNotificationsComponent.showPaymentError();
    } else if (response['requiresAction']) {
      this.addPaymentMethodComponent.stripe.handleCardAction(
        response['clientSecret']
      ).then(function (result) {
        if (result.error) {
          component.alertNotificationsComponent.showPaymentError();
        } else {
          component.makePayment(result.paymentIntent.id);
        }
      });
    } else {
      component.alertNotificationsComponent.showPaymentSuccess();
      this.onPaymentSucceed.emit();
    }
  }

  public setQuoteIdShipmentIdPriceAmount(quoteId: number, shipmentId: number, priceAmount: number) {
    this.quoteId = quoteId;
    this.shipmentId = shipmentId;
    this.priceAmount = priceAmount;
  }

  changeLoaderState(isLoading: boolean) {
    this.changeLoadingStateTS(isLoading);
  }

  setLaterPaymentActiveOption(value: string) {
    this.laterPaymentActiveOption = value;
    this.detectChanges();
  }

  navigateToTab(tab: string) {
    this.activeTab = tab;
    this.laterPaymentActiveOption = PaymentComponent.DEFAULT_ACTIVE_LATER_PAYMENT_OPTION;
    this.selectedSavedPaymentMethod = undefined;
    this.detectChanges();
    this.moveMovingTab();
  }

  payByLaterStripeBill() {
    this.changeLoadingStateTS(true, 'payBillLaterButton');
    this.onPaymentSucceed.emit('stripe_bill');
  }

  payBySepaDirectDebit() {
    this.changeLoadingStateTS(true, 'paySepaDirectDebitButton');
    if (this.selectedSavedPaymentMethod !== PaymentComponent.NEW_PAYMENT_METHOD) {
      this.paymentMethodToUse = this.selectedSavedPaymentMethod;
      this.onPaymentSucceed.emit('sepa_direct_debit');
    } else {
      this.addPaymentMethodComponent.submitPaymentMethod();
    }
  }

  savePaymentMethodIdAndDirectDebit(paymentMethodId: string) {
    this.selectedSavedPaymentMethod = paymentMethodId;
    this.payBySepaDirectDebit();
    this.laterPaymentMethodSelector.getPaymentMethods(paymentMethodId);
  }

  payByManualPayment() {
    this.changeLoadingStateTS(true, 'submitButton');
    this.onPaymentSucceed.emit('stripe_bill_now');
  }

  private moveMovingTab() {
    const activeHeadParent = this.masterNavigation.nativeElement.getElementsByClassName('active')[0];
    const movingTab = this.movingTab.nativeElement;
    const offset = 10, midOffset = 5;
    if (activeHeadParent.offsetWidth < movingTab.offsetWidth - offset) {
      movingTab.innerHTML = activeHeadParent.innerText;
    } else {
      setTimeout( () => {
        movingTab.innerHTML = activeHeadParent.innerText;
      }, 250);
    }
    movingTab.style.left = activeHeadParent.offsetLeft - midOffset + 'px';
    movingTab.style.width = activeHeadParent.offsetWidth + offset + 'px';
    movingTab.style.height = activeHeadParent.offsetHeight + 'px';
    movingTab.style.top = activeHeadParent.offsetTop + 'px';
  }

  moveMovingTabWithDelay() {
    setTimeout(() => {
      this.moveMovingTab();
    }, 300);
  }

  setViewFromPaymentStrategyId(strategyId: number, paymentMethodId?: string) {
    switch (strategyId) {
      case PaymentComponent.AUTO_STRIPE_BILL_STRATEGY_ID:
        this.navigateToTab(PaymentComponent.INSTANT_PAYMENT_TAB);
        if (!DataValidator.isNullOrUndefined(paymentMethodId)) {
          this.paymentMethodSelector.getPaymentMethods(paymentMethodId, false);
        }
        break;
      case PaymentComponent.STRIPE_BILL_STRATEGY_ID:
        this.navigateToTab(PaymentComponent.LATER_PAYMENT_TAB);
        this.setLaterPaymentActiveOption('download');
        break;
      case PaymentComponent.SEPA_DIRECT_DEBIT_PAYMENT_STRATEGY_ID:
        this.navigateToTab(PaymentComponent.LATER_PAYMENT_TAB);
        this.setLaterPaymentActiveOption('sepa');
        if (!DataValidator.isNullOrUndefined(paymentMethodId)) {
          this.laterPaymentMethodSelector.getPaymentMethods(paymentMethodId, false);
        }
        break;
      case PaymentComponent.STRIPE_BILL_NOW_STRATEGY_ID:
        this.navigateToTab(PaymentComponent.INSTANT_PAYMENT_TAB);
        this.paymentMethodSelector.getPaymentMethods(PaymentMethodSelectorComponent.BANK_TRANSFER_METHOD, false);
        break;
    }
    this.detectChanges();
  }

  getPaymentStrategyString(): string {
    let paymentStrategyString = '';
    if (this.activeTab === PaymentComponent.INSTANT_PAYMENT_TAB) {
      paymentStrategyString += 'Pagar ahora';
      if (this.selectedSavedPaymentMethod === PaymentMethodSelectorComponent.BANK_TRANSFER_METHOD) {
        paymentStrategyString += ' - transferencia bancaria';
      }
    } else {
      paymentStrategyString += `Pagar a ${this.globals.companyBillingOptions.invoicesDueDays} días - ${this.laterPaymentActiveOption}`;
    }
    return paymentStrategyString;
  }
}
