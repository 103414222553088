import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyOnBoardingComponent} from './company-on-boarding.component';
import {CompanyOnBoardingVideoComponent} from './components/on-boarding-video/company-on-boarding-video.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [CompanyOnBoardingComponent, CompanyOnBoardingVideoComponent],
  exports: [
    CompanyOnBoardingVideoComponent,
    CompanyOnBoardingComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class ComanyOnBoardingModule { }
