import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {CONSTANTS} from '../QBconfig';

import {Chathelpers} from '../chat-helper/Chathelpers';
import {ChatDashboardService} from '../chat-dashboard.service';
import {DialogChatService} from './dialog-chat.service';
import {QBHelper} from '../chat-helper/qbHelper';
import {ChatUserService} from '../chat-user/chat-user.service';
import {ChatMessageService} from '../chat-messages/chat-message.service';
import {Globals} from '../../../models/Globals';
import {LoaderComponent} from '../../loader/loader/loader.component';
import {InnportingChatServicesService} from '../innporting-chat-services/innporting-chat-services.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertNotificationsComponent} from '../../alert-notifications/alert-notifications.component';
import {DataValidator} from '../../inputs/utils/DataValidator';
import {InnComponent} from '../../inn-component/InnComponent';

@Component({
  selector: 'app-chat-dialogs',
  templateUrl: './dialogs-chat.component.html',
  styleUrls: ['./dialogs-chat.component.scss']
})
export class DialogsChatComponent extends InnComponent implements OnInit, OnChanges {

  @Input() dialog: any;
  @ViewChild('field') field: ElementRef;

  chatName: string;
  chatOccupants: string;
  CONSTANTS = CONSTANTS;
  messages: any = [];
  messageField = '';
  userId = this.userService.user.id;
  attachments: any = [];
  shiftDown = false;

  constructor(
    private dashboardService: ChatDashboardService,
    private dialogService: DialogChatService,
    private qbHelper: QBHelper,
    private userService: ChatUserService,
    private innChatService: InnportingChatServicesService,
    private messageService: ChatMessageService,
    private globals: Globals,
    private translateService: TranslateService,
    private alertNotificationsComponent: AlertNotificationsComponent,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
    this.dialogService.currentDialogEvent.subscribe((dilog: Object) => {
      this.dialog = dilog;
      try {
        this.changeDetectorRef.detectChanges();
      } catch (e) {
        // Do nothing
      }
    });
    this.messageService.messagesEvent.subscribe((messages: Object) => {
      this.messages = Object.keys(messages).map(function (key) {
        return [key, messages[key]];
      });
      try {
        this.changeDetectorRef.detectChanges();
      } catch (e) {
        // Do nothing
      }
    });
  }

  ngOnChanges() {
    this.messageField = '';
    this.field.nativeElement.focus();
    const
      self = this,
      params = {
        chat_dialog_id: this.dialog._id,
        sort_desc: 'date_sent',
        limit: 200,
        skip: 0,
        mark_as_read: 0
      };
    self.dialogService.currentDialog.full = false;
    LoaderComponent.show();
    this.messageService.getMessages(params)
      .then((res) => {
        if (res.items.length < 50) {
          self.dialogService.currentDialog.full = true;
        }
        this.messageService.setMessages(res.items.reverse(), 'bottom');
        LoaderComponent.hide();
        this.detectChanges();
      })
      .catch((err) => {
        LoaderComponent.hide();
        // console.log('Messages Error: ', err);
      });

    this.setOccupants();
    this.chatName = this.dialog.name;
  }

  findOtherCompanyId(occupantsIds): string {
    for (let i = 0; i < occupantsIds.length; i++) {
      let occupantId = occupantsIds[i];
      if (occupantId != this.globals.getChatUser().userChatId && occupantId != ChatUserService.INNPORTING_CHAT_USER_ID) {
        return occupantId;
      }
    }
  }

  loadMoreMessages(e) {
    const
      self = this,
      elem = e.currentTarget;
    if (this.dialogService.currentDialog.full !== undefined && this.dialogService.currentDialog.full) {
      delete elem.dataset.loading;
      return false;
    }
    if (elem.scrollTop < 150 && !elem.dataset.loading) {
      elem.dataset.loading = true;
      const params = {
        chat_dialog_id: this.dialog._id,
        sort_desc: 'date_sent',
        limit: 200,
        skip: this.messageService.messages.length,
        mark_as_read: 0
      };
      LoaderComponent.show();
      this.messageService.getMessages(params)
        .then((res) => {
          if (res.items.length < 50) {
            self.dialogService.currentDialog.full = true;
          }
          self.messageService.setMessages(
            res.items.reverse().concat(self.messageService.messages), 'top');
          LoaderComponent.hide();
          delete elem.dataset.loading;
        })
        .catch((err) => {
          LoaderComponent.hide();
          // console.log('Messages Error: ', err);
        });
    }
  }

  public showUpdateDialog() {
    this.dashboardService.showComponent({
      'createGroupClicked': false,
      'updateDialog': true,
      'welcomeChat': false,
      'onChatClick': false
    });
  }

  public quitFromTheDialog() {
    const self = this,
      dialog = this.dialog;

    switch (dialog.type) {
      case CONSTANTS.DIALOG_TYPES.PUBLICCHAT:
        break;
      case CONSTANTS.DIALOG_TYPES.CHAT:
      case CONSTANTS.DIALOG_TYPES.GROUPCHAT:
        if (CONSTANTS.DIALOG_TYPES.GROUPCHAT === dialog.type) {
          // remove user from current  group dialog;
          const msg = {
            type: 'groupchat',
            body: self.userService.user.full_name + ' left the chat.',
            extension: {
              save_to_history: 1,
              dialog_id: dialog._id,
              notification_type: 3,
              dialog_updated_at: Date.now() / 1000
            },
            markable: 1
          };
          this.messageService.sendMessage(this.dialog, msg);
          const systemMessage = {
            extension: {
              notification_type: 3,
              dialog_id: dialog._id
            }
          };
          const userIds = dialog.occupants_ids.filter((userId) => {
            return userId !== self.userService.user.id;
          });
          this.messageService.sendSystemMessage(userIds, systemMessage);
        }
        this.dialogService.deleteDialogByIds([this.dialog._id]);
        this.dashboardService.showComponent({
          'createGroupClicked': false,
          'updateDialog': false,
          'welcomeChat': true,
          'onChatClick': false
        });
        break;
    }
  }

  prepareToUpload(e) {
    const self = this,
      files = e.currentTarget.files,
      dialogId = this.dialogService.currentDialog._id;
    for (let i = 0; i < files.length; i++) {
      self.uploadFilesAndGetIds(files[i], dialogId);
    }
    e.currentTarget.value = null;
  }

  uploadFilesAndGetIds(file, dialogId) {
    if (file.size >= CONSTANTS.ATTACHMENT.MAXSIZE) {
      return this.alertNotificationsComponent.showGenericDataError();
    }
    this.attachments = [{
      id: 'isLoading',
      src: URL.createObjectURL(file)
    }];
    const self = this;
    this.qbHelper.abCreateAndUpload(file).then(response => {
      self.attachments = [];
      const attachments = [{id: response.uid, type: CONSTANTS.ATTACHMENT.TYPE}];
      self.sendMessage(CONSTANTS.ATTACHMENT.BODY, attachments);
    }).catch(err => {
      self.attachments = [];
    });
  }

  onSubmit() {
    if (this.messageField) {
      this.sendMessage(this.messageField);
      this.messageField = null;
    }
  }

  sendMessage(body, attachments: any = false) {
    const
      self = this,
      msg = {
        type: self.dialog.type === 3 ? 'chat' : 'groupchat',
        body: body,
        extension: {
          save_to_history: 1,
          dialog_id: self.dialog._id
        },
        markable: 1
      };
    if (attachments) {
      msg.extension['attachments'] = attachments;
    }
    const
      message = self.messageService.sendMessage(self.dialog, msg),
      newMessage = self.messageService.fillNewMessageParams(self.userService.user.id, message);
    self.dialogService.setDialogParams(newMessage);
    self.messageService.messages.push(newMessage);
    self.messageService.addMessageToDatesIds(newMessage);
    self.messageService.messagesEvent.emit(self.messageService.datesIds);
    setTimeout(() => {
      Chathelpers.scrollTo(document.querySelector('.j-messages'), 'bottom');
    }, 200);
  }

  onKeydown(e) {
    if (e.repeat && e.key === 'Shift') {
      this.shiftDown = true;
    }
    if (e.key === 'Enter' && !e.shiftKey && !this.shiftDown) {
      this.onSubmit();
      return false;
    }
  }

  setSidebarListener() {
    document.querySelector('.j-open_chat-sidebar').addEventListener('click', function (e) {
      document.querySelector('.j-chat-sidebar').classList.add('active');
    }.bind(this));
  }

  ngOnInit(): void {
    this.setSidebarListener();
  }

  private setOccupants() {
    try {
      let occupants = '';
      this.userService.getUserList({
        field: 'id',
        value: this.findOtherCompanyId(this.dialog.occupants_ids)
      }).then(users => {
        try {
          if (!DataValidator.isNullOrUndefined(users[0])) {
            occupants = 'InnPorting'
              + ', ' + users[0].full_name + ', '
              + 'You';
            this.chatOccupants = occupants;
          }
        } catch (e) {
          // Do nothing
        }
      });
    } catch (e) {
      // Do nothing
    }
  }

  startMailConversation() {
    this.innChatService.getChatUser(this.findOtherCompanyId(this.dialog.occupants_ids) as unknown as number).subscribe(otherCompany => {
      const translatedHi = this.translateService.instant('MY_SHIPMENTS.CHAT_DASHBOARD.MAIL_CONVERSATION.HI');
      const translatedSubjectPrefix = this.translateService.instant('MY_SHIPMENTS.CHAT_DASHBOARD.MAIL_CONVERSATION.SUBJECT_PREFIX');
      const translatedSubjectSufix = this.translateService.instant('MY_SHIPMENTS.CHAT_DASHBOARD.MAIL_CONVERSATION.SUBJECT_SUFIX');
      const translatedBodyPrefix = this.translateService.instant('MY_SHIPMENTS.CHAT_DASHBOARD.MAIL_CONVERSATION.BODY_PREFIX');
      const translatedBodySufix = this.translateService.instant('MY_SHIPMENTS.CHAT_DASHBOARD.MAIL_CONVERSATION.BODY_SUFIX');

      const shipmentId = this.dialogService.getDialogShipmentIdByName(this.dialog.name);
      const userName = otherCompany.userName.split(' ')[0];
      let link = 'https://dashboard.innporting.com/company-shipment-detail/';
      if (this.globals.isCompany()) {
        link = 'https://dashboard.innporting.com/forwarder-shipment-detail/';
      }

      const href = `mailto:${otherCompany.userMail}?cc=shipment@innporting.com` +
        `&subject=${translatedSubjectPrefix} ${shipmentId} ${translatedSubjectSufix}` +
        `&body=${translatedHi} ${userName},%0D%0A%0A${translatedBodyPrefix} ${link + shipmentId}${translatedBodySufix}%0D%0A%0A%0A`;
      this.executeHref(href);
    });
  }

  private executeHref(href: string) {
    const anchor = document.createElement('a');
    anchor.href = href;
    anchor.click();
    setTimeout(() => {
      anchor.remove();
    }, 300);
  }
}
