import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddPaymentMethodComponent} from './add-payment-method/add-payment-method.component';
import {PaymentMethodSelectorComponent} from './payment-method-selector/payment-method-selector.component';
import {PaymentComponent} from './payment/payment.component';
import {MyMaterialsModule} from '../../modules/my-materials.module';
import {TranslateModule} from '@ngx-translate/core';
import {EmptyStateModule} from '../empty-state/empty-state.module';

@NgModule({
  declarations: [AddPaymentMethodComponent, PaymentMethodSelectorComponent, PaymentComponent],
  exports: [
    AddPaymentMethodComponent,
    PaymentComponent
  ],
  imports: [
    CommonModule,
    MyMaterialsModule,
    TranslateModule,
    EmptyStateModule
  ]
})
export class PaymentsModule { }
