import {Injectable} from '@angular/core';
import {Location} from '@angular/common';

@Injectable(
  {providedIn: 'root'}
)
export class RouteHelper {
  constructor(private location: Location) {

  }

  areAuthPages() {
    return this.location.path() === '/login' ||
      this.location.path().includes('/register') ||
      this.location.path() === '/lock' ||
      this.location.path() === '/recover-pass' ||
      this.location.path().includes('/recover-pass-user');
  }

  areAuthPagesOrEmpty() {
    return this.location.path() === '' ||
      this.location.path() === '/login' ||
      this.location.path().includes('/register') ||
      this.location.path() === '/lock' ||
      this.location.path() === '/recover-pass' ||
      this.location.path().includes('/recover-pass-user');
  }

  areExternalPagesOrEmpty() {
    return this.location.path() === '' ||
      this.location.path() === '/login' ||
      this.location.path().includes('/register') ||
      this.location.path() === '/lock' ||
      this.location.path() === '/recover-pass' ||
      this.location.path().includes('/recover-pass-user') ||
      this.location.path().includes('/track-my-shipment');
  }

  areTrackPage() {
    return this.location.path().includes('/track-my-shipment');
  }

  isForwarderRoute() {
    return this.location.path() === '/forwarder-overview'
      || this.location.path() === '/forwarder-ship-analytics'
      || this.location.path() === '/forwarder-help'
      || this.location.path() === '/forwarder-notifications'
      || this.location.path() === '/forwarder-chat'
      || this.location.path().includes('/my-transport-company')
      || this.location.path().includes('/forwarder-shipments')
      || this.location.path().includes('/my-quotes')
      || this.location.path().includes('/forwarder-invoices')
      || this.location.path().includes('/my-forwarder-account')
      || this.location.path().includes('/simulate-quote')
      || this.location.path().includes('/forwarder-shipment-detail')
      || this.location.path().includes('/forwarder-offers')
      ;
  }

  isCompanyRoute() {
    return this.location.path() === '/company-overview'
      || this.location.path() === '/company-help'
      || this.location.path() === '/company-ship-analytics'
      || this.location.path() === '/company-notifications'
      || this.location.path() === '/company-chat'
      || this.location.path().includes('/my-company')
      || this.location.path().includes('/ship-now')
      || this.location.path().includes('/company-shipments')
      || this.location.path().includes('/company-invoices')
      || this.location.path().includes('/my-company-account')
      || this.location.path().includes('/company-shipment-detail')
      || this.location.path().includes('/company-offers')
      ;
  }
}
