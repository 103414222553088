import {Injectable} from '@angular/core';
import {Globals} from '../../components/shared/models/Globals';
import {ClientHelper} from '../ClientHelper/client-helper.service';
import {CookiesService} from '../../components/shared/components/cookie-consent/services/cookies.service';


@Injectable(
  {providedIn: 'root'}
)
export class MixPanelHelper {
  public static readonly MIXPANEL_TOKEN = '36825a64f260cbb5c9063be7a991c323';

  public static readonly SHARE_SHIPMENT_EVENT_KEY = 'ShipmentShared';
  public static readonly COPY_SHARE_SHIPMENT_EVENT_KEY = 'ShipmentShareCopied';
  public static readonly REPEAT_SHIPMENT_EVENT_KEY = 'ShipmentRepeated';

  private mixPanel;

  private cookiesRejected = false;

  constructor(private globals: Globals, private clientIpAddressHelper: ClientHelper, private cookieService: CookiesService) {
    this.mixPanel = require('mixpanel-browser');
    this.mixPanel.init(
      MixPanelHelper.MIXPANEL_TOKEN,
      {
        api_host: 'https://api-eu.mixpanel.com',
      },
    );
    // this.cookiesRejected = this.cookieService.checkCookieExistence('cookies-rejected');
  }

  public identify() {
    this.mixPanel.identify(this.globals.mixPanelId);
  }

  public setProperties(json: {}) {
    this.mixPanel.people.set(json);
  }

  public sendEvent(eventId: string, props: {}) {
    if (!this.cookiesRejected) {
      try {
        // TODO: Remove true for production tracking only
        if (true || this.globals.getEnvironment().production) {
          this.mixPanel.track(eventId, props);
        }
      } catch (e) {
        // Do nothing
      }
    }
  }

  sendPageLoadedEvent(pageRoute: string) {
    if (!this.cookiesRejected) {
      try {
        const props = this.getBasicEventProps();
        props['route'] = pageRoute;
        props['userAgent'] = navigator.userAgent;
        props['userIp'] = this.clientIpAddressHelper.getIp();
        this.sendEvent('Page View', props);
      } catch (e) {
        // Do nothing
      }
    }
  }

  public getBasicEventProps() {
    try {
      return {
        userMail: this.globals.mail,
        userName: this.globals.name,
        userTfn: this.globals.tfn,
      };
    } catch (e) {
      // Do nothing
    }
  }
}
