import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataValidator} from '../inputs/utils/DataValidator';


@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {

  @Input()
  keyText?: string;

  @Input()
  ctaLink?: string;

  @Input()
  ctaButtonTextKey?: string;

  @Input()
  isLoading = false;

  hasCta: boolean;

  constructor(private translate: TranslateService, private cdRef: ChangeDetectorRef) {
    this.hasCta = false;
  }

  ngOnInit() {
    this.hasCta = !DataValidator.isNullOrUndefined(this.ctaLink);
  }

  callCta() {
    location.href = this.ctaLink;
  }

  setLoadingFlag(isLoading: boolean) {
    this.isLoading = isLoading;
    try {
      this.cdRef.detectChanges();
    } catch (e) {
      // Do nothing
    }
  }

}
