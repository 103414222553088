import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {MailWrapper} from './models/MailWrapper';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService {

  private static RECOVER_PASSWD_METHOD = environment.baseApiUrl + 'recover-password';

  constructor(private httpauth: HttpClient) { }

  recoverPasswd(mail: MailWrapper) {
    return this.httpauth.post(RecoverPasswordService.RECOVER_PASSWD_METHOD, mail).map(value => value);
  }
}
