import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ChatMessageService} from './chat-message.service';
import {CONSTANTS} from '../QBconfig';
import {DialogChatService} from '../chat-dialogs/dialog-chat.service';
import {Globals} from '../../../models/Globals';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['../chat-dialogs/dialogs-chat.component.scss']
})
export class ChatMessageComponent implements AfterViewInit {

  @Input() message: any = [];
  @ViewChild('element') el: ElementRef;
  public CONSTANTS = CONSTANTS;

  constructor(
    private messageService: ChatMessageService,
    private dialogService: DialogChatService,
    private globals: Globals
  ) {
  }

  ngAfterViewInit() {
    if (this.message.visibilityEvent) {
      this.el.nativeElement['dataset'].messageId = this.message._id;
      this.el.nativeElement['dataset'].userId = this.message.sender_id;
      this.el.nativeElement['dataset'].dialogId = this.message.chat_dialog_id;
      this.messageService.intersectionObserver.observe(this.el.nativeElement);
    }
    // console.log(this.message);
  }

  visibility(e) {
    this.dialogService.dialogs[e.detail.dialogId].unread_messages_count--;
    this.dialogService.dialogsEvent.emit(this.dialogService.dialogs);
    this.messageService.intersectionObserver.unobserve(this.el.nativeElement);
    this.messageService.messages = this.messageService.messages.map(message => {
      if (message._id === e.detail.messageId) {
        message.visibilityEvent = false;
      }
      return message;
    });
  }

  loadImagesEvent(e) {
    let img: any, container: Element, imgPos: number, scrollHeight: number;
    img = e.target;
    container = document.querySelector('.j-messages');
    // @ts-ignore
    imgPos = container.offsetHeight + container.scrollTop - img.offsetTop;
    scrollHeight = container.scrollTop + img.offsetHeight;

    img.classList.add('loaded');

    if (imgPos >= 0) {
      container.scrollTop = scrollHeight + 5;
    }
  }


}
