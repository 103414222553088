import {Component, OnInit} from '@angular/core';
import {LanguageHelper} from '../../../../utils/LanguageHelper';
import {ChangeLanguageService} from './services/change-language.service';
import {UserService} from '../../features/auth/services/user.service';
import {AlertNotificationsComponent} from '../alert-notifications/alert-notifications.component';
import {LocalStorageHelper} from '../../../../utils/LocalStorageHelper';

@Component({
  selector: 'app-language-switch-item',
  templateUrl: './language-switch-item.component.html',
  styleUrls: ['./language-switch-item.component.scss', './language-switch-item.mediaquery.component.scss']
})
export class LanguageSwitchItemComponent implements OnInit {

  constructor(public languageHelper: LanguageHelper,
              private changeLanguageService: ChangeLanguageService,
              private userService: UserService,
              private alertNotificationComponent: AlertNotificationsComponent) { }

  ngOnInit() {
  }

  // TODO: Maybe reload?
  changeLanguage(lang: string) {
    this.languageHelper.changeLanguage(lang);
    this.scrollToTop();
    if(this.userService.isLogged()) {
      this.changeLanguageService.updateLang(lang).subscribe(next => {
        LocalStorageHelper.setItem(LocalStorageHelper.LANG_KEY, lang);
      }, error => {
        this.alertNotificationComponent.showGenericDataError();
      }, () => {

      });
    }
  }

  private scrollToTop() {
    window.scrollTo({left: 0, top: 0});
  }

}
